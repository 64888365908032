import { createContext, useContext, useState } from "react"; 
import { useUI } from "../../context/ui";
import { API_URL } from "../../opl-utilities/constants";
import { withRouter } from "react-router-dom";

const ScanflowContext = createContext();

const ScanflowProvider = withRouter (({ children, history, location }) => {
	const queryParams = new URLSearchParams(location.search);
	const [taskList, setTaskList] = useState(null); 
	const [task, setTask] = useState(null); 
	const [stats, setStats] = useState({
		notStarted: 0,
		completed: 0,
		inProgress: 0,
		forwarded: 0,
		paused: 0
	}); 
	const [taskLoading, setTaskLoading] = useState(false);
	const [random, setRandom] = useState(getRandomNumber());

	function getRandomNumber() {
	  const min = 1;  
	  const max = 100;
	  	  return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	const refreshHandler = () => {
		setRandom(getRandomNumber());
	  };

	  const { addToastMessage } = useUI();	

	const updateQueryParam = (key, value) => {
		queryParams.set(key, value);
		history.push({ search: queryParams.toString() });
	}; 
	
	{/* RE-OPEN FUNCTION */}
	const handleReOpen = async (id) => {
		try {
			if (!id) { return false; }
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/scanflow-v2/re-open/${id}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					updateQueryParam("tab", "not-started");
					updateQueryParam("id", response.data.taskId);
					addToastMessage({
						status: true,
						message: `Task Re-open successfully.`,
					});
				}
			}

			return false;
		} catch (error) {
			addToastMessage({
				status: false,
				message: `Something went wrong. Please try again.`,
			});
			console.log(error);

			return false;
		}
	};
	{/* RE-OPEN */}
	
	return (
		<ScanflowContext.Provider
			value={{   
				random,
				taskList,
				task, 
				setTask,
				setTaskList,
				taskLoading, 
				setTaskLoading,
				refreshHandler,
				stats, 
				setStats,
				handleReOpen
			}}>
			{children}
		</ScanflowContext.Provider>
	);
});

const useScanflow = () => {
	const context = useContext(ScanflowContext);
	if (!context) {
		throw new Error("Curate Provider error.");
	}
	return context;
};

export { ScanflowProvider, useScanflow };