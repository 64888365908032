import { formatISO } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { LOCAL_STORAGE_KEYS } from "../javascript/constants/common";
import { PBFUSA_EMAIL_ARRAY, ROLES_LIST, SCANFLOW_STATUS_LIST, USER_TYPE_OPTIONS } from "./constants";

export const formatYYYYMMDD = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr = str.split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export function formatMMDDYYYY(dateStr) {
    const regex = /^\d{4}-\d{2}-\d{2}$/; // Validate 'yyyy-mm-dd' format
    if (!regex.test(dateStr) || isNaN(new Date(dateStr).getTime())) {
        throw new Error('Invalid date format. Please use yyyy-mm-dd.');
    }
    const [year, month, day] = dateStr.split('-');
    return `${month}-${day}-${year}`;
}

export const formatServerDate = (str) => {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // const date = new Date(str);
	const date = convertGMTToLocal(str);
    const day = date.getDate(); 
    const month = months[date.getMonth()]; 
    const year = date.getFullYear(); 

    return month + " " + day + " " + year;
};

export function formatDateTime(dateString) { 
	const date = convertGMTToLocal(dateString);
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const formattedDate = `${formatServerDate(
		dateString
	)} ${hours}:${minutes}:${seconds}`;
	return formattedDate;
}

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const formatAmountValue = (value) => {
	if (isNaN(value)) return value;
	return parseFloat(value).toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export function getScanflowStatusById(id = 0) {
	const status = SCANFLOW_STATUS_LIST.find((d) => d.id == id);
	return status
		? status
		: {
				id: "0",
				name: "Undefined",
				class: "not-started",
		  };
}

export const formatTime = (totalSeconds) => {
	const hours = Math.floor(totalSeconds / 3600)
		.toString()
		.padStart(2, "0");
	const minutes = Math.floor((totalSeconds % 3600) / 60)
		.toString()
		.padStart(2, "0");
	const seconds = (totalSeconds % 60).toString().padStart(2, "0");
	return `${hours}:${minutes}:${seconds}`;
};

export const getLogInUserDetails = (type) => {
	const LOGGED_IN_USER = localStorage.getItem(
		LOCAL_STORAGE_KEYS.LOGGED_IN_USER
	);
	if (!LOGGED_IN_USER) return "";
	const user = JSON.parse(LOGGED_IN_USER);
	const detailsMap = {
		type: user.type,
		id: user.id,
		fullname: user.fullname,
	};
	return detailsMap[type] || "";
};

export const isManager = (roleName) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.type === "manager";
};

export const isInDepartment = (roleName, department) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.department === department;
};

export const getUserTypeInfo = (type) => {
	return USER_TYPE_OPTIONS.find((userType) => userType.value === type) || null;
}

export const getRoleInfo = (roleName) => {
	return ROLES_LIST.find((role) => role.name === roleName) || null;
};

export const getScanfloeStatusInfo = (key, value) => {
	return SCANFLOW_STATUS_LIST.find((status) => status[key] === value) || null;
};

export const convertGMTToLocal = (gmtInput) => {
	const gmtTime = new Date(gmtInput);
	if (isNaN(gmtTime.getTime())) {
		console.error("Invalid GMT input");
		return null;
	}
	const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
	const localTime = new Date(
		gmtTime.getTime() - timezoneOffsetInMinutes * 60000
	);
	return localTime;
};


/**
 * Decodes user permissions for a given module from a JWT token.
 *
 * @param {string} module - Module name (e.g., "insurance").
 * @returns {null|object} - Returns `null` if the token or module is invalid, 
 * or an object with permissions:
 * {
 *   isCreateAllowed: boolean, // Create permission
 *   isReadAllowed: boolean,   // Read permission
 *   isUpdateAllowed: boolean, // Update permission
 *   isDeleteAllowed: boolean  // Delete permission
 * }
 */


export const decodeModuleAccessFromToken = (module) => { 
  const token = localStorage.getItem("token");
   
  if (!token) {
    console.warn("Token not found in localStorage.");
    return null;
  }

  try { 
    const decodedToken = jwtDecode(token); 
    const modules = decodedToken.modules;
 
    if (!modules || !modules.hasOwnProperty(module)) {
      console.warn(`Module "${module}" not found in token.`); 
	    return {
			isCreateAllowed: false,  
			isReadAllowed: false,   
			isUpdateAllowed: false,  
			isDeleteAllowed: false   
	    };
    }
 
    const crudValue = modules[module];
     
    if (typeof crudValue !== "string" || crudValue.length < 4) {
      console.error(`Invalid CRUD value for module "${module}".`); 
		return {
			isCreateAllowed: false,  
			isReadAllowed: false,   
			isUpdateAllowed: false,  
			isDeleteAllowed: false   
		};
    }
 
	if(crudValue === "0000"){
		console.error(`Invalid CRUD value for module "${module}".`);
		return {
			isCreateAllowed: false,  
			isReadAllowed: false,   
			isUpdateAllowed: false,  
			isDeleteAllowed: false   
		};
	}

    return {
      isCreateAllowed: crudValue[0] === '1',  
      isReadAllowed: crudValue[1] === '1',   
      isUpdateAllowed: crudValue[2] === '1',  
      isDeleteAllowed: crudValue[3] === '1'   
    };
  } catch (error) { 
    console.error("Error decoding token:", error.message);
    return null;
  }

};

export const getDepartmentId = (department) => {
	const token = localStorage.getItem("token");
  
	if (!token) {
	  console.warn("Token not found in localStorage.");
	  return null;
	}
  
	try {
	  const decodedToken = jwtDecode(token);  
		return decodedToken.department || null;  
	} catch (error) {
	  console.error("Error decoding token:", error.message);
	  return null;
	}
  };

  export const formatAndValidateDate = (inputDate) => { 
	
	const sanitizedDate = inputDate.replace(/[^0-9]/g, '');
   
	if (sanitizedDate.length !== 8) {
	  console.log("Invalid date format: Length should be 8 characters.");
	  return "";
	}
   
	const month = sanitizedDate.slice(0, 2);
	const day = sanitizedDate.slice(2, 4);
	const year = sanitizedDate.slice(4);
   
	const date = new Date(`${year}-${month}-${day}`);
	 
	if (date.getMonth() + 1 !== parseInt(month) || date.getDate() !== parseInt(day) || date.getFullYear() !== parseInt(year)) {
	  console.log("Invalid date.");
	  return "";
	}
   
	return `${year}-${month}-${day}`;
  }
     
  
	export const handleDatePickerChange = (setValue, key, date, e) => {  
		if (e?.target?.value !== undefined) {  
			if (e?.target?.value) {  
				setValue(key, formatAndValidateDate(e.target.value));
			} else { 
				setValue(key, "");
			} 
		} else if (date) {  
			setValue(key, formatISO(date, { representation: "date" }));
		} else { 
			setValue(key, "");
		}
	};

	export const getPBFUSAaccess = () => {
		const token = localStorage.getItem("token");
	  
		if (!token) {
		  return false;
		}
	  
		try {
		  const decodedToken = jwtDecode(token);
	  
		  const { userName } = decodedToken;
	  
		  if (PBFUSA_EMAIL_ARRAY.includes(userName)) {
			return true;
		  } else {
			return false;
		  }
		} catch (error) {
		  console.error("Failed to decode token:", error);
		  return false;
		}
	  };
	  
	  
