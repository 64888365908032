import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
    ALLOWED_FILE_TYPES,
    API_URL,
    CLEARANCE_OPTIONS,
    EMR_ACCESS_OPTIONS,
    FACILITY_INVOICE_OPTIONS,
    FACILITY_TYPE_OPTIONS,
    PLACE_OF_SERVICE,
    SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import AddFacility from "./AddFacility";
import { useUI } from "../../context/ui";

const AddFacilityForm = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const { addToastMessage } = useUI();

    const initialValues = {
        facilityName: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        contactFax: "",
        gpo: "",
        idn: "",
        npi: "",
        taxPercent: "",
        caseCapacity: "",
        dayConfirmEmail: "",
        facilityType: "",
        financialClearanceFacility: "no",
        facilityEmrAccess: "",
        facilityPlaceOfService: "",
        invoiceReq: "",
        facilityAdditionalContact: " ",
        internalIdentifier: ""

    };

    const validationSchema = Yup.object({
        facilityName: Yup.string().required("Facility Name is Required"),
        npi: Yup.string().required("NPI is Required"),
        facilityPlaceOfService: Yup.string().required("Required"),
    });

    const createFacility = async (values) => {
        setLoading(true);
        setStatus("");
        console.log("vvv", values);

        try {
            const payload = JSON.stringify({
                facilityName: values.facilityName,
                address: values.address,
                address2: values.address2,
                city: values.city?.label,
                state: values.state?.value,
                zipCode: values.zipCode,
                contactName: values.contactName,
                contactEmail: values.contactEmail,
                contactPhone: values.contactPhone,
                contactFax: values.contactFax,
                gpo: values.gpo,
                idn: values.idn,
                npi: values.npi,
                taxPercent: values.taxPercent,
                caseCapacity: values.caseCapacity,
                dayConfirmEmail: values.dayConfirmEmail,
                facilityType: values.facilityType,
                financialClearanceFacility: values.financialClearanceFacility||"",
                facilityEmrAccess: values.facilityEmrAccess,
                facilityPlaceOfService: values.facilityPlaceOfService,
                invoiceReq: values.invoiceReq,
                facilityAdditionalContact:  values.facilityAdditionalContact,
                internalIdentifier:  values.internalIdentifier,
            });
            console.log("...", payload);

            const resp = await fetch(API_URL + `/api/1.0.0/facility`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
                body: payload,
            });
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    if (response.result.responseCode === 200) {
                        return true;
                    } else {
                        addToastMessage({
                            status: false,
                            message: `Something went wrong, please try again later.`,
                        });
                        return false;
                    }
                }
            }
            else {
                const response = await resp.json();
                if (response) {
                    addToastMessage({
                        status: false,
                        message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
            }
        } catch (e) {
            addToastMessage({
                status: false,
                message: "Something went wrong, please try again later.",
            });
            console.log("Error:", e);
            return false;
        } finally {
            setLoading(false);
        }

    };

    const Label = ({ title, required }) => {
        return (
            <p
                className='color-primary f-w-600'
                style={{ paddingBottom: "5px" }}>
                {title}
                {required && (
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                )}
            </p>
        );
    };

    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelStateFunc = (o) => `${o.name}`;
    const labelStateCodeFunc = (o) => `${o.code}`;
    const labelCityFunc = (o) => `${o.name}`

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                const api = await createFacility(values);
                if (api) {
                    resetForm();
                    setStatus(
                        "You have successfully created a facility, thank you."
                    );
                    addToastMessage({
                        status: true,
                        message: `You have successfully created a facility, thank you.`,
                    })
                    history.push("/facilities");
                } else {
                    setStatus("Something went wrong, please try again later.");
                }
            }}
        >
            {({
                values,
                setFieldValue,
                errors,
                touched,
                handleSubmit,
                validateForm,
            }) => (
                <Form>
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Facility Name' required={true} />
                                        <Field
                                            type='text'
                                            placeholder='Enter Facility Name'
                                            name='facilityName'
                                            className={`col-1-1 primary ${errors.facilityName &&
                                                touched.facilityName
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Address Line1' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Address Line1'
                                            name='address'
                                            className={`col-1-1 primary ${errors.address &&
                                                touched.address
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Address Line2' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Address Line1'
                                            name='address2'
                                            className={`col-1-1 primary ${errors.address2 &&
                                                touched.address2
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td>
                                        <Label
                                            title='Zip Code'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter ZipCode'
                                            name='zipCode'
                                            className={`col-1-1 primary ${errors.zipCode &&
                                                touched.zipCode
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='State' />
                                        <PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/states/US?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "code",
                                                    labelStateFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.state}
                                            onChange={(v) => {
                                               setFieldValue("state", v);
                                               setFieldValue("city", null);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Label
                                            title='City'
                                        />
                                        <PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.state?.value}?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "name",
                                                    labelCityFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.city}
                                            onChange={(v) => {
                                                setFieldValue(
                                                        "city",
                                                        v
                                                    );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label
                                            title='Main Contact Name'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter Main Contact Name'
                                            name='contactName'
                                            className={`col-1-1 primary ${errors.contactName &&
                                                touched.contactName
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td>
                                        <Label
                                            title='Main Contact Phone'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter Main Contact Phone'
                                            name='contactPhone'
                                            className={`col-1-1 primary ${errors.contactPhone &&
                                                touched.contactPhone
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label
                                            title='Main Contact Fax'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter Main Contact Fax'
                                            name='contactFax'
                                            className={`col-1-1 primary ${errors.contactFax &&
                                                touched.contactFax
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Main Contact Email' />
                                        <Field
                                            type='email'
                                            placeholder='Enter Main Contact Email'
                                            name='contactEmail'
                                            className={`col-1-1 primary ${errors.contactEmail &&
                                                touched.contactEmail
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>

                                    <td width='50%'>
                                        <Label title='GPO' />
                                        <Field
                                            type='text'
                                            placeholder='Enter GPO'
                                            name='gpo'
                                            className={`col-1-1 primary ${errors.gpo &&
                                                touched.gpo
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='IDN' />
                                        <Field
                                            type='text'
                                            placeholder='Enter IDN'
                                            name='idn'
                                            className={`col-1-1 primary ${errors.idn &&
                                                touched.idn
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>

                                    <td width='50%'>
                                        <Label title='Facility NPI' required={true} />
                                        <Field
                                            type='text'
                                            placeholder='Enter Facility NPI'
                                            name='npi'
                                            className={`col-1-1 primary ${errors.npi &&
                                                touched.npi
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Internal Identifier' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Internal Identifier'
                                            name='internalIdentifier'
                                            className={`col-1-1 primary ${errors.internalIdentifier &&
                                                touched.internalIdentifier
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Sales Tax Percent' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Sales Tax Percent'
                                            name='taxPercent'
                                            className={`col-1-1 primary ${errors.taxPercent &&
                                                touched.taxPercent
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Case Capacity' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Case Capacity'
                                            name='caseCapacity'
                                            className={`col-1-1 primary ${errors.caseCapacity &&
                                                touched.caseCapacity
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Day Confirmation Email Address' />
                                        <Field
                                            type='email'
                                            placeholder='Enter Confirmation Email'
                                            name='dayConfirmEmail'
                                            className={`col-1-1 primary ${errors.dayConfirmEmail &&
                                                touched.dayConfirmEmail
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Facility Type' />
                                        <Field
                                            as='select'
                                            name='facilityType'
                                            className={`col-1-1 primary ${errors.facilityType &&
                                                touched.facilityType
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {FACILITY_TYPE_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Financial Clearance' />
                                        <Field
                                            as='select'
                                            name='financialClearanceFacility'
                                            className={`col-1-1 primary ${errors.financialClearanceFacility &&
                                                touched.financialClearanceFacility
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {CLEARANCE_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                    <td width='50%'>
                                        <Label title='EMR Access' />
                                        <Field
                                            as='select'
                                            name='facilityEmrAccess'
                                            className={`col-1-1 primary ${errors.facilityEmrAccess &&
                                                touched.facilityEmrAccess
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {EMR_ACCESS_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Place Of Service' required={true} />
                                        <Field
                                            as='select'
                                            name='facilityPlaceOfService'
                                            className={`col-1-1 primary ${errors.facilityPlaceOfService &&
                                                touched.facilityPlaceOfService
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {PLACE_OF_SERVICE.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                    <td width='50%'>
                                        <Label title='Adition Contact(s)' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Adition Contact(s)'
                                            name='facilityAdditionalContact'
                                            className={`col-1-1 primary ${errors.facilityAdditionalContact &&
                                                touched.facilityAdditionalContact
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>

                                    <td width='50%'>
                                        <Label title='Invoice Procedure CPT Requirements' />
                                        <Field
                                            as='select'
                                            name='invoiceReq'
                                            className={`col-1-1 primary ${errors.invoiceReq &&
                                                touched.invoiceReq
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {FACILITY_INVOICE_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push("/facilities")}
                            icon={
                                <svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
                            }
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Submit'
                            className='mar-l-a'
                            icon={
                                loading ? (
                                    <LoaderIcon />
                                ) : (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.996'
                                        viewBox='0 0 19.995 19.996'>
                                        <path
                                            d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                            transform='translate(-2.014 -1.995)'
                                        />
                                    </svg>
                                )
                            }
                        />
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default AddFacilityForm;
