import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PaginatedDropdown from '../../components/common/ui/PaginatedDropdown';
import ButtonTextIcon from '../../components/common/ui/ButtonTextIcon';
import LoaderIcon from '../../components/common/ui/LoaderIcon';
import DragFileUpload from '../../components/common/ui/DragFileUpload';
import { API_URL } from '../../opl-utilities/constants'; 
import { useUI } from '../../context/ui';

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'), 
    articlecategory: Yup.mixed()
			.nullable()
			.test("articlecategory-required", "Required",
				function (value) { return value !== null && value.value; }), 
    articlesubcategory: Yup.mixed()
    .nullable()
    .test("articlesubcategory-required", "Required",
        function (value) { return value !== null && value.value; }),
});

function UpdateForm({ history, data }) {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(""); 
    const {addToastMessage}=useUI();
    const [initialValues, setInitialValues] = useState({
        name: '',
        articlecategory: null,
        articlesubcategory: null,
        description: '',
        file: ''
    }); 
    const Label = ({ title, required }) => (
        <p className='color-primary f-w-600' style={{ paddingBottom: '5px' }}>
            {title}
            {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        </p>
    );

    useEffect(()=>{
        setInitialValues({
            name: data?.name||"",
            articlecategory: data?.knowledgeBaseSubCategory?.knowledgeBaseCategory ? {
                label:data?.knowledgeBaseSubCategory?.knowledgeBaseCategory.name,
                value:data?.knowledgeBaseSubCategory?.knowledgeBaseCategory.id,
                obj:data?.knowledgeBaseSubCategory?.knowledgeBaseCategory,
            } :null,
            articlesubcategory: data?.knowledgeBaseSubCategory ? {
                label:data?.knowledgeBaseSubCategory.name,
                value:data?.knowledgeBaseSubCategory.id,
                obj:data?.knowledgeBaseSubCategory,
            } :null ,
            description: data?.description ||"",
            file: data?.fileLink ||"",
        })
    },[data]);

    const updateKnowledgeBase = async (values) => {
        setLoading(true);
        setStatus("");
     
        if (!data?.id) {
            setStatus(`Something went wrong, please try again later.`);
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
            return false;
        }
        
        try {
            const payload = { 
                id: data?.id || null,
                name: values.name,
                description: values.description,
                knowledgeBaseSubCategoryId: Number(values?.articlesubcategory?.obj?.id), 
            }; 
            if(values.file){
                payload["fileLink"] = values.file;
            }  
            const resp = await fetch(`${API_URL}/api/1.0.0/knowledge-base/${data?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
                body:  JSON.stringify(payload),
            });
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    return response.result.responseCode === 200 ? true : false;
                } else {
                    const responseData = await resp.json();
                    if (responseData) {
                        setStatus(`${responseData.result.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${responseData.result.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus(`Something went wrong, please try again later.`);
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            } else {
                const response = await resp.json();
                if (response) {
                    setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
                    addToastMessage({
                        status: false,
                        message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }

        } catch (e) {
            console.log("Error:", e);
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values ) => {
                const api = await updateKnowledgeBase(values); 
                if (api) { 
                    setStatus("You have successfully updated the knowledge base, thank you.");
                    addToastMessage({
                        status: true,
                        message: `You have successfully updated an knowledge base, thank you.`,
                    }); 
					history.push("/knowledge-base");
                }
            }}
        >
            {({ handleSubmit, setFieldValue, errors, touched,values }) => (
                <Form onSubmit={handleSubmit}>
                    <div className='col-1-1 '>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <Label title="Title" required />
                                        <Field
                                            name="name"
                                            type="text"
                                            placeholder="Eg: How to Create.."
                                            className={`primary ${errors.name && touched.name ? 'error' : ''}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="Category" required />
                                         <PaginatedDropdown
                                            searchkey='name'
                                            selectedValue={values.articlecategory}
											url={`${API_URL}/api/1.0.0/knowledge-base-category?`}
                                            mapOptions={(records) =>
                                                records?.map((record) => ({
                                                    value: record["id"],
                                                    label: record.name,
                                                    obj: record,
                                                }))
                                            } 
											placeHolder='Search'
                                            datakey = "knowledgeBaseCategoryList"
											onChange={(v)=>
                                                { 
													setFieldValue(
														"category",
														v
													); 
											}}
										/>
                                    </td>
                                    <td>
                                        <Label title="Sub Category" required />
                                        <PaginatedDropdown
                                            searchkey='name'
                                            selectedValue={values.articlesubcategory}
                                            url={`${API_URL}/api/1.0.0/knowledge-base-subcategory?statusId=1&categoryId=${values?.articlecategory?.value}&`}
                                            name="articlesubcategory"
                                            mapOptions={(records) =>
                                                records?.map((record) => ({
                                                    value: record["id"],
                                                    label: record.name,
                                                    obj: record,
                                                }))
                                            }
											placeHolder='Search'
                                            className={`primary ${errors.articlesubcategory && touched.articlesubcategory ? 'error' : ''}`}
                                            onChange={(value) => setFieldValue('articlesubcategory', value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
											<div
												className='flex center-left'
												style={{ gap: "8px" }}>
												<div
													className='opl-tooltip'
													data-tooltip='Please only select PDF files for upload.'>
													<Label title='Upload File' />
												</div>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													style={{
														height: "12px",
														width: "12px",
														marginBottom: "5px",
													}}>
													<path
														d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
														fill='#5dc6b3'
													/>
												</svg>
											</div>
											<DragFileUpload
												// fileType={values.file_type} 
												afterUpload={(url) => {
													setFieldValue("file", url);
												}}
											/>
										
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Label title="Description" required />
                                        <Field
                                            name="description"
                                            as="textarea"
                                            placeholder="Enter Description"
                                            className={`primary ${errors.description && touched.description ? 'error' : ''}`}
                                            style={{
                                                width: '100%',
                                                height: '100px',
                                                resize: 'none'
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push('/knowledge-base')}
                            icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Update Now'
                            className='mar-l-a'
                            icon={loading ? <LoaderIcon /> : <svg xmlns='http://www.w3.org/2000/svg' width='19.995' height='19.996' viewBox='0 0 19.995 19.996'><path d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z' transform='translate(-2.014 -1.995)' /></svg>}
                        />
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
}

export default UpdateForm;