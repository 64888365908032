import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "../constants/common";
import { utilityService } from "./utility";
import { LARAVEL_API_URL } from "../../opl-utilities/constants";

export const apiClient = (cancelToken) => {
	const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || null;
	const tenantid = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID) || null;
	const tenantfound =
		localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_FOUND) || null;
	const baseURLWithoutTenant = LARAVEL_API_URL.replace(
		"{tenant}",
		""
	).replace("{subdomain}", "usp");
	const baseURL = LARAVEL_API_URL;
	// eslint-disable-next-line
	let tenantURL = baseURL.replace(
		"{tenant}",
		tenantid && tenantfound && tenantfound == "1" ? tenantid + "/" : ""
	);

	let url = window.location.href;
	url = url.split(".");
	let firstPart = url[0].replace("http://", "").replace("https://", "");
	tenantURL = tenantURL.replace("{subdomain}", firstPart);

	const tenatExcludeList = ["client/check-client"];
	const defaultOptions = {
		headers: {
			"Authorization": `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	};

	return {
		get: (url, options = {}) => {
			let tURL = "";
			if (tenatExcludeList.findIndex((temp) => temp === url) >= 0)
				tURL = baseURLWithoutTenant + url;
			else tURL = tenantURL + url;

			return new Promise((resolve, reject) => {
				axios
					.get(`${tURL}`, {
						...defaultOptions,
						...options,
						cancelToken: cancelToken,
					})
					.then((data) => {
						resolve(data);
					})
					.catch((data) => {
						if (
							data &&
							data.response &&
							data.response.data &&
							!data.response.data.success
						) {
							sendErrorNotification(data);
						}
						reject(data);
					});
			});
		},
		post: (url, data, options = {}) => {
			let tURL = "";
			if (tenatExcludeList.findIndex((temp) => temp === url) >= 0)
				tURL = baseURLWithoutTenant + url;
			else tURL = tenantURL + url;
			return new Promise((resolve, reject) => {
				axios
					.post(`${tURL}`, data, { ...defaultOptions, ...options })
					.then((data) => {
						resolve(data);
					})
					.catch((data) => {
						if (
							data &&
							data.response &&
							data.response.data &&
							!data.response.data.success
						) {
							sendErrorNotification(data);
						}
						reject(data);
					});
			});
		},
		put: (url, data, options = {}) => {
			let tURL = "";
			if (tenatExcludeList.findIndex((temp) => temp === url) >= 0)
				tURL = baseURLWithoutTenant + url;
			else tURL = tenantURL + url;
			return new Promise((resolve, reject) => {
				axios
					.put(`${tURL}`, data, { ...defaultOptions, ...options })
					.then((data) => {
						resolve(data);
					})
					.catch((data) => {
						if (
							data &&
							data.response &&
							data.response.data &&
							!data.response.data.success
						) {
							sendErrorNotification(data);
						}
						reject(data);
					});
			});
		},
		delete: (url, options = {}) => {
			let tURL = "";
			if (tenatExcludeList.findIndex((temp) => temp === url) >= 0)
				tURL = baseURLWithoutTenant + url;
			else tURL = tenantURL + url;
			return new Promise((resolve, reject) => {
				axios
					.delete(`${tURL}`, { ...defaultOptions, ...options })
					.then((data) => {
						resolve(data);
					})
					.catch((data) => {
						if (
							data &&
							data.response &&
							data.response.data &&
							!data.response.data.success
						) {
							sendErrorNotification(data);
						}
						reject(data);
					});
			});
		},
	};

	function sendErrorNotification(data) {
		let tData = data.response.data;
		let message = tData.message;

		if (tData.errors) {
			message += "<li>";
			tData.errors.forEach((tError) => {
				message += tError;
			});
			message += "</li>";
		}

		utilityService.showHTMLMessage("Error !", message, "error");
	}
};
