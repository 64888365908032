import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import ActivityFilter from "./activities/ActivityFilter";
import PaginatedDropdown from "../components/common/ui/PaginatedDropdown";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";

const Label = ({ title }) => {
    return (
        <p
            className='color-primary f-w-300 l-h-1'
            style={{ fontSize: "12px", paddingBottom: "6px" }}>
            &nbsp;{title}
        </p>
    );
};


const DivisionManagement = ({ history, location, activePage }) => {
    const data = [
        {
            id: 1,
            divisionName: "Sales",
            managerName: "John Doe",
            managerEmail: "john.doe@example.com",
            action: "Active"
        },
        {
            id: 2,
            divisionName: "Marketing",
            managerName: "Jane Smith",
            managerEmail: "jane.smith@example.com",
            action: "Inactive"
        },
        {
            id: 3,
            divisionName: "Finance",
            managerName: "Bob Johnson",
            managerEmail: "bob.johnson@example.com",
            action: "Pending"
        },
        {
            id: 4,
            divisionName: "Operations",
            managerName: "Alice Williams",
            managerEmail: "alice.williams@example.com",
            action: "Active"
        },
        {
            id: 5,
            divisionName: "Human Resources",
            managerName: "Charlie Brown",
            managerEmail: "charlie.brown@example.com",
            action: "Inactive"
        }
    ];

    // const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
    const pageSize = new URLSearchParams(location.search).get("pageSize") || 10;
    const queryParams = new URLSearchParams(location.search);


    const handleQueryParamChange = (key, value) => {
        // queryParams.delete("id");
        queryParams.set("pageNo", 0);
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };

    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
        }));

    const DROPDOWNS = [

        {
            title: "Filter",
            url: "/users",
            param: "users",
            mapFunc: mapOptions,
            labelFunc: (o) => `${o.firstName} ${o.lastName}`,
            placeholder: "Search users",
        },
    ];

    const [showDeleteModal, setShowDeleteModal] = useState(false); 

    const handleDelete = (id) => {
        setShowDeleteModal(true); 
    };

    const handleConfirmDelete = async () => {
        alert("Deleted");
        setShowDeleteModal(false); 
    };

    return (
        <section id='opl_coremax'>
            <section className='main-section'>
                <Header active={activePage} location={location} />
                <section id='activity-v2'>
                    <div className='col-1-1 flex center-left pad-0-10'>
                        <h5 className='page-active color-primary f-w-700'>
                            Division List
                        </h5>
                        <ButtonTextIcon
                            title='Add New Division'
                            className='mar-l-a'
                            onClick={() => history.push("/add-division")}
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='19.995'
                                    height='19.995'
                                    viewBox='0 0 19.995 19.995'>
                                    <path
                                        d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
                                        transform='translate(-2.005 -2)'
                                    />
                                </svg>
                            }
                        />
                        &nbsp;
                    </div><br />
                    <div className='flex center-left filters' style={{ gridTemplateColumns: "none" }}>
                        {DROPDOWNS.map(
                            ({ title, url, param, mapFunc, labelFunc, placeholder }) => (
                                <div key={param}>
                                    <Label title={title} />
                                    <PaginatedDropdown
                                        url={`${API_URL}/api/1.0.0${url}?`}
                                        selectedValue={
                                            queryParams.get(param)
                                                ? JSON.parse(queryParams.get(param))
                                                : null
                                        }
                                        onChange={(v) =>
                                            handleQueryParamChange(
                                                param,
                                                v ? JSON.stringify(v) : null
                                            )
                                        }
                                        mapOptions={(records) =>
                                            mapFunc(records, "id", labelFunc)
                                        }
                                        placeHolder={placeholder}
                                    />
                                </div>
                            )
                        )}
                    </div>

                    <div className="results">
                        <div className="rs-table-holder">
                            <table className="rs-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Division Name</th>
                                        <th>Manager Name</th>
                                        <th>Manager Email</th>
                                        <th style={{ textAlign: 'center' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.divisionName}</td>
                                            <td>{item.managerName}</td>
                                            <td>{item.managerEmail}</td>
                                            <td>
                                            <p className='flex color-light font-size-10'>
                                                    <NavLink
                                                        to={{
                                                            pathname: '/edit-division',
                                                            // state: { id: data.id } 
                                                        }}
                                                        className="color-secondary transition hover-color-primary cursor-pointer"
                                                        style={{ textDecoration: 'none', marginRight: '10px' }}
                                                    >
                                                        <div className='flex center-left'>
                                                            <span className='font-size-14 t-d-ul t-a-c color-secondary transition hover-color-primary'>
                                                                Update 
                                                            </span>
                                                        </div>
                                                    </NavLink>
                                                        
                                                    <span
                                                        className="color-secondary transition hover-color-primary cursor-pointer"
                                                        style={{ textDecoration: 'none', marginLeft: '10px' }}
                                                        onClick={() => handleDelete(data.id)}
                                                    >
                                                        <div className='flex center-left'>
                                                            <span className='font-size-14 t-d-ul t-a-c transition hover-color-primary' style={{color:"red"}}>
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </span>
                                                </p>
                                                {showDeleteModal && (
                                                        <ConfirmationModal
                                                            onConfirm={handleConfirmDelete} // Confirm delete action
                                                            onCancel={setShowDeleteModal} // Cancel delete action
                                                            message="Are you sure you want to delete this record ?"
                                                            setShow={setShowDeleteModal} 
                                                        />
                                                )}
                                                
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                    {data &&
                        <div className="flex center-left pad-10" style={{ backgroundColor: "#fafaff" }}>
                            <div>
                                <ResultsPerPage />
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                {/* <Pagination totalRecords={data.totalRecords} pageNo={data.pageNo} pageSize={data.pageSize} /> */}
                            </div>
                        </div>
                    }
                </section>
            </section>
        </section>
    );
};

export default withRouter(DivisionManagement);
