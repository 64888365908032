import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import { API_URL, SYSTEM_TOKEN } from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import PopUpModal from "../../components/common/PopUpModal";
import DatePicker from "react-datepicker";
import { formatISO, parseISO } from "date-fns";
import { handleDatePickerChange } from "../../opl-utilities/helpers";


const Label = ({ title, required }) => {
    return (
        <p
            className='color-primary f-w-300 l-h-1'
            style={{ fontSize: "13px", paddingBottom: "8px" }}>
            {title}
            {required && <span style={{ color: "red" }}>
                &nbsp;*
            </span>}
        </p>
    );
};

const AddPatientForm = ({ callback }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false); 
    const [confirmation, setConfirmation] = useState(false);
    const { addToastMessage } = useUI();

    const initialValues = {
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        email: '',
        phoneNumber: '',
        primaryInsuranceId: null,
        secondaryInsuranceId: null,
        tertiaryInsuranceId: null,
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().min(2, "First Name must be at least 2 characters long").required('First Name is required'),
        lastName: Yup.string().min(2, "Last Name must be at least 2 characters long").required('Last Name is required'),
        gender: Yup.string().required('Gender is required'),
        dateOfBirth: Yup.date().required('DOB is required'),
        email: Yup.string()
            .email("Please enter a valid email address") // Validates only if a value is entered
            .nullable(),
        phoneNumber: Yup.string()
        .matches(/^\d+$/, "Phone Number must contain only numbers")
        .max(15, "Phone Number must be at most 15 digits") // Ensures exactly 15 digits
        .required("Phone Number is required"),
    });

    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelInsuranceFunc = (o) => `${o.description}`;

    const checkPatientExists = async (name, dob) => {
        try {
            const resp = await fetch(
                `${API_URL}/api/1.0.0/reference/patients?dob=${dob}&search=${name}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token"),
                    },
                }
            );
            if (resp.ok) {
                const response = await resp.json();
                if (response.result.responseCode === 200) {
                    if (response.data.records.length > 0) {
                        return true;
                    }
                }
            }
            return false;
        } catch (e) {
            return false;
            console.log(e); 
        } finally { 
        }
    };
 
    return (
        <>
            <p
                onClick={() => { setShow(true) }}
                className='cursor-pointer'
                style={{
                    paddingTop: "4px",
                    paddingBottom: "10px",
                    color: "#8f8f8f",
                    fontSize: "10px"
                }}>
                Could't find the patient?{" "}
                <span
                    className='color-secondary hover-color-primary hover-t-d-ul transition'
                >
                    Click to add
                </span>
            </p>
            {show &&
                <div style={{ border: "solid 1px #5dc6b3", backgroundColor: "#ecf0f1", padding: "10px", borderRadius: "5px" }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={async (values) => {
                            setLoading(true);
                            setError("");
                            try {
                                const payload = JSON.stringify({
                                    userId: 1,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    gender: values.gender,
                                    dateOfBirth: values.dateOfBirth,
                                    email: values.email,
                                    phoneNumber: values.phoneNumber,
                                    primaryInsuranceId: values?.primaryInsuranceId?.value || null,
                                    secondaryInsuranceId: values?.secondaryInsuranceId?.value || null,
                                    tertiaryInsuranceId: values?.tertiaryInsuranceId?.value || null,
                                });
                                const resp = await fetch(API_URL + `/api/1.0.0/patient`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        "X-JWT-Assertion": localStorage.getItem("token"),
                                    },
                                    body: payload,
                                });
                                if (resp.ok) {
                                    const response = await resp.json();
                                    if (response) {
                                        if (response.result.responseCode === 200) {
                                            callback(response?.data);
                                            setShow(false)
                                            addToastMessage({
                                                status: true,
                                                message: `You have successfully created an patient, thank you.`,
                                            });
                                        } else {
                                            setError("Something went wrong, please try again later.");
                                        }
                                    }
                                } else {
                                    const response = await resp.json();
                                    if (response) {
                                        setError(response.responseDescription || "Something went wrong, please try again later.");
                                    }
                                }
                            } catch (e) {
                                setError("Something went wrong, please try again later.");
                            } finally {
                                setLoading(false);
                            }
                        }}>
                        {({
                            values,
                            setFieldValue,
                            errors,
                            touched,
                            handleSubmit,
                            resetForm,
                            validateForm,
                        }) => (
                            <Form>
                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='First Name' required={true} />
                                    <Field
                                        type='text'
                                        placeholder='e.g., John'
                                        name='firstName'
                                        className={`col-1-1 primary ${errors.firstName &&
                                            touched.firstName
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name='firstName'
                                        component='p'
                                        className='error-messages'
                                    />
                                </div>
                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='Last Name' required={true} />
                                    <Field
                                        type='text'
                                        placeholder='e.g., Doe '
                                        name='lastName'
                                        className={`col-1-1 primary ${errors.lastName &&
                                            touched.lastName
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name='lastName'
                                        component='p'
                                        className='error-messages'
                                    />
                                </div>
                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='Gender' required={true} />
                                    <Field
                                        as='select'
                                        placeholder='Select Gender'
                                        name='gender'
                                        className={`col-1-1 primary ${errors.gender &&
                                            touched.gender
                                            ? "error"
                                            : ""
                                            }`}
                                    >
                                        <option value="">Select Gender</option>
                                        {["Male", "Female", "Other"].map((d, i) => (
                                            <option value={d} key={i}> {d}</option>
                                        ))}
                                    </Field>
                                </div>
                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='DOB (DD/MM/YYYY)' required={true} />
                                    <DatePicker
                                        className={`primary ${errors.dateOfBirth && touched.dateOfBirth ? "error" : "" }`}
                                        isClearable
                                        portalId="root-portal" 
                                        selected={values.dateOfBirth ? parseISO(values.dateOfBirth) : ""}
                                        onChange={(date, e) => handleDatePickerChange(setFieldValue, "dateOfBirth", date, e)}
                                        showYearDropdown
                                        placeholderText="MM/DD/YYYY"
                                        dateFormat="MM/dd/yyyy"
                                    />  
                                </div>
                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='Email Address' />
                                    <Field
                                        type='text'
                                        placeholder='e.g., example@domain.com'
                                        name='email'
                                        className={`col-1-1 primary ${errors.email &&
                                            touched.email
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                </div>
                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='Phone Number' required={true} />
                                    <Field
                                        type='text'
                                        placeholder='e.g., 1234567890'
                                        name='phoneNumber'
                                        className={`col-1-1 primary ${errors.phoneNumber &&
                                            touched.phoneNumber
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                </div>

                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='Primary Insurance Name' />
                                    <PaginatedDropdown
                                        datakey="list"
                                        searchkey="description"
                                        url={`${API_URL}/api/1.0.0/insurance?`}
                                        mapOptions={(records) =>
                                            mapOptions(
                                                records,
                                                "id",
                                                labelInsuranceFunc
                                            )
                                        }
                                        placeHolder='Search Primary Insurance'
                                        onChange={(v) => {
                                                setFieldValue(
                                                    "primaryInsuranceId",
                                                    v
                                                );
                                            }
                                        }
                                    />
                                </div>

                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='Secondary Insurance Name' />
                                    <PaginatedDropdown
                                        datakey="list"
                                        searchkey="description"
                                        url={`${API_URL}/api/1.0.0/insurance?`}
                                        mapOptions={(records) =>
                                            mapOptions(
                                                records,
                                                "id",
                                                labelInsuranceFunc
                                            )
                                        }
                                        placeHolder='Search Secondary Insurance'
                                        onChange={(v) => {
                                                setFieldValue(
                                                    "secondaryInsuranceId",
                                                    v
                                                );
                                            }
                                        }
                                    />
                                </div>

                                <div style={{ width: "100%", paddingBottom: "10px" }}>
                                    <Label title='Tertiary Insurance Name' />
                                    <PaginatedDropdown
                                        datakey="list"
                                        searchkey="description"
                                        url={`${API_URL}/api/1.0.0/insurance?`}
                                        mapOptions={(records) =>
                                            mapOptions(
                                                records,
                                                "id",
                                                labelInsuranceFunc
                                            )
                                        }
                                        placeHolder='Search Tertiary Insurance'
                                        onChange={(v) => {
                                                setFieldValue(
                                                    "tertiaryInsuranceId",
                                                    v
                                                );
                                            }
                                        }
                                    />
                                </div>

                                <div
                                    className='flex center-left '
                                    style={{ gap: "5px", paddingBlock: "12px" }}>
                                    {loading && (
                                        <>
                                            <LoaderIcon
                                                style={{
                                                    width: "10px",
                                                    height: "10px",
                                                }}
                                            />
                                            <p
                                                className='color-primary f-w-300'
                                                style={{ fontSize: "12px" }}>
                                                Saving patient's details, please wait...
                                            </p>
                                        </>
                                    )}
                                    {error && (<>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                            }}
                                            viewBox='0 0 24 24'>
                                            <path
                                                d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
                                                fill='red'
                                            />
                                        </svg>
                                        <p
                                            className=' f-w-300'
                                            style={{ fontSize: "12px", color: "red" }}>{error}</p></>)}
                                </div>

                                <div className="flex center-center" style={{ width: "100%", gap: "10px", paddingBottom: "10px" }}>
                                    <ButtonLightTextIcon
                                        icon={<svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'>
                                            <path
                                                d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
                                                fill='#283f54'
                                            />
                                        </svg>}
                                        title='Close'
                                        style={{ width: "100%" }}
                                        type='button'
                                        onClick={() => { setShow(false); resetForm(); }}
                                        disabled={loading}
                                    />
                                    <ButtonTextIcon
                                        title='Add'
                                        style={{ width: "100%" }}
                                        icon={
                                            loading ? <LoaderIcon /> : (<svg
                                                fill='#000000'
                                                version='1.1'
                                                width='800px'
                                                height='800px'
                                                viewBox='0 0 400 400'
                                                xmlSpace='preserve'>
                                                <g>
                                                    <g>
                                                        <path d='M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z' />
                                                        <path d='M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z' />
                                                    </g>
                                                </g>
                                            </svg>)}
                                        type='button'
                                        disabled={loading}
                                        onClick={async () => {
                                            const errors = await validateForm(); 
                                            if (Object.keys(errors).length === 0) { 
                                                setLoading(true);
                                                const check = await checkPatientExists(`${values.firstName} ${values.lastName}`, values.dateOfBirth);
                                                console.log("check", check)
                                                if (check) {
                                                    setConfirmation(true); 
                                                } else {
                                                    handleSubmit();
                                                }
                                            }else{
                                                handleSubmit();
                                            }
                                        }}
                                    />
                                </div>
                                {confirmation && (<PopUpModal>
                                    <section id='opl_coremax'>
                                        <section
                                            style={{
                                                position: "fixed",
                                                inset: 0,
                                                zIndex: 9999,
                                                backgroundColor: "rgba(255, 255, 255, 0.86)",
                                            }}>
                                            <section
                                                className='col-1-1 flex center-center'
                                                style={{
                                                    height: "100vh",
                                                    width: "100vw",
                                                }}>
                                                <div
                                                    style={{
                                                        width: "486px",
                                                        padding: "24px",
                                                        height: "auto",
                                                        maxHeight: "80vh",
                                                        border: "solid 1px #f0f0f0",
                                                        background: "white",
                                                        borderRadius: "8px",
                                                        margin: "0 auto",
                                                        boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
                                                    }}>
                                                    <div
                                                        className='col-1-1 flex center-left'
                                                        style={{
                                                            borderBottom: "solid 1px #f0f0f0",
                                                            paddingBottom: "12px",
                                                            marginBottom: "16px",
                                                        }}>
                                                        <h6
                                                            className='color-primary f-w-300 opl-tooltip'
                                                            data-tooltip='View entire history of the activity below.'>
                                                            Confirmation
                                                        </h6>
                                                        <div
                                                            className='mar-l-a cursor-pointer opl-tooltip'
                                                            data-tooltip='Close to return to the previous section.'
                                                            onClick={() =>{ setConfirmation(false); setLoading(false);}}>
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='24'
                                                                height='24'
                                                                viewBox='0 0 24 24'>
                                                                <path
                                                                    d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
                                                                    fill='#283f54'
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <section>
                                                            <p>Patient with same name and date of birth found in the system, do you still want to continue?</p>
                                                        </section>
                                                    </div>
                                                    <div
                                                        className='col-1-1 flex center-left'
                                                        style={{
                                                            marginTop: "16px",
                                                            borderTop: "solid 1px #f0f0f0",
                                                            paddingTop: "16px",
                                                        }}>
                                                        <ButtonLightTextIcon
                                                            title='Cancel'
                                                            onClick={() =>{ setConfirmation(false); setLoading(false);}}
                                                            icon={
                                                                <svg
                                                                    clipRule="evenodd"
                                                                    fillRule="evenodd"
                                                                    strokeLinejoin="round"
                                                                    strokeMiterlimit="2"
                                                                    viewBox="0 0 24 24"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width='19.995'
                                                                    height='19.996'
                                                                >
                                                                    <path
                                                                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                                                        fillRule="nonzero" 
                                                                    />
                                                                </svg>
                                                            }
                                                        />

                                                        <div style={{ marginLeft: "auto" }}>

                                                        </div>
                                                        <ButtonTextIcon
                                                            title='Confirm'
                                                            onClick={() => {  handleSubmit(); setConfirmation(false); }}
                                                            icon={
                                                                <svg
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'>
                                                                    <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
                                                                </svg>
                                                            }
                                                        />

                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </section>
                                </PopUpModal>)}
                            </Form>
                        )}
                    </Formik>
                </div>}
        </>
    )
}

export default AddPatientForm;