import React, { useEffect, useState } from "react";
import FullPageLoader from "../components/common/FullPageLoader";
import Header from "../components/common/ui/Header";
import { withRouter } from "react-router-dom";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";

const Transactions = ({ history, location, activePage }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [data, setData] = useState([]);

	const callApi = async () => {
		try {
			setLoading(true);
			const api = await fetch(
				API_URL + "api/1.0.0/transaction?pageNo=0&pageSize=100",
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			const response = await api.json();
			if (response) {
				setData(response.data.transactionHistoryList);
			} else {
				setError("Something happened, shoot!");
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		callApi();
	}, []);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='octopi-coremax-ui'>
					<FullPageLoader isActive={loading} />
					{error && (
						<div>
							<h1>Error</h1>
						</div>
					)}
					{data && (
						<>
							<br />
							<div className='rs-table-holder'>
								<table className='rs-table'>
									<thead>
										<tr>
											<th>Transaction Number</th>
											<th>Sender's Name</th>
											<th>Amount</th>
											<th>Description</th>
											<th>Transaction At</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										{data.map((d, i) => (
											<tr key={i}>
												<td>
													{d.uniqueTransactionNumber}
												</td>
												<td>
													{d.customerName
														? d.customerName
														: "-"}
												</td>
												<td>
													{parseFloat(
														d.amount
													).toFixed(2)}
													/- USD
												</td>
												<td>{d.reference}</td>
												<td>
													{formatDateTime(
														d.createdAt
													)}
												</td>
												<td>
													<div className='flex center-left'>
														{d.transactionStatus ===
															"INITIATED" && (
															<div
																className='b-r-20 flex center-center col-3-4'
																style={{
																	background:
																		"#f7d635",
																	padding:
																		"12px 24px",
																}}>
																<p className='color-white l-h-1'>
																	Pending
																</p>
															</div>
														)}
														{d.transactionStatus ===
															"SUCCESS" && (
															<div
																className='b-r-20 flex center-center col-3-4'
																style={{
																	background:
																		"green",
																	padding:
																		"12px 24px",
																}}>
																<p className='color-white l-h-1'>
																	Successfull
																</p>
															</div>
														)}
														{d.transactionStatus ===
															"FAILED" && (
															<div
																className='b-r-20 flex center-center col-3-4'
																style={{
																	background:
																		"red",
																	padding:
																		"12px 24px",
																}}>
																<p className='color-white l-h-1'>
																	Failed
																</p>
															</div>
														)}
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</>
					)}
				</section>
			</section>
		</section>
	);
};

const formatServerDate = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr0 = str.split("T");
	const arr = arr0[0].split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

function formatDateTime(dateString) {
	const date = new Date(dateString);

	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");

	const formattedDate = `${formatServerDate(
		dateString
	)} ${hours}:${minutes}:${seconds}`;

	return formattedDate;
}

export default withRouter(Transactions);
