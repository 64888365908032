import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useRef, useState } from "react";
import {
	ALLOWED_FILE_TYPES,
	API_URL,
	PLACE_OF_SERVICE,
	SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { formatServerDate, handleDatePickerChange, sleep } from "../../opl-utilities/helpers";
import { useUI } from "../../context/ui";
import AddPatientForm from "./AddPatientForm";
import DatePicker from "react-datepicker";
import { formatISO, parseISO } from "date-fns";


const Label = ({ title, required }) => {
	return (
		<p
			className='color-primary f-w-600'
			style={{ paddingBottom: "5px" }}>
			{title}
			{required && (
				<span style={{ color: "red", marginLeft: "4px" }}>*</span>
			)}
		</p>
	);
};

const AddForm = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [procedures, setProcedures] = useState(null);
	const { addToastMessage } = useUI();
    const [show, setShow] = useState(false);
	const initialValues = {
		document_detail: [],
		user_id: JSON.parse(localStorage.getItem("LOGGED_IN_USER")).id,
		patient_id: null,
		patient_name: "",
		event_type: null,
		procedure_id: [],
		paytoadmin_id: null,
		account_number: "",
		event_surgeon: null,
		event_surgical_assitant: null,
		event_facility: null,
		admission_date: "",
		event_start_date: new Date().toISOString().split("T")[0],
		event_start_time: "",
		event_end_date: "",
		event_end_time: "",
		placeOfService: "",
		event_flags: null,
		modified_by: JSON.parse(localStorage.getItem("LOGGED_IN_USER")).id,
		notes: "",
	};

	const validationSchema = Yup.object({
		event_type: Yup.mixed()
			.nullable()
			.test("event-type-required", "Required",
				function (value) { return value !== null && value.value; }),
		event_surgeon: Yup.mixed()
			.nullable()
			.test("surgeon-required", "Required",
				function (value) { return value !== null && value.value; }),
		event_facility: Yup.mixed()
			.nullable()
			.test("facility-required", "Required",
				function (value) { return value !== null && value.value; }),
		event_surgical_assitant: Yup.mixed()
			.nullable()
			.test("surgical-assitant-required", "Required",
				function (value) { return value !== null && value.value; }),
		event_start_date: Yup.string().required("Required"),
		event_start_time: Yup.string().required("Required"),
		placeOfService: Yup.string().required("Required"),
	});

	function convertToMMDDYYYY(dateString) {
		const date = new Date(dateString);
		const formattedDate =
			("0" + (date.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + date.getDate()).slice(-2) +
			"/" +
			date.getFullYear();
		return formattedDate;
	}

	function convertTo12HourFormat(timeString) {
		const [hours, minutes] = timeString.split(":");
		const hour = parseInt(hours, 10);
		const period = hour >= 12 ? "PM" : "AM";
		const convertedHour = hour % 12 || 12;
		return `${("0" + convertedHour).slice(-2)}:${minutes} ${period}`;
	}

	const createActivity = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const loggedInUser = JSON.parse(localStorage.getItem("LOGGED_IN_USER"));

			const files = values.document_detail.map((o) => ({ type: o.file_type, fileUrl: o.file }));


			/* ADD ACTIVITY VALIDATION START */ 
            const hasFile = files.length;
            const hasRequiredFiled = values.event_facility?.value && values.patient_id?.value && values.event_surgical_assitant;
            if ( !hasFile && !hasRequiredFiled ) {
                addToastMessage({
                    status: false,
                    message: `Please upload a file or select Facility, Patient, and Assistant to proceed with creating the activity.`,
                })
                setStatus("Please upload a file or select Facility, Patient, and Assistant to proceed with creating the activity.");
                return false;
            } 
            /* ADD ACTIVITY VALIDATION START */

			/* ADD FACESHEET VALIDATION START */
			// const hasFacesheet = files.some((file) => file.type === "facesheet");
			// if (!hasFacesheet) {
			// 	addToastMessage({
			// 		status: false,
			// 		message: `Please add a facesheet to proceed with creating the activity.`,
			// 	})
			// 	setStatus("Please add a facesheet to proceed with creating the activity.");
			// 	return false;
			// }
			/* ADD FACESHEET VALIDATION END   */

			const payload = {};

			if (values.document_detail.length) payload.files = files;
			if (loggedInUser?.id) payload.user_id = loggedInUser.id;
			if (values.event_surgeon?.value) payload.event_surgeon = values.event_surgeon.value + "";
			if (values.patient_id?.value) payload.patient_id = values.patient_id.value + "";
			if (values.paytoadmin_id) payload.paytoadmin_id = values.paytoadmin_id.value + "";
			payload.modified_by = loggedInUser.id;
			if (values.patient_name) payload.patient_name = values.patient_name;
			if (values.procedure_id.length) payload.procedure_id = values.procedure_id.map(item => item.val).join(",");
			if (values.event_facility?.value) payload.event_facility = values.event_facility.value + "";
			if (values.event_surgical_assitant?.value) payload.event_surgical_assitant = values.event_surgical_assitant.value + "";
			if (values.account_number) payload.account_number = values.account_number + "";
			if (values.placeOfService) payload.placeOfService = values.placeOfService;
			if (values.event_start_date) payload.event_start_date = convertToMMDDYYYY(values.event_start_date);
			if (values.event_end_date) payload.event_end_date = convertToMMDDYYYY(values.event_end_date);
			if (values.event_start_time) payload.event_start_time = convertTo12HourFormat(values.event_start_time);
			if (values.event_end_time) payload.event_end_time = convertTo12HourFormat(values.event_end_time);
			if (values.event_type?.value) payload.event_type = values.event_type.value + "";
			if (values.event_flags?.value) payload.event_flags = values.event_flags.value + "";
			if (values.admission_date) payload.admission_date = convertToMMDDYYYY(values.admission_date);
			if (values.notes) payload.event_notes = values.notes;

			const payloadJSON = JSON.stringify(payload);

			const resp = await fetch(API_URL + `/api/1.0.0/activity`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: payloadJSON,
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					if (response.result.responseCode === 200) {
						return true;
					} else {
						addToastMessage({
							status: false,
							message: `Something went wrong, please try again later.`,
						});
						return false;
					}
				}
			} else {
				const response = await resp.json();
				if (response) {
					addToastMessage({
						status: false,
						message: `${response.responseDescription || "Something went wrong, please try again later."}`,
					});
					return false;
				}
			}
		} catch (e) {
			addToastMessage({
				status: false,
				message: "Something went wrong, please try again later.",
			});
			console.log("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};

	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
			obj: record,
		}));

	const labelPatientsFunc = (o) => `${o.firstName?.toUpperCase()} ${o.lastName?.toUpperCase()} ${o.dateOfBirth ? `(${formatServerDate(o.dateOfBirth)})` : ''}`;
	const labelSurgeonFunc = (o) => `${o.fullName}`;
	const labelFacilityFunc = (o) => `${o.facilityName}`;
	const labelAssistantsFunc = (o) => `${o.fullName}`;
	const labelProcedureFunc = (o) => `${o.procedureName}`;
	const labelPayToFunc = (o) => `${o.payToName}`;
	const labelEventFlagsFunc = (o) => `${o.scheduleFlag}`;
	const labelEventTypeFunc = (o) => `${o.eventTypeName}`;

	return (
		<Formik
			initialValues={initialValues}
			// validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await createActivity(values);
				if (api) {
					resetForm();
					setStatus(
						"You have successfully created an activity, thank you."
					);
					addToastMessage({
						status: true,
						message: `You have successfully created an activity, thank you.`,
					})
					history.push("/activities");
				}  
			}}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td>
										<Label
											title='Event Type'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/event-type?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelEventTypeFunc
												)
											}
											error={errors.event_type && touched.event_type}
											placeHolder='Search'
											selectedValue={values.event_type}
											onChange={(v) => {
												setFieldValue(
													"event_type",
													v
												);
											}}
										/>
									</td>
									<td width='50%'>
										<Label title='Patient' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/patients?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelPatientsFunc
												)
											}
											selectedValue={values.patient_id}
											placeHolder='Search'
											onChange={(v) => {
												if (v) {
													setFieldValue(
														"patient_id",
														v
													);
													setFieldValue(
														"patient_name",
														v.label
													);
													setFieldValue(
														"account_number",
														v.obj.accountNumber
													);
												} else {
													setFieldValue(
														"patient_id",
														null
													);
													setFieldValue(
														"patient_name",
														""
													);
													setFieldValue(
														"account_number",
														""
													);
												}
											}}
										/>
										<p
											onClick={() => { setShow(true) }}
											style={{
												paddingTop: "4px",
												color: "#8f8f8f",
											}}>
											Could't find the patient?{" "}
											<span
												className='cursor-pointer color-secondary hover-color-primary hover-t-d-ul transition' >
												Click to add
											</span>
										</p> 
									</td>
								</tr>
								<tr >
									<td colSpan="2"> 
										<AddPatientForm
											setShow={setShow}
											show={show}
											callback={(v) => {
												setFieldValue(
													"patient_id",
													v
												);
												setFieldValue(
													"patient_name",
													v.label
												);
												setFieldValue(
													"account_number",
													""
												);
											}} />
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<NestedDocumentForm
											onAdd={(newDocument) => {
												setFieldValue('document_detail', [
													...values.document_detail,
													newDocument,
												]);
											}}
											p={values?.patient_id}
											list={values?.document_detail}
											onRemove={(index) => {
												const updatedData = values.document_detail.filter((_, i) => i !== index);
												setFieldValue("document_detail", updatedData);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Procedures' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/procedure?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelProcedureFunc
												)
											}
											selectedValue={procedures}
											placeHolder='Search'
											onChange={async (v) => {
												if (v) {
													setProcedures(v);
													const p =
														values.procedure_id;
													const n = {
														val: v.value,
														name: v.label,
													};
													if (
														!p.some(
															(item) =>
																item.val ===
																v.value
														)
													) {
														setFieldValue(
															"procedure_id",
															[
																...values.procedure_id,
																n,
															]
														);
													}
													await sleep();
													setProcedures(null);
												}
											}}
										/>
										{values.procedure_id.length > 0 && (
											<ul
												className='col-1-1 t-a-l'
												style={{
													padding: "10px 0",
												}}>
												{values.procedure_id.map(
													(p, j) => (
														<li
															data-tooltip={`Click to delete the procedure.`}
															onClick={() => {
																const filtered =
																	values.procedure_id.filter(
																		(obj) =>
																			obj.val !==
																			p.val
																	);
																setFieldValue(
																	"procedure_id",
																	filtered
																);
															}}
															style={{
																listStyle:
																	"none",
																marginRight:
																	"4px",
																marginBottom:
																	"4px",
																borderRadius:
																	"2px",
																lineHeight:
																	"32px",
																padding:
																	"10px 12px",
																verticalAlign:
																	"middle",
															}}
															key={j}
															className='opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition'>
															<div className='flex center-left'>
																<p
																	className='of-hid'
																	style={{
																		whiteSpace:
																			"nowrap",
																		width: "108px",
																		textOverflow:
																			"ellipsis",
																		fontSize:
																			"12px",
																	}}>
																	{p.name}
																</p>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																	style={{
																		height: "16px",
																		width: "16px",
																	}}>
																	<path
																		fill='#283f54'
																		d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.151,17.943-4.143-4.1L7.891,18,6.058,16.167l4.1-4.157L6,7.891,7.833,6.058l4.155,4.1L16.094,6l1.849,1.849-4.1,4.141L18,16.094Z'
																	/>
																</svg>
															</div>
														</li>
													)
												)}
											</ul>
										)}
										<p
											style={{
												paddingTop: "4px",
												color: "#8f8f8f",
											}}>
											Could't find the procedure?{" "}
											<span
												className='cursor-pointer color-secondary hover-color-primary hover-t-d-ul transition'
												onClick={() =>
													history.push(
														"/add-procedure"
													)
												}>
												Click to add
											</span>
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='Pay To' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/practice?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelPayToFunc
												)
											}
											placeHolder='Search'
											onChange={(v) => {
												setFieldValue(
													"paytoadmin_id",
													v
												);
											}}
										/>
									</td>
									<td>
										<Label title='Account Number' />
										<Field
											type='text'
											placeholder='Enter Acc. No.'
											name='account_number'
											className={`col-1-1 primary ${errors.account_number &&
												touched.account_number
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Surgeon'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/surgeon?`}
											error={errors.event_surgeon && touched.event_surgeon}
											selectedValue={values.event_surgeon}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelSurgeonFunc
												)
											}
											placeHolder='Search'
											onChange={(v) => {
												setFieldValue(
													"event_surgeon",
													v
												);
											}}
										/>
									</td>
									<td>
										<Label
											title='Facility'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/facility?`}
											error={errors.event_facility && touched.event_facility}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelFacilityFunc
												)
											}
											selectedValue={values.event_facility}
											placeHolder='Search'
											onChange={(v) => {
												setFieldValue(
													"event_facility",
													v
												);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Assistant'
											required={false}
										/>
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/assistants?`}
											error={errors.event_surgical_assitant && touched.event_surgical_assitant}
											selectedValue={values.event_surgical_assitant}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelAssistantsFunc
												)
											}
											placeHolder='Search'
											onChange={(v) => {
												setFieldValue(
													"event_surgical_assitant",
													v
												);
											}}
										/>
									</td>
									<td>
										<Label
											title='Admission Date'
										// required={false}
										/>
										<DatePicker
											className={`primary ${errors.admission_date && touched.admission_date ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.admission_date ? parseISO(values.admission_date) : ""} 
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "admission_date", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Start Date'
											required={false}
										/>
										<DatePicker
											className={`primary ${errors.event_start_date && touched.event_start_date ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.event_start_date ? parseISO(values.event_start_date) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "event_start_date", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
									</td>
									<td>
										<Label
											title='Start Time'
											required={false}
										/>
										<Field
											type='time'
											name='event_start_time'
											className={`col-1-1 primary ${errors.event_start_time &&
												touched.event_start_time
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label title='End Date' />
										<DatePicker
											className={`primary ${errors.event_end_date && touched.event_end_date ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.event_end_date ? parseISO(values.event_end_date) : ""}  
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "event_end_date", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
									</td>
									<td>
										<Label title='End Time' />
										<Field
											type='time'
											name='event_end_time'
											className={`col-1-1 primary ${errors.event_end_time &&
												touched.event_end_time
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<Label
											title='Place of Service'
											required={false}
										/>
										<Field
											as='select'
											name='placeOfService'
											className={`col-1-1 primary ${errors.placeOfService &&
												touched.placeOfService
												? "error"
												: ""
												}`}>
											<option value=''>Select</option>
											{PLACE_OF_SERVICE.map((d, i) => (
												<option value={d.value} key={i}>
													{d.label}
												</option>
											))}
										</Field>
									</td>
									<td>
										<Label title='Event Flags' />
										<PaginatedDropdown
											url={`${API_URL}/api/1.0.0/reference/event-flags?`}
											mapOptions={(records) =>
												mapOptions(
													records,
													"id",
													labelEventFlagsFunc
												)
											}
											selectedValue={values.event_flags}
											placeHolder='Search'
											onChange={(v) => {
												if (v) {
													setFieldValue(
														"event_flags",
														v
													);
												}
											}}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<Label title='Notes' />
										<Field
											as='textarea'
											name='notes'
											placeholder='Add Notes'
											className={`primary ${errors.notes && touched.notes
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan='2'>
										<p
											style={{
												paddingTop: "10px",
												paddingBottom: "10px",
												color: "#8f8f8f",
											}}
											className='l-h-1'>
											Click checkbox only if you do not
											know the end time.
										</p>
										<div>
											<label className='cursor-pointer'>
												<div
													className='flex center-left'
													style={{ gap: "12px" }}>
													<Field
														type='checkbox'
														name='endTimeNotConfirmed'
														className={`primary ${errors.endTimeNotConfirmed &&
															touched.endTimeNotConfirmed
															? "error"
															: ""
															}`}
													/>
													<p className='color-light'>
														End time not confirmed.
													</p>
												</div>
											</label>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => history.push("/activities")}
							icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
						/>
						<ButtonTextIcon
							type='submit'
							disabled={loading}
							title='Create Now'
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='19.995'
										height='19.996'
										viewBox='0 0 19.995 19.996'>
										<path
											d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
											transform='translate(-2.014 -1.995)'
										/>
									</svg>
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};

const NestedDocumentForm = ({ onAdd, onRemove, p, list }) => {
	const [addFile, setAddFile] = useState(true);
	const fileUploadRef = useRef();
	let typeCounters = {};
	const validationSchema = Yup.object().shape({
		file_type: Yup.string().required('File Type is required'),
		file: Yup.string().required('File is required'),
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	return (
		<Formik
			initialValues={{
				file_type: '',
				file: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm }) => {
				onAdd(values);
				setAddFile(true);
				resetForm();
				handleReset();
			}}
		>
			{({ errors, touched, setFieldValue, values, submitForm }) => (
				<Form>
					<table>
						<tbody>
							{list && (
								<tr>
									<td colSpan="2" >
										<div className="inner-data">
											<table>
												<thead>
													<tr>
														<th className="color-primary">File Type</th>
														<th className="color-primary">File</th>
														<th className="color-primary" style={{ textAlign: 'center' }}>Action</th>
													</tr>
												</thead>
												<tbody>
													{list?.length ? list.map((d, index) => {
													
													if(index === 0 ){ typeCounters = {}; }

													const type = d.file_type; 
													typeCounters[type] = (typeCounters[type] || 0) + 1;

													return(
														<tr key={index}>
															<td><span className="badge color-primary">{d.file_type}</span></td>
															<td className="color-primary t-t-u l-s-n ">{`${p?.label ? p?.label+"-" : ""}${d.file_type || ""}-${typeCounters[d.file_type]}`}</td>
															<td>
																<div className="flex center-center">
																	<span className="cursor-pointer opl-tooltip" data-tooltip='Click to Remove File.'
																		onClick={() => { onRemove(index) }}
																	>
																		<svg
																			height="15px"
																			width="15px"
																			viewBox="0 0 330 330"
																			fill="red"
																		>
																			<g id="XMLID_6_">
																				<g id="XMLID_11_">
																					<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																				</g>
																				<g id="XMLID_18_">
																					<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																				</g>
																				<g id="XMLID_23_">
																					<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																				</g>
																			</g>
																		</svg>
																	</span>
																</div>
															</td>
														</tr>
													)}) : (
														<tr>
															<td colSpan="3"><div className="t-a-c color-primary"> No files uploaded. </div></td>
														</tr>)}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							)}
							{addFile ? <tr>
								<td colSpan="2">
									<div className="flex center-center">
										<div className='opl-tooltip' data-tooltip='Click to Add File.'>
											<Label title="&nbsp;" />
											<ButtonLightTextIcon
												title="Add File"
												type="button"
												onClick={() => { setAddFile((pre) => (!pre)) }}
												icon={<svg
													fill="#000000"
													version="1.1"
													width="800px"
													height="800px"
													viewBox="0 0 400 400"
													xmlSpace="preserve"
												>
													<g>
														<g>
															<path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
															<path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
														</g>
													</g>
												</svg>}
											/>
										</div>
									</div>
								</td>
							</tr>
								: <>
									<tr>
										<td colSpan='2'>
											<Label title='File Type' />
											<Field
												as='select'
												name='file_type'
												className={`col-1-1 primary ${errors.file_type &&
													touched.file_type
													? "error"
													: ""
													}`}>
												<option value=''>Select</option>
												{
													ALLOWED_FILE_TYPES.map(({ value, label }) => {
														const isFacesheet = value === "facesheet";
														const isFacesheetPresent = list.some((file) => file.file_type === "facesheet");

														if (isFacesheet && isFacesheetPresent) return null;

														return (
															<option value={value} key={label}>
																{label}
															</option>
														);
													}).filter(Boolean)
												}
											</Field>
										</td>
									</tr>
									{values.file_type &&
										<tr>
											<td colSpan='2'>
												<div
													className='flex center-left'
													style={{ gap: "8px" }}>
													<div
														className='opl-tooltip'
														data-tooltip='Please only select PDF files for upload.'>
														<Label title='Upload File' />
													</div>
													<div
														className='opl-tooltip'
														data-tooltip='Please only select PDF files for upload.'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															style={{
																height: "12px",
																width: "12px",
																marginBottom: "5px",
															}}>
															<path
																d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
																fill='#5dc6b3'
															/>
														</svg>
													</div>
												</div>
												<DragFileUpload
													ref={fileUploadRef}
													fileType={values.file_type}
													error={errors.file &&
														touched.file}
													afterUpload={(url) => {
														if (url) {
															setFieldValue("file", url);
															submitForm();
														}
													}}
												/>
											</td>
										</tr>
									}
								</>}
						</tbody>
					</table>
				</Form>
			)}
		</Formik>
	);
};

export default AddForm;
