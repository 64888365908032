import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useRef, useState } from "react";
import { 
	API_URL, 
	DEGREE_OPTIONS, 
	DEGREE_STATUS_OPTIONS, 
	ROLE_OPTIONS, 
	STATUS_USER_OPTIONS, 
	SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import { formatServerDate, handleDatePickerChange, sleep } from "../../opl-utilities/helpers";
import Tabs from "./Tabs";
import { withRouter } from "react-router-dom";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import DatePicker from "react-datepicker";
import { formatISO, parseISO } from "date-fns";
const Label = ({ title, required }) => {
	return (
		<p
			className='color-primary f-w-600'
			style={{ paddingBottom: "5px" }}>
			{title}
			{required && (
				<span style={{ color: "red", marginLeft: "4px" }}>*</span>
			)}
		</p>
	);
};

const AddForm = ({ history, location }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [tab , setTab] = useState("basic-information");
	const { addToastMessage } = useUI(); 
	// const queryParams = new URLSearchParams(location.search);

	// ====== FILTER QUERY PARAMS

	// const tab = queryParams.get("tab") || "basic-information";

	const updateQueryParam = (key, value) => {
		setTab(value);
		// value ? queryParams.set(key, value) : queryParams.delete(key);
		// history.push({ search: queryParams.toString() });
	};

	const initialValues = {
		facilities_detail: [],
		vaccinations_detail: [],
		background_checks_detail: [],
		pto_detail: [],
		degrees_detail: [],
		firstName: "", //required 
		lastName: "", //required
		preferredName:"",
		workEmail: "", // required|email|unique:users
		personalEmail: "",
		workCellPhone: "",
		cellPhone: "",
		status: "1",
		homeAddress: "", // "practice1Address1": "456 Practice Rd",
		homeZip: "",  // "practice1Zip": "12345",
		homeState: null, // "practice1State": "NY",
		homeCity: null, // "practice1City": "New York",
	  
		practice1Address2: "",
		practice1Address3: "",
		practice2Address1: "",
		practice2Address2: "",
		practice2State: null,
		practice2City: null,
		practice2Zip: "",
	  
		drivingLicense: "",
		drivingLicenseState: null,  //required
		drivingIssueDate: "",
		drivingExpiryDate: "",
		npi: "",
		primaryTaxonomy: "",
		secondaryTaxonomy: "",
		certificate: null, //required
		certificateNumber: "",
		region: null, //required
		licenseIssueDate: "",
		licenseExpiryDate: "",
		joiningDate: "",
		terminationDate: "",
		role:""
	};

	const validationSchema = Yup.object({
		firstName: Yup.string().min(2, "First Name must be at least 2 characters long").required('First name is required'),
		lastName: Yup.string().min(2, "Last Name must be at least 2 characters long").required('Last name is required'),
		joiningDate: Yup.string().required('Start Date is required'),
		workEmail: Yup.string() .email('Work email must be a valid email') .required('Work email is required'),
		drivingLicenseState: Yup.mixed()
		.nullable()
		.test(
		  "drivingLicenseState-required",
		  "Driving license state is required",
		  function (value) { 
			const { drivingLicense } = this.parent;
			if (drivingLicense) { 
			  return value !== null && value.value;  
			}
			return true; 
		  }
		), 
		certificate: Yup.mixed()
			.nullable()
			.test("certificate-required", "Certificate is required",
				function (value) { return value !== null && value.value; }),
		region: Yup.mixed()
		.nullable()
		.test("region-required", "Region is required",
			function (value) { return value !== null && value.value; }),
	  });

	function convertToMMDDYYYY(dateString) {
		const date = new Date(dateString);
		const formattedDate =
			("0" + (date.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + date.getDate()).slice(-2) +
			"/" +
			date.getFullYear();
		return formattedDate;
	}

	function convertTo12HourFormat(timeString) {
		const [hours, minutes] = timeString.split(":");
		const hour = parseInt(hours, 10);
		const period = hour >= 12 ? "PM" : "AM";
		const convertedHour = hour % 12 || 12;
		return `${("0" + convertedHour).slice(-2)}:${minutes} ${period}`;
	}

	const createPhysicianAssistant = async (values) => {
		console.log(values)
		setLoading(true);
		setStatus("");
		try {
			const payload = JSON.stringify({
				facilities: values?.facilities_detail.length > 0 ? values?.facilities_detail?.map((detail) => ({
					attachment: detail.attachments,
					expiryDate: detail.date_expiry ? convertToMMDDYYYY(detail.date_expiry) : null,
					renewalDate: detail.date_renewed? convertToMMDDYYYY(detail.date_renewed) : null,
					feePaid: detail.fee_paid ?  parseFloat(detail.fee_paid.toFixed(2)) : 0.00 ,
					id: detail.facility.value,
				})) : null,
				vaccinations: values?.vaccinations_detail.length ?  values?.vaccinations_detail.map((detail) => ({
					id: detail.vaccine.value,
					name: detail.vaccine.label,
					administeredDate: detail.date_administer ? convertToMMDDYYYY(detail.date_administer) : null,
					expiryDate: detail.date_expiry ? convertToMMDDYYYY(detail.date_expiry) : null,
					attachment: detail.attachments,
				})): null,
				backgroundChecks: values?.background_checks_detail.length ?  values?.background_checks_detail.map((detail) => ({
					notes: detail.notes || " ",
					feePaid: detail.fee_paid ?  parseFloat(detail.fee_paid.toFixed(2)) : 0.00 ,
					attachment: detail.attachments,
					datePerformed: detail.date_performed ? convertToMMDDYYYY(detail.date_performed) : null,
				})): null,
				ptos: values?.pto_detail.length ?  values?.pto_detail.map((detail) => ({
					date: detail.date ? convertToMMDDYYYY(detail.date) : null,
					type: detail.type || "",
					amount: detail.amount ? parseFloat(detail.amount.toFixed(2)) : 0.00,
					attachment: detail.attachments,
				})): null,
				degrees: values?.degrees_detail.length ?  values?.degrees_detail.map((detail) => ({
					dateAcquired: detail.date_aquired ? convertToMMDDYYYY(detail.date_aquired) : null,
					dateExpired: detail.date_expiry ? convertToMMDDYYYY(detail.date_expiry) : null,
					degree: detail.degree || "",
					nameOfSchool: detail.nameOfSchool || "",
					state: detail.state.value,
					status: detail.degree_status,
					attachment: detail.attachments,
				})): null,
				firstName: values?.firstName || "",
				lastName: values?.lastName || "",
				preferredName: values?.preferredName || "",
				workEmail: values?.workEmail || "",
				personalEmail: values?.personalEmail || "",
				workCellPhone: values?.workCellPhone || "",
				cellPhone: values?.cellPhone || "",
				status: Number(values?.status),
				homeAddress: values?.homeAddress || "",
				homeZip: values?.homeZip || "",
				homeState: values?.homeState?.value || "",
				homeCity: values?.homeCity?.label || "",
				practice1Address2: values?.practice1Address2 || "",
				practice1Address3: values?.practice1Address3 || "",
				practice2Address1: values?.practice2Address1 || "",
				practice2Address2: values?.practice2Address2 || "",
				practice2State: values?.practice2State?.value || "",
				practice2City: values?.practice2City?.label || "",
				practice2Zip: values?.practice2Zip || "",
				drivingLicense: values?.drivingLicense || "",
				drivingLicenseState: values?.drivingLicenseState?.value || "",
				drivingIssueDate: values?.drivingIssueDate ? convertToMMDDYYYY(values.drivingIssueDate) : null,
				drivingExpiryDate: values?.drivingExpiryDate ? convertToMMDDYYYY(values.drivingExpiryDate) : null,
				npi: values?.npi || "",
				primaryTaxonomy: values?.primaryTaxonomy || "",
				secondaryTaxonomy: values?.secondaryTaxonomy || "",
				certificate: values?.certificate?.value || "",
				certificateNumber: values?.certificateNumber || "",
				region: values?.region?.value || "",
				licenseIssueDate: values?.licenseIssueDate ? convertToMMDDYYYY(values.licenseIssueDate): null,
				licenseExpiryDate: values?.licenseExpiryDate ? convertToMMDDYYYY(values.licenseExpiryDate) : null,
				joiningDate: values?.joiningDate  ? convertToMMDDYYYY(values.joiningDate) : null,
				terminationDate: values?.terminationDate ? convertToMMDDYYYY(values.terminationDate) : null,
				role: values?.role || "",
			});

			console.log("Payload:::", payload);

			const resp = await fetch(API_URL + `/api/1.0.0/surgical-assistant`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: payload,
			});
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					return response.result.responseCode === 200 ? true : false;
				}  else {
					const responseData = await resp.json();
					if (responseData) {
						setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
						addToastMessage({
							status: false,
							message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
						});
						return false;
					}
					setStatus("Something went wrong, please try again later.");
					addToastMessage({
						status: false,
						message: `Something went wrong, please try again later.`,
					});
					return false;
				}
			} else {
				const responseData = await resp.json();
				if (responseData) {
					setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
					addToastMessage({
						status: false,
						message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
					});
					return false;
				}
				setStatus("Something went wrong, please try again later.");
				addToastMessage({
					status: false,
					message: `Something went wrong, please try again later.`,
				});
				return false;
			}
		} catch (e) {
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			console.log("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};



	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
			obj: record,
		}));

	const labelStateFunc = (o) => `${o.name}`;
    const labelCityFunc = (o) => `${o.name}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				console.log(values);
				const api = await createPhysicianAssistant(values); 
				if (api) {
					resetForm();
					setStatus(
						"You have successfully created a surgical assistant, thank you."
					);
                    addToastMessage({
						status: true,
						message: `You have successfully created a surgical assistant, thank you.`,
					})
					history.push("/surgical_assistance");
				} else {
					setStatus("Something went wrong, please try again later.");
				}
				setLoading(false);
			}}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
			}) => (
				<Form>
					<div className='col-1-1'>
						<Tabs
							options={[
								{
									name: "Basic Information",
									slug: "basic-information",
									content: (<table>
										<tbody> 
											<br />
											<tr>
												<td width='25%'>
													<Label title='First Name' required={true} />
													<Field
														type='text'
														placeholder='Enter First Name'
														name='firstName'
														className={`col-1-1 primary ${errors.firstName &&
															touched.firstName
															? "error"
															: ""
															}`}
													/>
													<ErrorMessage
														name='firstName'
														component='p'
														className='error-messages'
													/>
												</td>
												<td width='25%'>
													<Label title='Last Name' required={true} />
													<Field
														type='text'
														placeholder='Enter Last Name'
														name='lastName'
														className={`col-1-1 primary ${errors.lastName &&
															touched.lastName
															? "error"
															: ""
															}`}
													/>
													<ErrorMessage
														name='lastName'
														component='p'
														className='error-messages'
													/>
												</td>
												<td width='25%'>
													<Label title='Preferred Name' />
													<Field
														type='text'
														placeholder='Enter Preferred Name'
														name='preferredName'
														className={`col-1-1 primary ${errors.preferredName &&
															touched.preferredName
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='Work Email' required={true} />
													<Field
														type='email'
														placeholder='Enter Work Email'
														name='workEmail'
														className={`col-1-1 primary ${errors.workEmail &&
															touched.workEmail
															? "error"
															: ""
															}`}
													/>
												</td>
												
											</tr>
											<tr>
											<td width='25%'>
													<Label title='Personal Email' />
													<Field
														type='email'
														placeholder='Enter Personal Email'
														name='personalEmail'
														className={`col-1-1 primary ${errors.personalEmail &&
															touched.personalEmail
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='Cell Phone' />
													<Field
														type='text'
														placeholder='Enter Cell Phone'
														name='workCellPhone'
														className={`col-1-1 primary ${errors.workCellPhone &&
															touched.workCellPhone
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='Work Phone' />
													<Field
														type='text'
														placeholder='Enter Work Phone'
														name='cellPhone'
														className={`col-1-1 primary ${errors.cellPhone &&
															touched.cellPhone
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='Active Status' />
													<Field
														as='select'
														name='status'
														className={`col-1-1 primary ${errors.status &&
															touched.status
															? "error"
															: ""
															}`}>
														<option value=''>Select Status</option>
														{STATUS_USER_OPTIONS.map((d, i) => (
															<option value={d.value} key={i}>
																{d.label}
															</option>
														))}
													</Field>
												</td>
												
											</tr>
											<tr>
												<td colSpan="4">
													<Label title='Home Address' />
													<Field
														as='textarea'
														placeholder='Enter Home Address'
														name='homeAddress'
														className={`col-1-1 primary ${errors.homeAddress &&
															touched.homeAddress
															? "error"
															: ""
															}`}
													/>
												</td>
											</tr>
											<tr>

												<td width='25%'>
													<Label title='Zip' />
													<Field
														type='text'
														placeholder='Enter Zip'
														name='homeZip'
														className={`col-1-1 primary ${errors.homeZip &&
															touched.homeZip
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='State' />
													<PaginatedDropdown
														datakey="records"
														url={`${API_URL}/api/1.0.0/reference/states/US?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"code",
																labelStateFunc
															)
														}
														error={errors.homeState && touched.homeState}
														placeHolder='Search'
														selectedValue={values.homeState}
														onChange={(v) => {
																setFieldValue("homeState", v);
																setFieldValue("homeCity", null);
															}
														}
													/>
												</td>
												<td width='25%'>
													<Label title='City' /> 
													<PaginatedDropdown
														datakey="records"
														url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.homeState?.value}?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"name",
																labelCityFunc
															)
														}
														error={errors.homeCity && touched.homeCity}
														placeHolder='Search'
														selectedValue={values.homeCity}
														onChange={(v) => {
																setFieldValue(
																	"homeCity",
																	v
																);
															}
														}
													/> 
												</td>
												<td width='25%'>
													<Label title='Role' />
													<Field
														as='select'
														name='role'
														className={`col-1-1 primary ${errors.role &&
															touched.role
															? "error"
															: ""
															}`}>
														<option value=''>Select Role</option>
														{ROLE_OPTIONS.map((d, i) => (
															<option value={d.value} key={i}>
																{d.label}
															</option>
														))}
													</Field>
												</td>
											</tr>
											<tr>
												<td colSpan="2" width='50%'>
													<Label title='Practice Address' />
													<Field
														as='textarea'
														placeholder='Practice Address'
														name='practice1Address2'
														className={`col-1-1 primary ${errors.practice2Address1 &&
															touched.practice2Address1
															? "error"
															: ""
															}`}
													/>
												</td>
												<td colSpan="2" width='50%'>
													<Label title='Practice Address 2' />
													<Field
														as='textarea'
														placeholder='Enter Practice Address 2'
														name='practice2Address2'
														className={`col-1-1 primary ${errors.practice2Address2 &&
															touched.practice2Address2
															? "error"
															: ""
															}`}
													/>
												</td>
											</tr>
											<tr>
												<td width='25%'>
													<Label title='Zip' />
													<Field
														type='text'
														placeholder='Enter Zip'
														name='practice2Zip'
														className={`col-1-1 primary ${errors.practice2Zip &&
															touched.practice2Zip
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='State' />
													<PaginatedDropdown
														datakey="records"
														url={`${API_URL}/api/1.0.0/reference/states/US?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"code",
																labelStateFunc
															)
														}
														error={errors.practice2State && touched.practice2State}
														placeHolder='Search'
														selectedValue={values.practice2State}
														onChange={(v) => {
																setFieldValue("practice2State", v);
																setFieldValue("practice2City", null);
															}
														}
													/> 
												</td>
												<td width='25%'>
													<Label title='City' />
													<PaginatedDropdown
														datakey="records"
														url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.practice2State?.value}?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"name",
																labelCityFunc
															)
														}
														error={errors.practice2City && touched.practice2City}
														placeHolder='Search'
														selectedValue={values.practice2City}
														onChange={(v) => {
																setFieldValue(
																	"practice2City",
																	v
																);
															}
														}
													/>  
												</td>
												<td width='25%'>
													<Label title='Primary Taxonomy' />
													<Field
														// as='textarea'
														type='text'
														placeholder='Enter Primary Taxonomy'
														name='primaryTaxonomy'
														className={`col-1-1 primary ${errors.primaryTaxonomy &&
															touched.primaryTaxonomy
															? "error"
															: ""
															}`}
													/>
												</td>
											</tr>
											<tr>
												<td width='25%'>
													<Label title='Secondary Taxonomy' />
													<Field
														type='text'
														placeholder='Enter Secondary Taxonomy'
														name='secondaryTaxonomy'
														className={`col-1-1 primary ${errors.secondaryTaxonomy &&
															touched.secondaryTaxonomy
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='NPI #' />
													<Field
														type="text"
														placeholder='Enter NPI #'
														name='npi'
														className={`col-1-1 primary ${errors.npi &&
															touched.npi
															? "error"
															: ""
															}`}
													/>
												</td>
											</tr>
											<tr>
												<td width='25%'>
													<Label title="Driver's License" />
													<Field
														type="text"
														placeholder="Enter Driver's License"
														name='drivingLicense'
														className={`col-1-1 primary ${errors.drivingLicense &&
															touched.drivingLicense
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title="Driver's License State"/>
													<PaginatedDropdown
														datakey="records"
														url={`${API_URL}/api/1.0.0/reference/states/US?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"code",
																labelStateFunc
															)
														}
														error={errors.drivingLicenseState && touched.drivingLicenseState}
														placeHolder='Search'
														selectedValue={values.drivingLicenseState}
														onChange={(v) => {
																setFieldValue("drivingLicenseState", v); 
															}
														}
													/> 
												</td>
												<td width='25%'>
													<Label title="Driver's License Issue Date" />
													<DatePicker
														className={`primary ${errors.drivingIssueDate && touched.drivingIssueDate ? "error" : "" }`}
														isClearable
														portalId="root-portal"
														selected={values.drivingIssueDate ? parseISO(values.drivingIssueDate) : ""} 
														onChange={(date, e) => handleDatePickerChange(setFieldValue, "drivingIssueDate", date, e)}
														showYearDropdown
														placeholderText="MM/DD/YYYY"
														dateFormat="MM/dd/yyyy"
													/>
												</td>
												<td width='25%'>
													<Label title="Driver's License Expiry Date" />
													<DatePicker
														className={`primary ${errors.drivingExpiryDate && touched.drivingExpiryDate ? "error" : "" }`}
														isClearable
														portalId="root-portal"
														selected={values.drivingExpiryDate ? parseISO(values.drivingExpiryDate) : ""} 
														onChange={(date, e) => handleDatePickerChange(setFieldValue, "drivingExpiryDate", date, e)}
														showYearDropdown
														placeholderText="MM/DD/YYYY"
														dateFormat="MM/dd/yyyy"
													/>
												</td>

											</tr>
											<tr>
												<td width='25%'>
													<Label title='Certificate' required={true} />
													<PaginatedDropdown
														datakey="records"
														url={`${API_URL}/api/1.0.0/reference/certificates?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"id",
																(o) => `${o.name}`
															)
														}
														error={errors.certificate && touched.certificate}
														placeHolder='Search'
														selectedValue={values.certificate}
														onChange={(v) => {
																setFieldValue("certificate", v); 
															}
														}
													/>  
												</td>
												<td width='25%'>
													<Label title='License Certificate #' />
													<Field
														type="text"
														placeholder='Enter License Certificate #'
														name='certificateNumber'
														className={`col-1-1 primary ${errors.certificateNumber &&
															touched.certificateNumber
															? "error"
															: ""
															}`}
													/>
												</td>
												<td width='25%'>
													<Label title='Certificate Issue Date' />
													<DatePicker
														className={`primary ${errors.licenseIssueDate && touched.licenseIssueDate ? "error" : "" }`}
														isClearable
														portalId="root-portal"
														selected={values.licenseIssueDate ? parseISO(values.licenseIssueDate) : ""}
														onChange={(date, e) => handleDatePickerChange(setFieldValue, "licenseIssueDate", date, e)}
														showYearDropdown
														placeholderText="MM/DD/YYYY"
														dateFormat="MM/dd/yyyy"
													/>
												</td>
												<td width='25%'>
													<Label title='Certificate Expiry Date' />
													<DatePicker
														className={`primary ${errors.licenseExpiryDate && touched.licenseExpiryDate ? "error" : "" }`}
														isClearable
														portalId="root-portal"
														selected={values.licenseExpiryDate ? parseISO(values.licenseExpiryDate) : ""}
														onChange={(date, e) => handleDatePickerChange(setFieldValue, "licenseExpiryDate", date, e)}
														showYearDropdown
														placeholderText="MM/DD/YYYY"
														dateFormat="MM/dd/yyyy"
													/>
												</td>
											</tr>
											<tr>
												<td width='25%'>
													<Label title='Region' required={true} />
													<PaginatedDropdown
														datakey="regionList"
														url={`${API_URL}/api/1.0.0/region?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"id",
																(o) => `${o.name}`
															)
														}
														error={errors.region && touched.region}
														placeHolder='Search'
														selectedValue={values.region}
														onChange={(v) => {
																setFieldValue("region", v); 
															}
														}
													/> 
												</td>
												<td width='25%'>
													<Label title='Start Date' required={true}/>
													<DatePicker
														className={`primary ${errors.joiningDate && touched.joiningDate ? "error" : "" }`}
														isClearable
														portalId="root-portal"
														selected={values.joiningDate ? parseISO(values.joiningDate) : ""}
														onChange={(date, e) => handleDatePickerChange(setFieldValue, "joiningDate", date, e)}
														showYearDropdown
														placeholderText="MM/DD/YYYY"
														dateFormat="MM/dd/yyyy"
													/>
												</td>
												<td width='25%'>
													<Label title='Termination Date' />
													<DatePicker
														className={`primary ${errors.terminationDate && touched.terminationDate ? "error" : "" }`}
														isClearable
														portalId="root-portal"
														selected={values.terminationDate ? parseISO(values.terminationDate) : ""}
														onChange={(date, e) => handleDatePickerChange(setFieldValue, "terminationDate", date, e)}
														showYearDropdown
														placeholderText="MM/DD/YYYY"
														dateFormat="MM/dd/yyyy"
													/>
												</td>
											</tr> 
											<br />
										</tbody>
									</table>)
								},
								{
									name: "Facilities",
									slug: "facilities",
									content: (
										<NestedFacilityForm
											onAdd={(newData) => {
												setFieldValue('facilities_detail', [
													...values.facilities_detail,
													newData,
												]);
											}}
											list={values?.facilities_detail}
											onRemove={(index) => {
												const updatedData = values?.facilities_detail.filter((_, i) => i !== index);
												setFieldValue("facilities_detail", updatedData);
											}}
										/>)
								},
								{
									name: "Vaccinations",
									slug: "vaccinations",
									content: (
										<NestedVaccinationForm
											onAdd={(newData) => {
												setFieldValue('vaccinations_detail', [
													...values.vaccinations_detail,
													newData,
												]);
											}}
											list={values?.vaccinations_detail}
											onRemove={(index) => {
												const updatedData = values?.vaccinations_detail.filter((_, i) => i !== index);
												setFieldValue("vaccinations_detail", updatedData);
											}}
										/>)
								},
								{
									name: "Background Checks",
									slug: "background-checks",
									content: (
										<NestedBackgroundCheckForm
											onAdd={(newData) => {
												setFieldValue('background_checks_detail', [
													...values.background_checks_detail,
													newData,
												]);
											}}
											list={values?.background_checks_detail}
											onRemove={(index) => {
												const updatedData = values?.background_checks_detail.filter((_, i) => i !== index);
												setFieldValue("background_checks_detail", updatedData);
											}}
										/>)
								},
								{
									name: "PTO",
									slug: "pto",
									content: (
										<NestedPTOForm onAdd={(newData) => {
											setFieldValue('pto_detail', [
												...values.pto_detail,
												newData,
											]);
										}}
											list={values?.pto_detail}
											onRemove={(index) => {
												const updatedData = values?.pto_detail.filter((_, i) => i !== index);
												setFieldValue("pto_detail", updatedData);
											}}
										/>)
								},
								{
									name: "Education",
									slug: "degrees",
									content: (
										<NestedDegreeForm
											onAdd={(newData) => {
												setFieldValue('degrees_detail', [
													...values.degrees_detail,
													newData,
												]);
											}}
											list={values?.degrees_detail}
											onRemove={(index) => {
												const updatedData = values?.degrees_detail.filter((_, i) => i !== index);
												setFieldValue("degrees_detail", updatedData);
											}}
										/>)
								},
							].map((o) => ({
								title: o.name,
								id: o.slug,
								content: o.content,
							}))}
							active={tab}
							setActive={(v) => {
								updateQueryParam("tab", v);
							}}
						/>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonLightTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => history.push("/surgical_assistance")}
							icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
						/>
						{tab === 'basic-information' ? (
							<ButtonTextIcon
								type='button'
								disabled={loading}
								onClick={async () => {
									const errors = await validateForm();
									if (!errors || Object.keys(errors).length === 0) {
										updateQueryParam("tab", "facilities")
									} else {
										handleSubmit();
									}
								}}
								title='Next'
								className='mar-l-a'
								icon={
									loading ? (
										<LoaderIcon />
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
												transform='translate(-2.014 -1.995)'
											/>
										</svg>
									)
								}
							/>
						) : tab === 'facilities' ? (
							<ButtonTextIcon
								type='button'
								disabled={loading}
								onClick={() => {
									updateQueryParam("tab", "vaccinations")
								}}
								title='Next'
								className='mar-l-a'
								icon={
									loading ? (
										<LoaderIcon />
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
												transform='translate(-2.014 -1.995)'
											/>
										</svg>
									)
								}
							/>
						) : tab === 'vaccinations' ? (
							<ButtonTextIcon
								type='button'
								disabled={loading}
								onClick={() => {
									updateQueryParam("tab", "background-checks")
								}}
								title='Next'
								className='mar-l-a'
								icon={
									loading ? (
										<LoaderIcon />
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
												transform='translate(-2.014 -1.995)'
											/>
										</svg>
									)
								}
							/>
						) : tab === 'background-checks' ? (
							<ButtonTextIcon
								type='button'
								disabled={loading}
								onClick={() => {
									updateQueryParam("tab", "pto")
								}}
								title='Next'
								className='mar-l-a'
								icon={
									loading ? (
										<LoaderIcon />
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
												transform='translate(-2.014 -1.995)'
											/>
										</svg>
									)
								}
							/>
						) : tab === 'pto' ? (
							<ButtonTextIcon
								type='button'
								disabled={loading}
								onClick={() => {
									updateQueryParam("tab", "degrees")
								}}
								title='Next'
								className='mar-l-a'
								icon={
									loading ? (
										<LoaderIcon />
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
												transform='translate(-2.014 -1.995)'
											/>
										</svg>
									)
								}
							/>
						) : tab === 'degrees' ? (
							<ButtonTextIcon
								type='button'
								disabled={loading}
								title='Create Now'
								onClick={async () => {
									const errors = await validateForm(); 
									if (Object.keys(errors).length > 0) {
										const firstErrorMessage = Object.values(errors)[0];
										addToastMessage({
											status: false,
											message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
										});
										updateQueryParam("tab", "basic-information");
									}
									handleSubmit(); 
								}}
								className='mar-l-a'
								icon={
									loading ? (
										<LoaderIcon />
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
												transform='translate(-2.014 -1.995)'
											/>
										</svg>
									)
								}
							/>
						) : (
							<ButtonTextIcon
								type='button'
								disabled={loading}
								onClick={async () => {
									const errors = await validateForm();
									if (!errors || Object.keys(errors).length === 0) {
										updateQueryParam("tab", "facilities")
									} else {
										handleSubmit();
									}
								}}
								title='Next'
								className='mar-l-a'
								icon={
									loading ? (
										<LoaderIcon />
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.996'
											viewBox='0 0 19.995 19.996'>
											<path
												d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
												transform='translate(-2.014 -1.995)'
											/>
										</svg>
									)
								}
							/>
						)}
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};

const NestedBackgroundCheckForm = ({ onAdd, list, onRemove }) => {
	const fileUploadRef = useRef();
	const validationSchema = Yup.object().shape({
		date_performed: Yup.date().required('Date performed is required').typeError('Date performed must be a valid date'),
		notes: Yup.string().required('Notes is required').typeError('Notes must be a valid date'),
		fee_paid: Yup.number().typeError('Fee paid must be a valid number').required('Fee paid is required'),
		
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	return (
		<Formik
			initialValues={{
				fee_paid: '',
				date_performed: '',
				notes: '',
				attachments: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm }) => {
				onAdd(values);
				resetForm();
				handleReset();
			}}
		>
			{({ errors, touched, setFieldValue, values, submitForm }) => (
				<Form>
					<table>
						<tbody>
							<br />
							<tr>
								<td width='50%'>
									<Label title='Date Complete' required={true} />
									<DatePicker
											className={`primary ${errors.date_performed && touched.date_performed ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date_performed ? parseISO(values.date_performed) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date_performed", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
								<td width='50%'>
									<Label title='Fees Paid' required={true} />
									<Field
										type='number'
										placeholder='Fees Paid'
										name='fee_paid'
										className={`col-1-1 primary ${errors.fee_paid &&
											touched.fee_paid
											? "error"
											: ""
											}`}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan="2">

									<Label title='Notes' required={true} />
									<Field
										as='textarea'
										placeholder='Enter Notes'
										name='notes'
										className={`col-1-1 primary ${errors.notes &&
											touched.notes
											? "error"
											: ""
											}`}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<div
										className='flex center-left'
										style={{ gap: "8px" }}>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<Label title='Attachment'   />
										</div>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												style={{
													height: "12px",
													width: "12px",
													marginBottom: "5px",
												}}>
												<path
													d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
													fill='#5dc6b3'
												/>
											</svg>
										</div>
									</div>
									<DragFileUpload
										ref={fileUploadRef}
										fileType={"BACKGROUND_CHECK_DOC"}
										error={errors.attachments && touched.attachments}
										afterUpload={(url) => {
											setFieldValue("attachments", url);
										}}
									/>
								</td>
							</tr>
							<tr>

								<td colSpan="3">
									<div className="flex center-right">
										<div>
											<Label title="&nbsp;" />
											<ButtonTextIcon
												title="Add Background Check"
												type="button"
												onClick={submitForm}
												icon={<svg
													fill="#000000"
													version="1.1"
													width="800px"
													height="800px"
													viewBox="0 0 400 400"
													xmlSpace="preserve"
												>
													<g>
														<g>
															<path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
															<path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
														</g>
													</g>
												</svg>}
											/>
										</div>
									</div>
								</td>
							</tr>
							{list && (
								<tr>
									<td colSpan="4" >
										<div className="inner-table-date">
											<table>
												<thead>
													<tr>
														<th>Date Complete</th>
														<th>Notes</th>
														<th>Fees Paid</th>
														<th>Attachments</th>
														<th style={{ textAlign: 'center' }}>Action</th>
													</tr>
												</thead>
												<tbody>
													{list.map((contact, index) => (
														<tr>
															<td>{contact.date_performed? formatServerDate(contact.date_performed) : "-"}</td>
															<td>{contact.notes}</td>
															<td>{contact.fee_paid}</td>
															<td>{contact?.attachments.split('/').pop() || "-"}</td>
															<td>
																<div className="flex center-center">
																	<span className="cursor-pointer"
																		onClick={() => {
																			onRemove(index);
																		}}
																	>
																		<svg
																			height="15px"
																			width="15px"
																			viewBox="0 0 330 330"
																			fill="red"
																		>
																			<g id="XMLID_6_">
																				<g id="XMLID_11_">
																					<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																				</g>
																				<g id="XMLID_18_">
																					<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																				</g>
																				<g id="XMLID_23_">
																					<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																				</g>
																			</g>
																		</svg>
																	</span>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Form>
			)}
		</Formik>
	);
};

const NestedDegreeForm = ({ onAdd, list, onRemove }) => {
	const fileUploadRef = useRef();
	const validationSchema = Yup.object().shape({ 
		state: Yup.mixed()
		.nullable()
		.test("state-required", "Required",
			function (value) { return value !== null && value.value; }),
		degree: Yup.string().required('Degree is required').typeError('Degree must be a valid string'),
		date_aquired: Yup.date().required('Date aquired is required').typeError('Date aquired must be a valid date'),
		// degree_status: Yup.string().required('Degree status is required').typeError('Degree status must be a valid string'),
		nameOfSchool: Yup.string().required('Name of School is required'), 
		
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	

	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	const labelStateFunc = (o) => `${o.name}`;

	return (
		<Formik
			initialValues={{
				nameOfSchool:"",
				degree: '',
				date_aquired: '',
				date_expiry: '',
				state: null,
				attachments: '',
				degree_status : '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm }) => {
				onAdd(values);
				resetForm();
				handleReset();
			}}
		>
			{({ errors, touched, setFieldValue, values, submitForm }) => (
				<Form>
					<table>
						<tbody>
							<br />
							<tr>
								<td width='33%'>
									<Label title='Name Of School' required={true} />
									<Field
										type='text'
										placeholder='Enter Name of School'
										name='nameOfSchool'
										className={`col-1-1 primary ${errors.nameOfSchool &&
											touched.nameOfSchool
											? "error"
											: ""
											}`}
									/>
								</td>
								<td width='33%'>
									<Label title='Degree' required={true} />
									<Field
                                        as='select'
                                        name='degree'
                                        className={`col-1-1 primary ${errors.degree &&
                                            touched.degree
                                            ? "error"
                                            : ""
                                            }`}>
                                        <option value=''>Select Degree</option>
                                        {DEGREE_OPTIONS.map((d, i) => (
                                            <option value={d.value} key={i}>
                                                {d.label}
                                            </option>
                                        ))}
                                    </Field>
								</td>
								<td width='33%'>
									<Label title='Date Graduated ' required={true} />
									<DatePicker
											className={`primary ${errors.date_aquired && touched.date_aquired ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date_aquired ? parseISO(values.date_aquired) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date_aquired", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
								</tr>
							<tr>
							<td width='33%'>
									<Label title='Date Expired - If Applicable'  />
									<DatePicker
											className={`primary ${errors.date_expiry && touched.date_expiry ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date_expiry ? parseISO(values.date_expiry) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date_expiry", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
							<td width='33%'>
									<Label title='State' required={true} />
									<PaginatedDropdown
														datakey="records"
														url={`${API_URL}/api/1.0.0/reference/states/US?`}
														mapOptions={(records) =>
															mapOptions(
																records,
																"code",
																labelStateFunc
															)
														}
														error={errors.state && touched.state}
														placeHolder='Search'
														selectedValue={values.state}
														onChange={(v) => {
																setFieldValue("state", v); 
															}
														}
													/> 
								</td>
							<td width='33%'>
									<Label title='Degree Status' />
									<Field
                                        as='select'
                                        name='degree_status'
                                        className={`col-1-1 primary ${errors.degree_status &&
                                            touched.degree_status
                                            ? "error"
                                            : ""
                                            }`}>
                                        <option value=''>Select Degree status</option>
                                        {DEGREE_STATUS_OPTIONS.map((d, i) => (
                                            <option value={d.value} key={i}>
                                                {d.label}
                                            </option>
                                        ))}
                                    </Field>
									</td>
								</tr>
							<tr>
								<td colSpan='3'>
									<div
										className='flex center-left'
										style={{ gap: "8px" }}>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<Label title='Attachment'  />
										</div>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												style={{
													height: "12px",
													width: "12px",
													marginBottom: "5px",
												}}>
												<path
													d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
													fill='#5dc6b3'
												/>
											</svg>
										</div>
									</div>
									<DragFileUpload
										ref={fileUploadRef}
										fileType={"DEGREE_DOC"}
										error={errors.attachments && touched.attachments}
										afterUpload={(url) => {
											setFieldValue("attachments", url);
										}}
									/>
								</td>
							</tr>
							<tr>

								<td colSpan="3">
									<div className="flex center-right">
										<div> 
											<Label title="&nbsp;" />
											<ButtonTextIcon
												title="Add Degree"
												type="button"
												onClick={submitForm}
												icon={<svg
													fill="#000000"
													version="1.1"
													width="800px"
													height="800px"
													viewBox="0 0 400 400"
													xmlSpace="preserve"
												>
													<g>
														<g>
															<path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
															<path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
														</g>
													</g>
												</svg>}
											/>
										</div>
									</div>
								</td>
							</tr>
							{list && (
								<tr>
									<td colSpan="4" >
										<div className="inner-table-date">
											<table>
												<thead>
													<tr>				
														<th>Name Of School</th>
														<th>Degree</th>
														<th>Date Graduated</th>
														<th>Date Expired</th>
														<th>State</th>
														<th>Status</th>
														<th>Attachments</th>
														<th style={{ textAlign: 'center' }}>Action</th>
													</tr>
												</thead>
												<tbody>
													{list.map((contact, index) => (
														<tr> 
															<td>{contact.nameOfSchool}</td>
															<td>{contact.degree}</td>
															<td>{contact.date_aquired? formatServerDate(contact.date_aquired) : "-"}</td>
															<td>{contact.date_expiry? formatServerDate(contact.date_expiry) : "-"}</td>
															<td>{contact.state.label}</td>
															<td>{contact.degree_status}</td>
															<td>{contact?.attachments.split('/').pop() || "-"}</td>
															<td>
																<div className="flex center-center">
																	<span className="cursor-pointer"
																		onClick={() => {
																			onRemove(index);
																		}}
																	>
																		<svg
																			height="15px"
																			width="15px"
																			viewBox="0 0 330 330"
																			fill="red"
																		>
																			<g id="XMLID_6_">
																				<g id="XMLID_11_">
																					<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																				</g>
																				<g id="XMLID_18_">
																					<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																				</g>
																				<g id="XMLID_23_">
																					<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																				</g>
																			</g>
																		</svg>
																	</span>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Form>
			)}
		</Formik>
	);
};

const NestedPTOForm = ({ onAdd, list, onRemove }) => {
	const fileUploadRef = useRef();
	const validationSchema = Yup.object().shape({
		date: Yup.date().required('Date is required').typeError('Date must be a valid date'),
		type: Yup.string().required('Type is required'),
		amount: Yup.number().typeError('Amount must be a valid number').required('Amount is required'),
		
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	const labelVaccinationFunc = (o) => `${o.facilityName}`;


	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	return (
		<Formik
			initialValues={{
				date: "",
				type: '',
				amount: '',
				attachments: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm }) => {
				onAdd(values);
				resetForm();
				handleReset();
			}}
		>
			{({ errors, touched, setFieldValue, values, submitForm }) => (
				<Form>
					<table>
						<tbody>
							<br />
							<tr>
								<td width='33%'>
									<Label title='Date' required={true} />
										<DatePicker
											className={`primary ${errors.date && touched.date ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date ? parseISO(values.date) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
								<td width='33%'>
									<Label title='Type' required={true} />
									<Field
										type='text'
										placeholder='Enter Type'
										name='type'
										className={`col-1-1 primary ${errors.type &&
											touched.type
											? "error"
											: ""
											}`}
									/>
								</td>
								<td width='33%'>
									<Label title='Amount' required={true} />
									<Field
										type='number'
										placeholder='Enter Amount'
										name='amount'
										className={`col-1-1 primary ${errors.amount &&
											touched.amount
											? "error"
											: ""
											}`}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan='3'>
									<div
										className='flex center-left'
										style={{ gap: "8px" }}>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<Label title='Attachment'   />
										</div>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												style={{
													height: "12px",
													width: "12px",
													marginBottom: "5px",
												}}>
												<path
													d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
													fill='#5dc6b3'
												/>
											</svg>
										</div>
									</div>
									<DragFileUpload
										ref={fileUploadRef}
										fileType={"PTO_DOC"}
										error={errors.attachments && touched.attachments}
										afterUpload={(url) => {
											setFieldValue("attachments", url);
										}}
									/>
								</td>
							</tr>
							<tr>

								<td colSpan="3">
									<div className="flex center-right">
										<div>
											<Label title="&nbsp;" />
											<ButtonTextIcon
												title="Add PTO"
												type="button"
												onClick={submitForm}
												icon={<svg
													fill="#000000"
													version="1.1"
													width="800px"
													height="800px"
													viewBox="0 0 400 400"
													xmlSpace="preserve"
												>
													<g>
														<g>
															<path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
															<path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
														</g>
													</g>
												</svg>}
											/>
										</div>
									</div>
								</td>
							</tr>
							{list && (
								<tr>
									<td colSpan="4" >
										<div className="inner-table-date">
											<table>
												<thead>
													<tr>
														<th>Date</th>
														<th>Type</th>
														<th>Amount</th>
														<th>Attachments</th>
														<th style={{ textAlign: 'center' }}>Action</th>
													</tr>
												</thead>
												<tbody>
													{list.map((contact, index) => (
														<tr>
															<td>{contact.date? formatServerDate(contact.date) : "-"}</td>
															<td>{contact.type}</td>
															<td>{contact.amount}</td>
															<td>{contact?.attachments.split('/').pop() || "-"}</td>
															<td>
																<div className="flex center-center">
																	<span className="cursor-pointer"
																		onClick={() => {
																			onRemove(index);
																		}}
																	>
																		<svg
																			height="15px"
																			width="15px"
																			viewBox="0 0 330 330"
																			fill="red"
																		>
																			<g id="XMLID_6_">
																				<g id="XMLID_11_">
																					<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																				</g>
																				<g id="XMLID_18_">
																					<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																				</g>
																				<g id="XMLID_23_">
																					<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																				</g>
																			</g>
																		</svg>
																	</span>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Form>
			)}
		</Formik>
	);
};

const NestedVaccinationForm = ({ onAdd, list, onRemove }) => {
	const fileUploadRef = useRef();
	const validationSchema = Yup.object().shape({
		vaccine: Yup.mixed().nullable().required("Vaccine is required"),
		//   facility: Yup.mixed()
		//   .nullable()
		//   .test(
		//     "facility-required",
		//     "Facility is required",
		//     function (value) {
		//       return value !== null && value.value;
		//     }
		//   ),
		date_administer: Yup.date().required('Date administer is required').typeError('Date administer must be a valid date'),
		date_expiry: Yup.date().required('Date expiry is required').typeError('Date expiry must be a valid date'),
		
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	const labelVaccinationFunc = (o) => `${o.name}`;


	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	return (
		<Formik
			initialValues={{
				vaccine: null,
				date_administer: '',
				date_expiry: '',
				attachments: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm }) => {
				onAdd(values);
				resetForm();
				handleReset();
			}}
		>
			{({ errors, touched, setFieldValue, values, submitForm }) => (
				<Form>
					<table>
						<tbody>
							<br />
							<tr>
								<td width='33%'>
									<Label title='Vaccination' required={true} />
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/reference/vaccinations?`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelVaccinationFunc
											)
										}
										error={errors.vaccine &&
											touched.vaccine}
										placeHolder='Search Vaccination'
										selectedValue={values.vaccine}
										onChange={(v) =>
											setFieldValue("vaccine", v)
										}
									/>
								</td>
								<td width='33%'>
									<Label title='Date Administered ' required={true} />
									<DatePicker
											className={`primary ${errors.date_administer && touched.date_administer ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date_administer ? parseISO(values.date_administer) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date_administer", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
								<td width='33%'>
									<Label title='Expiration Date' required={true} />
									<DatePicker
											className={`primary ${errors.date_expiry && touched.date_expiry ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date_expiry ? parseISO(values.date_expiry) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date_expiry", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
							</tr>
							<tr>
								<td colSpan='3'>
									<div
										className='flex center-left'
										style={{ gap: "8px" }}>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<Label title='Attachment'   />
										</div>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												style={{
													height: "12px",
													width: "12px",
													marginBottom: "5px",
												}}>
												<path
													d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
													fill='#5dc6b3'
												/>
											</svg>
										</div>
									</div>
									<DragFileUpload
										ref={fileUploadRef}
										fileType={"VACCINATION_DOC"}
										error={errors.attachments && touched.attachments}
										afterUpload={(url) => {
											setFieldValue("attachments", url);
										}}
									/>
								</td>
							</tr>
							<tr>

								<td colSpan="3">
									<div className="flex center-right">
										<div>
											<Label title="&nbsp;" />
											<ButtonTextIcon
												title="Add Vaccination"
												type="button"
												onClick={submitForm}
												icon={<svg
													fill="#000000"
													version="1.1"
													width="800px"
													height="800px"
													viewBox="0 0 400 400"
													xmlSpace="preserve"
												>
													<g>
														<g>
															<path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
															<path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
														</g>
													</g>
												</svg>}
											/>
										</div>
									</div>
								</td>
							</tr>
							{list && (
								<tr>
									<td colSpan="4" >
										<div className="inner-table-date">
											<table>
												<thead>
													<tr>
														<th>Vaccine</th>
														<th>Date Administered</th>
														<th>Expiration Date</th>
														<th>Attachments</th>
														<th style={{ textAlign: 'center' }}>Action</th>
													</tr>
												</thead>
												<tbody>
													{list.map((contact, index) => (
														<tr>
															<td>{contact.vaccine.label}</td>
															<td>{contact.date_administer? formatServerDate(contact.date_administer) : "-"}</td>
															<td>{contact.date_expiry? formatServerDate(contact.date_expiry) : "-"}</td>
															<td>{contact?.attachments.split('/').pop() || "-"}</td>
															<td>
																<div className="flex center-center">
																	<span className="cursor-pointer"
																		onClick={() => {
																			onRemove(index);
																		}}
																	>
																		<svg
																			height="15px"
																			width="15px"
																			viewBox="0 0 330 330"
																			fill="red"
																		>
																			<g id="XMLID_6_">
																				<g id="XMLID_11_">
																					<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																				</g>
																				<g id="XMLID_18_">
																					<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																				</g>
																				<g id="XMLID_23_">
																					<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																				</g>
																			</g>
																		</svg>
																	</span>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Form>
			)}
		</Formik>
	);
};

const NestedFacilityForm = ({ onAdd, list, onRemove }) => {
	const fileUploadRef = useRef();
	const validationSchema = Yup.object().shape({ 
		facility: Yup.mixed()
			.nullable()
			.test("surgeon-required", "Required",
				function (value) { return value !== null && value.value; }),
		date_renewed: Yup.date().required('Date renewed is required').typeError('Date renewed must be a valid date'),
		date_expiry: Yup.date().required('Date expiry is required').typeError('Date expiry must be a valid date'),
		fee_paid: Yup.number().typeError('Fee paid must be a valid number').required('Fee paid is required'),
		
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	const labelFacilityFunc = (o) => `${o.facilityName}`;


	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	return (
		<Formik
			initialValues={{
				facility: null,
				date_renewed: '',
				date_expiry: '',
				fee_paid: '',
				attachments: '',
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { resetForm }) => {
				onAdd(values);
				resetForm();
				handleReset();
			}}
		>
			{({ errors, touched, setFieldValue, values, submitForm }) => (
				<Form>
					<table>
						<tbody> 
							<br />
							<tr>
								<td>
									<Label title='Facility' required={true} />
									<PaginatedDropdown
										url={`${API_URL}/api/1.0.0/reference/facility?`}
										mapOptions={(records) =>
											mapOptions(
												records,
												"id",
												labelFacilityFunc
											)
										}
										error={errors.facility && touched.facility}
										placeHolder='Search Facility'
										selectedValue={values.facility}
										onChange={(v) =>
											setFieldValue("facility", v)
										}
									/>
								</td>
								<td width='25%'>
									<Label title='Renewal Date' required={true} />
									<DatePicker
											className={`primary ${errors.date_renewed && touched.date_renewed ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date_renewed ? parseISO(values.date_renewed) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date_renewed", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
								<td width='25%'>
									<Label title='Expiration Date' required={true} />
									<DatePicker
											className={`primary ${errors.date_expiry && touched.date_expiry ? "error" : "" }`}
											isClearable
											portalId="root-portal"
											selected={values.date_expiry ? parseISO(values.date_expiry) : ""}
											onChange={(date, e) => handleDatePickerChange(setFieldValue, "date_expiry", date, e)}
											showYearDropdown
											placeholderText="MM/DD/YYYY"
											dateFormat="MM/dd/yyyy"
										/>
								</td>
								<td width='25%'>
									<Label title='Fees Paid' required={true} />
									<Field
										type="number"
										placeholder='Enter Fees Paid'
										name='fee_paid'
										className={`col-1-1 primary ${errors.fee_paid &&
											touched.fee_paid
											? "error"
											: ""
											}`}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan='4'>
									<div
										className='flex center-left'
										style={{ gap: "8px" }}>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<Label title='Attachment'   />
										</div>
										<div
											className='opl-tooltip'
											data-tooltip='Please only select PDF files for upload.'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												style={{
													height: "12px",
													width: "12px",
													marginBottom: "5px",
												}}>
												<path
													d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
													fill='#5dc6b3'
												/>
											</svg>
										</div>
									</div>
									<DragFileUpload
										ref={fileUploadRef}
										fileType={"FACILITY_DOC"}
										error={errors.attachments && touched.attachments}
										afterUpload={(url) => {
											setFieldValue("attachments", url);
										}}
									/>
								</td>
							</tr>
							<tr>

								<td colSpan="4">
									<div className="flex center-right">
										<div>
											<Label title="&nbsp;" />
											<ButtonTextIcon
												title="Add Facility"
												type="button"
												onClick={submitForm}
												icon={<svg
													fill="#000000"
													version="1.1"
													width="800px"
													height="800px"
													viewBox="0 0 400 400"
													xmlSpace="preserve"
												>
													<g>
														<g>
															<path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
															<path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
														</g>
													</g>
												</svg>}
											/>
										</div>
									</div>
								</td>
							</tr>
							{list && (
								<tr>
									<td colSpan="4" >
										<div className="inner-table-date">
											<table>
												<thead>
													<tr>
														<th>Facility</th>
														<th>Renewal Date</th>
														<th>Expiration Date</th>
														<th>Fees Paid</th>
														<th>Attachments</th>
														<th style={{ textAlign: 'center' }}>Action</th>
													</tr>
												</thead>
												<tbody>
													{list.map((contact, index) => (
														<tr>
															<td>{contact.facility.label}</td>
															<td>{contact.date_renewed ? formatServerDate(contact.date_renewed) : "-"}</td>
															<td>{contact.date_expiry? formatServerDate(contact.date_expiry) : "-"}</td>
															<td>{contact.fee_paid}</td>
															<td>{contact?.attachments.split('/').pop() || "-"}</td>
															<td>
																<div className="flex center-center">
																	<span className="cursor-pointer"
																		onClick={() => {
																			onRemove(index);
																		}}
																	>
																		<svg
																			height="15px"
																			width="15px"
																			viewBox="0 0 330 330"
																			fill="red"
																		>
																			<g id="XMLID_6_">
																				<g id="XMLID_11_">
																					<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																				</g>
																				<g id="XMLID_18_">
																					<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																				</g>
																				<g id="XMLID_23_">
																					<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																				</g>
																			</g>
																		</svg>
																	</span>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Form>
			)}
		</Formik>
	);
};

export default withRouter(AddForm);
