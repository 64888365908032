const ButtonTertiary = ({ actionType, title, ...rest }) => {
	const getRespectiveType = () => {
		switch (actionType) {
			case "view":
				return "tertiary-view";
			case "edit":
				return "tertiary-edit";
			case "delete":
				return "tertiary-delete";

			default:
				return "";
		}
	};

	const getIcon = () => {
		switch (actionType) {
			case "view":
				return (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='22'
						viewBox='0 0 24 22'>
						<path
							d='M21,13V23H0V4H12V6H2V21H19V13ZM24,1H13.012l4.035,4L10.07,12.07,12.9,14.9l6.977-7.07L24,12Z'
							transform='translate(0 -1)'
						/>
					</svg>
				);
			case "edit":
				return (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='18.007'
						viewBox='0 0 20 18.007'>
						<path
							d='M11.25,6a.772.772,0,0,1,.75.75.75.75,0,0,1-.75.75H3.5v12h17V10.75a.75.75,0,0,1,1.5,0V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V7A1,1,0,0,1,3,6ZM9.239,12.526C8.194,15.529,8,15.976,8,16.366a.627.627,0,0,0,.627.626,29.084,29.084,0,0,0,3.829-1.249Zm.888-.889,3.22,3.22,8.408-8.4a.837.837,0,0,0,0-1.183c-.58-.578-1.458-1.457-2.039-2.036a.837.837,0,0,0-1.183,0Z'
							transform='translate(-2 -2.993)'
						/>
					</svg>
				);
			case "delete":
				return (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='18'
						height='20'
						viewBox='0 0 18 20'>
						<path
							d='M20.015,6.506h-16V20.929a1.038,1.038,0,0,0,1,1.071h14a1.038,1.038,0,0,0,1-1.071V6.506ZM14.265,9a.75.75,0,0,1,.75.75v8.5a.75.75,0,0,1-1.5,0V9.75A.75.75,0,0,1,14.265,9Zm-4.5,0a.75.75,0,0,1,.75.75v8.5a.75.75,0,0,1-1.5,0V9.75A.75.75,0,0,1,9.765,9Zm-.75-5V3a1.029,1.029,0,0,1,1-1h4a1.029,1.029,0,0,1,1,1V4h5.254a.747.747,0,0,1,0,1.494H3.762A.747.747,0,1,1,3.762,4Zm4.5,0V3.5h-3V4Z'
							transform='translate(-3.015 -2)'
						/>
					</svg>
				);
			default:
				return <></>;
		}
	};

	return (
		<button className={`tertiary ${getRespectiveType()}`} {...rest}>
			<span>
				{getIcon()}
				<span>{title}</span>
			</span>
		</button>
	);
};

export default ButtonTertiary;
