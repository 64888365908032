import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ButtonTextIcon from '../../components/common/ui/ButtonTextIcon';
import LoaderIcon from '../../components/common/ui/LoaderIcon';
import PaginatedDropdown from '../../components/common/ui/PaginatedDropdown';
import { API_URL, SYSTEM_TOKEN } from '../../opl-utilities/constants';
import { useUI } from '../../context/ui';
const validationSchema = Yup.object({
    
    category: Yup.mixed()
			.nullable()
			.test("category-required", "Required",
				function (value) { return value !== null && value.value; }),
    name: Yup.string().required('Sub Category is required')
});

function AddForm({ history }) {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const { addToastMessage }=useUI();

    const Label = ({ title, required }) => (
        <p className='color-primary f-w-600' style={{ paddingBottom: '5px' }}>
            {title}
            {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        </p>
    );

    const createSubCategory = async (values) => {
        setLoading(true); 
        try {
            const resp = await fetch(API_URL+'/api/1.0.0/knowledge-base-subcategory', {
                method: 'POST',
                headers: {
                    'X-JWT-Assertion':  localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: values.name,
                    knowledgeBaseCategoryId: values.category.value
                })
            });

            if (resp) {
                const response = await resp.json();
                if (response) {
                    return response.result.responseCode === 200 ? true : false;
                } else {
                    const response = await resp.json();
                    if (response) {
                        setStatus(`${response.result.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${response.result.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus("Something went wrong, please try again later.");
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            } else {
                const response = await resp.json();
                if (response) {
                    setStatus(`${response.result.responseDescription || "Something went wrong, please try again later."}`);
                    addToastMessage({
                        status: false,
                        message: `${response.result.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }
        } catch (e) {
            console.log("Error:", e);
            setStatus("Something went wrong, please try again later.");
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
        } 
    };

    return (
        <Formik
            initialValues={{
                category: null, 
                name: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => { 
                resetForm();
                const api =  await createSubCategory(values);  
                if (api) {
                    resetForm();
                    setStatus(
                        "Your knowledge base sub category has been successfully created. Thank you."
                    );
                    addToastMessage({
						status: true,
						message: `Your knowledge base sub category has been successfully created. Thank you.`,
					})
                    history.push("/knowledge-base-sub-categories");
                }
                setLoading(false);
            }}
        >
            {({ handleSubmit, values, errors, touched, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Label title="Category" required />
                                        <PaginatedDropdown
                                            searchkey='name'
											url={`${API_URL}/api/1.0.0/knowledge-base-category?`}
                                            mapOptions={(records) =>
                                                records.map((record) => ({
                                                    value: record["id"],
                                                    label: record.name,
                                                    obj: record,
                                                }))
                                            }
                                            error={errors.category && touched.category}
											selectedValue={values.category}
											placeHolder='Search'
                                            datakey = "knowledgeBaseCategoryList"
											onChange={(v) => {  
													setFieldValue(
														"category",
														v
													); 

											}}
										/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="Sub Category" required />
                                        <Field
                                            name="name"
                                            type="text"
                                            placeholder="Eg: Scanflow"
                                            className={`primary ${errors.name && touched.name ? 'error' : ''}`}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push('/knowledge-base-sub-categories')}
                            icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Create Now'
                            className='mar-l-a'
                            icon={loading ? <LoaderIcon /> : (
                                <svg xmlns='http://www.w3.org/2000/svg' width='19.995' height='19.996' viewBox='0 0 19.995 19.996'>
                                    <path d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z' transform='translate(-2.014 -1.995)' />
                                </svg>
                            )}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default AddForm;