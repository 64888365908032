import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { decodeModuleAccessFromToken } from "../opl-utilities/helpers";
import NotAuthorized from "../pages/NotAuthorized";

const ApplicationLayout = ({ history, module, children, checkAccess = ["isReadAllowed"] }) => {
  useEffect(() => {
    const permissions = decodeModuleAccessFromToken(module);
 
    if (!permissions) {
      history.push("/login");
    }
  }, [module, history]);

  const permissions = decodeModuleAccessFromToken(module);

  const isAccessAllowed = checkAccess.every(accessKey => permissions?.[accessKey]);
  
  if (isAccessAllowed) {
	  return <>{children}</>;
  }

  return <NotAuthorized />;
};

export default withRouter(ApplicationLayout);