import { useState, forwardRef, useImperativeHandle  } from "react";
import LoaderIcon from "./LoaderIcon";
import { API_URL, SYSTEM_TOKEN } from "../../../opl-utilities/constants";

const DragFileUpload = forwardRef(({ afterUpload, fileType, error = false }, ref) => {
	const [loading, setLoading] = useState(false);
	const [uploadedError, setUploadedError] = useState(null);
	const [f, setF] = useState("");
	const [uploaded, setUploaded] = useState(false);

	const resetFile = () => {
		setF("");
		setUploaded(false);
		setLoading(false);
		afterUpload("");
	};

	useImperativeHandle(ref, () => ({
		resetFile,
	}));

	return (
		<>
			<div className={`opl-file-drag-upload flex center-center pos-rel cursor-pointer ${(error || uploadedError) ? "error-border" : ""}`} >
				<input
					type='file'
					onChange={async (e) => {
						
						setUploadedError(null); 
						const acceptedFileTypes = [
							// Images
							'image/jpeg', 'image/jpg', 'image/png', 'image/webp', 
							// PDF
							'application/pdf'
						]; 

						if (e.target.files.length > 0) {
							setF(e.target.files[0].name);
							setLoading(true);

							const file = e.target.files[0];
							if (!acceptedFileTypes.includes(file.type)) {
								setUploadedError("Invalid file type. Please upload a PNG, JPEG, JPG, WEBP, or PDF file.");
								setLoading(false);
								return;
							}

							try {
								const formdata = new FormData();
								formdata.append("file", e.target.files[0]);
								formdata.append("type", fileType);
								const resp = await fetch(
									API_URL + `/api/1.0.0/uploadFile`,
									{
										method: "POST",
										headers: {
											"X-JWT-Assertion": localStorage.getItem("token"),
										},
										body: formdata,
										redirect: "follow",
									}
								);
								if (resp.ok) {
									const response = await resp.json();
									if (response.result.responseCode === 200) {
										const uploadedURL = response.data;
										afterUpload(uploadedURL);
										setUploaded(true);
									}
								} else {
									const response = await resp.json(); 								  
									setUploadedError(response?.result?.responseDescription || "File upload failed. Please try again."); 

								}
							} catch (e) { 
								setUploadedError("File upload failed. Please try again.");
							} finally {
								setLoading(false);
							}
						}
					}}
				/>
				<div className='t-a-c'>
					<div style={{ marginBottom: "10px" }}>
						{loading ? (
							<LoaderIcon
								style={{
									height: "24px",
									width: "24px",
								}}
							/>
						) : (
							<>
									{uploaded ? (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19.995'
											height='19.995'
											viewBox='0 0 19.995 19.995'>
											<path
												fill='#27ae60'
												d='M12,2.005A10,10,0,1,1,2,12a10,10,0,0,1,10-10ZM6.949,12.391l3.851,3.43a.75.75,0,0,0,1.051-.052L17.8,9.26a.745.745,0,0,0-.552-1.249.751.751,0,0,0-.554.243l-5.453,5.962-3.3-2.938a.747.747,0,1,0-1,1.113Z'
												transform='translate(-2 -2.005)'
											/>
										</svg>
									) : (
										(
											uploadedError ?
													(<svg
														clipRule="evenodd"
														fillRule="evenodd"
														strokeLinejoin="round"
														strokeMiterlimit="2"
														width='19.995'
														height='19.995'
														viewBox="0 0 24 24"
														fill="red"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
															fillRule="nonzero"
														/>
													</svg>)
												: (<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='18'
													viewBox='0 0 24 18'>
													<path
														d='M11.492,10.172l-2.5,3.064-.737-.677L11.992,8l3.753,4.585-.753.665-2.5-3.076V18h-1V10.172ZM18.5,20H5.5a4.5,4.5,0,0,1-.8-8.924l.779-.14.043-.789a6.488,6.488,0,0,1,12.958,0l.044.789.78.14A4.5,4.5,0,0,1,18.5,20m.978-9.908a7.489,7.489,0,0,0-14.957,0A5.5,5.5,0,0,0,5.5,21h13a5.5,5.5,0,0,0,.978-10.908'
														transform='translate(0 -3)'
														fillRule='evenodd'
														fill='#283f54'
													/>
												</svg>)
										)
									)}
							</>
						)}
					</div>
					<div>
						{loading ? (
							<p style={{ color: "#8f8f8f" }} className='small l-h-1'>
								Uploading...
								<br />
								{`${f}`}
							</p>
						) : (
							<>
								{uploaded ? ( 
										<p
											style={{ color: "#27ae60" }}
											className='small l-h-1'>
											Your file has been successfully uploaded.
										</p>  
									) : (
										uploadedError ? (<p
											style={{ color: "red" }}
											className='small l-h-1'>
											{uploadedError}
										</p>) : (
											<p
												style={{ color: "#8f8f8f" }}
												className='small l-h-1'>
												Drag files here or click to select files.
											</p>)
									)}
							</>
						)}
					</div>
				</div>
			</div>
			{uploaded &&
				<div
				className="flex"
					style={{
						marginTop:"10px",
						alignItems: "center",
						justifyContent: "space-between",
						backgroundColor: "#f5f5f5", // Example muted background color
						padding: "5px",
						borderRadius: "8px",
					}}
				>
					<span 
						className='small l-h-1 color-primary'
					>
						&nbsp; {f}
					</span>
					<button
						onClick={resetFile}
						style={{
							display: "flex",
							alignItems: "center",
							color: "#d9534f",  
							backgroundColor: "transparent",
							border: "none",
							cursor: "pointer",
							height:"25px"
						}}
						className='small l-h-1'
						onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "rgba(217, 83, 79, 0.1)")}
						onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							style={{ width: "16px", height: "16px", marginRight: "8px" }}
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="lucide lucide-x w-4 h-4 mr-2"
							data-id="17"
						>
							<path d="M18 6 6 18"></path>
							<path d="m6 6 12 12"></path>
						</svg>
						Remove
					</button>
				</div>
			}
		</>
	);
});

export default DragFileUpload;