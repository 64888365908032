
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { withRouter } from "react-router-dom";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import CustomModal from "../../components/common/CustomModal";
import ConfirmationModal from "../../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken } from "../../opl-utilities/helpers";
import { useEffect, useRef, useState } from "react";

const Label = ({ title }) => {
    return (
        <p
            className='color-primary f-w-300 l-h-1'
            style={{ fontSize: "12px", paddingBottom: "6px" }}>
            &nbsp;{title}
        </p>
    );
};
 

const PhysicianAssistantFilter = ({ callback, ids, history, location, resetFilters }) => {
    const isFirstRender = useRef(true);
    const queryParams = new URLSearchParams(location.search);
    const [searchValue, setSearchValue] = useState(queryParams.get("search") || "");

    useEffect(() => { 
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        } 
        const handler = setTimeout(() => {
          handleQueryParamChange("search", searchValue);
        }, 500);
    
        return () => {
          clearTimeout(handler);
        };
      }, [searchValue]);

    // Decode permissions for the "groups" module
 	const permissions = decodeModuleAccessFromToken("physicians_assistance");
	
     // Redirect to login if no permissions
     useEffect(() => {
         if (!permissions) {
           history.push("/login");
         }
     }, [permissions, history]);
     // ====== FILTER QUERY PARAMS

    const handleQueryParamChange = (key, value) => {
        queryParams.set("pageNo", 0);
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };

    return (
        <div className='col-1-1 flex center-left filters'>
            <div>
                <Label title='Name' />
                <input className="primary" type="text" value={searchValue} placeholder="Eg: John Doe" onChange={(e) => setSearchValue(e.target.value)} />
            </div>
            <div>
                <Label title='Status'    />
                <select className="primary"   value={queryParams.get("status") || ""} onChange={(v) => { handleQueryParamChange("status", v.target.value) }} >
                    <option value="" >All</option>
                    <option value="1" >Active</option>
                    <option value="0" >In-Active</option>
                </select>
            </div>
            <div className='flex center-left' style={{ gap: "12px" }}>
                <div>
                    <Label title='Reset' />
                    <ButtonLightTextIcon
                        title='Reset'
                        className='light'
                        icon={
                            <svg width='800px' height='800px' viewBox='0 0 512 512'>
                                <path d='M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z' />
                            </svg>
                        }
                        onClick={() => {
                            setSearchValue("");
                            resetFilters();
                        }}
                    />
                </div>
                <div className='mar-l-a'>
                    <Label title='Per page' />
                    <ResultsPerPage />
                </div>
            </div>
            {/* <div>
                <Label title='&nbsp;' />
                <ButtonTextIcon disabled={!ids.length} title="Move to Surgical Assistant" style={{ width:"100%"}} />
            </div> */}
            {permissions?.isUpdateAllowed &&
                <div> 
                    <Label title='&nbsp;' />
                    <CustomModal
                        selector={ <ButtonTextIcon disabled={!ids.length} title="Active" type="button" style={{ width: "100%" }} />}
                        content={
                            <ConfirmationModal
                                onConfirm={async () => { await callback("1"); return true; }}
                                message={`Are you sure you want to activate ${ids.length} physician assistants?`}
                            />
                        }
                    />  
                </div>
            }
            {permissions?.isUpdateAllowed &&
                <div>
                    <Label title='&nbsp;' />
                    <CustomModal
                        selector={ <ButtonTextIcon disabled={!ids.length} title="In-Active" type="button" style={{ width: "100%" }} />}
                        content={
                            <ConfirmationModal
                                onConfirm={async () => { await callback("0"); return true; }}
                                message={`Are you sure you want to deactivate ${ids.length} physician assistants?`} 
                            />
                        }
                    /> 
                </div>
            }
        </div>
    );
};

export default withRouter(PhysicianAssistantFilter);