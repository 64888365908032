import { useEffect, useRef, useState } from "react";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { withRouter } from "react-router-dom";

const Label = ({ title }) => {
    return (
        <p
            className='color-primary f-w-300 l-h-1'
            style={{ fontSize: "12px", paddingBottom: "6px" }}>
            &nbsp;{title}
        </p>
    );
};

const TableFilter = ({ history, location, resetFilters }) => {
    const isFirstRender = useRef(true);
    const isFirst2Render = useRef(true);
    const queryParams = new URLSearchParams(location.search);
    const [searchValue, setSearchValue] = useState(queryParams.get("search") || "");
    const [assistantSearchValue , setAssistantSearchValue] = useState(queryParams.get("assistantSearch")||"");
    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      } 
      const handler = setTimeout(() => {
        handleQueryParamChange("search", searchValue);
      }, 500);
  
      return () => {
        clearTimeout(handler);
      };
    }, [searchValue]);

    useEffect(() => {
      if (isFirst2Render.current) {
        isFirst2Render.current = false;
        return;
      } 
      const handler = setTimeout(() => {
        handleQueryParamChange("assistantSearch", assistantSearchValue);
      }, 500);
  
      return () => {
        clearTimeout(handler);
      };
    }, [assistantSearchValue]);
  
  const handleQueryParamChange = (key, value) => {
        queryParams.set("pageNo", 0);
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };

    return (
      <div className="col-1-1 flex center-left filters">
        <div>
          <Label title="Assistant Name" />
          <input
            className="primary"
            placeholder="Eg: John Doe"
            type="text"
            value={assistantSearchValue}
            onChange={(e) => setAssistantSearchValue(e.target.value)}
          />
        </div><div>
          <Label title="Surgeon Name" />
          <input
            className="primary"
            placeholder="Eg: Johnny Bravo"
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="flex center-left" style={{ gap: "12px" }}>
          <div>
            <Label title="Reset" />
            <ButtonLightTextIcon
              title="Reset"
              className="light"
              icon={
                <svg width="800px" height="800px" viewBox="0 0 512 512">
                  <path d="M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z" />
                </svg>
              }
              onClick={() => {
                setSearchValue("");
                setAssistantSearchValue("");
                resetFilters();
            }}
            />
          </div>
          <div className="mar-l-a">
            <Label title="Per page" />
            <ResultsPerPage />
          </div>
        </div>
      </div>
    );
};

export default withRouter(TableFilter);