
import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";  
import Pagination from "../components/common/Pagination";   
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken } from "../opl-utilities/helpers";
import SurgicalAssistantFilter from "./surgical-assistant/SurgicalAssistantFilter";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import SurgicalAssistantDetails from "./surgical-assistant/SurgicalAssistantDetails";
import PopUpModal from "../components/common/PopUpModal";

const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");

	return queryParams;
};
 
const SurgicalAssistantPage = ({ history, location, activePage }) => {
	const [ids, setIds] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState(null);
	const queryParams = new URLSearchParams(location.search);
	const pageSize = queryParams.get("pageSize") || 12;
	const pageNo = queryParams.get("pageNo") || 0; 
    const { addToastMessage } = useUI();
	const [viewAssistant, setViewAssistant] = useState("");

	const toggleNumberInArray = (number) => {
		setIds((prevArray) => {
		  const index = prevArray.indexOf(number); 
		  if (index === -1) { 
			return [...prevArray, number];
		  } else { 
			const newArray = [...prevArray];
			newArray.splice(index, 1);
			return newArray;
		  }
		});
	  };

	// Decode permissions for the "groups" module
 	const permissions = decodeModuleAccessFromToken("surgical_assistance");
	
	// Redirect to login if no permissions
	useEffect(() => {
		if (!permissions) {
		  history.push("/login");
		}
	}, [permissions, history]);
	// ====== FILTER QUERY PARAMS
	const search = queryParams.get("search") || "";
	const status = queryParams.get("status") || "";  

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("status", ""); 
		updateQueryParam("search", ""); 
		updateQueryParam("pageNo", 0);
	};



	const getListApi = async (s) => {
		setLoading(true);
		try { 
			const query = jsonToQueryParams({  
                search: search,  
                status: status,  
			});  
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/surgical-assistant/list?pageSize=${pageSize}&pageNo=${pageNo}&${query}`, 
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setData(response.data);
				} else {
					setData(null);
				}
			}
		} catch (e) {
			console.log("Error:", e);
		} finally {
			setLoading(false);
		}
	};

	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	const active = async (type) =>{
		try { 
			if(!ids.length){  
				addToastMessage({
				  status: false,
				  message: `Something went wrong while ${type} surgical assistants. Please try again.`,
			    });
			    return false; 
			}
			const response = await fetch(
				API_URL +
				`/api/1.0.0/surgical-assistant/status/${type}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
					body: JSON.stringify({
						"ids":ids
					  })
				}
			);  
			if (response.ok) {
				const res = await response.json();  
				console.log(res);
				if (res.result.responseCode === 200) {
				getListApi();
				setIds([]);
				addToastMessage({
					status: true,
					message: `Surgical assistance ${type=== "0" ? "Deactivated" :type === "1" ? "Activated" : "NA"} successfully.`,
				});
				return true;
			   }else{
				addToastMessage({
					status: false,
					message: res?.responseDescription || "Invalid Request" ,
				});
				return false;
			   }
			}else{
				const res = await response.json();  
				addToastMessage({
					status: false,
					message: res?.responseDescription || "ERR" ,
				});
				return false;
			}   
		} catch (error) { 
			console.log(error);
			addToastMessage({
				status: false,
				message: `Something went wrong while  ${type=== "0" ? "Deactivated" :type === "1" ? "Activated" : "NA"} surgical assistants. Please try again.`,
			});
			return false;
		}
	} 

    const handleDelete = async (id) => {
		try { 
			if(!id){  
				addToastMessage({
				  status: false,
				  message: `Something went wrong while deleting surgical assistance. Please try again.`,
			    });
			    return false; 
			}
			const response = await fetch(
				API_URL +
				`/api/1.0.0/surgical-assistant/${id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
				}
			); 
			if (response.status === 200) {
				getListApi();
				addToastMessage({
					status: true,
					message: `Surgical assistance deleted successfully.`,
				});
				return true;
			}
			
			addToastMessage({
				status: false,
				message: `Something went wrong while deleting surgical assistance. Please try again.`,
			});

			return false;
		} catch (error) { 
			addToastMessage({
				status: false,
				message: `Something went wrong while deleting surgical assistance. Please try again.`,
			});
			return false;
		}
	};

	useEffect(() => {
		getListApi();
	}, [location.search]);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='physician-assistant'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
						Surgical Assistant
						</h5>
						{permissions?.isCreateAllowed &&
						<ButtonTextIcon
							title='Add New'
							className='mar-l-a'
							onClick={() => history.push("/add-assistant")}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19.995'
									height='19.995'
									viewBox='0 0 19.995 19.995'>
									<path
										d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
										transform='translate(-2.005 -2)'
									/>
								</svg>
							}
						/>
						}
						&nbsp;
					</div>
					<SurgicalAssistantFilter
						ids={ids}
						callback={(type)=>{active(type)}}       
						resetFilters={() => {
						resetFilters();
						}}
					/> 
                    <div>
                        {data && !loading && (
                            <div
                                className='flex center-left'
                                style={{
                                    padding: "10px 15px 10px 15px",
                                    gap: "12px",
                                }}>
                                <p
                                    className='f-w-300'
                                    style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
					{loading ? (
						<section
							style={{
								height: "100vh",
								paddingBottom: "20vh",
								backgroundColor: "#fafaff"
							}}
							className='flex center-center'>
							<LoaderIcon
								style={{ height: "32px", width: "32px" }}
							/>
						</section>
					) : data?.records.length ? (  
                                    <div className='rs-table-holder'>
                                        <table className='rs-table'>
                                            <thead>
                                                <tr>
												    <th>#</th> 
                                                    <th>ID</th> 		
                                                    <th>Name</th>
                                                    <th>NPI	</th>
                                                    <th>Taxonomy ID</th>
                                                    <th>Address</th>
                                                    <th>Phone</th>
                                                    <th>Status</th>
                                                    <th style={{ textAlign: 'center' }}>Action</th>
                                                </tr>
                                            </thead>
                                            {data && (
                                                <tbody>
                                                    {data?.records?.map((item, i) => ( 
                                                    <tr key={i}>
												    <td><input onClick={() => { toggleNumberInArray(item.id); }} className="primary" type="checkbox"  checked={ids.includes(item.id)} /></td>
                                                    <td >{pageNo * pageSize + i + 1}</td>
                                                    <td>{item.firstName + " " + item.lastName}</td>
                                                    <td>{item.npi}</td> 
                                                    <td>{item.primaryTaxonomy}</td>
                                                    <td>{item.homeAddress} - {item.homeCity} - {item.homeState}</td>
                                                    <td>{item.cellPhone}</td>
                                                    <td><p className="f-w-500">{item.statusId === 0 ? "In-Active" : item.statusId === 1 ? "Active" : "NA" }</p></td>
                                                    <td>
                                                        <div className="flex center-center" style={{ gap: '12px' }}>
														{permissions?.isReadAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">
																<ButtonTertiary
																				onClick={() => setViewAssistant(item?.id)}
																				actionType='view'
																				title='View'
																			/>
                                                                {/* <CustomModal
                                                                    selector={
                                                                        <ButtonTertiary
                                                                            actionType='view'
                                                                            title='View'
                                                                        />
                                                                    }
                                                                    content={
                                                                                <SurgicalAssistantDetails
                                                                                    id={item?.id}
                                                                                    history={history}
                                                                                />
                                                                    }
                                                                /> */}
                                                            </div>

                                                        )}
                                                            {permissions?.isUpdateAllowed && <div>
                                                                <NavLink
                                                                    to={{
                                                                        pathname: '/edit-assistant',
                                                                        state: { id: item.id }
                                                                    }}
                                                                >
                                                                    <ButtonTertiary
																	actionType='edit'
																	title='Edit'
																/>
                                                                </NavLink>
                                                            </div>
                                                            }

                                                            {permissions?.isDeleteAllowed &&
                                                                <div className="color-secondary transition hover-color-primary cursor-pointer " >
                                                                    <CustomModal
                                                                        selector={<ButtonTertiary
																			actionType='delete'
																			title='Delete'
																		/>}
                                                                        content={
                                                                            <ConfirmationModal
                                                                                onConfirm={async () => { await handleDelete(item.id); return true; }}
                                                                                message="Are you sure you want to delete this surgical assistant?"
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                                    {/* ))} */}
                                                    {/* {(!data || !data.profileResponseDTOS || data.profileResponseDTOS.length === 0) && !loading && (
                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: "center", padding: "10px" }} >
                                                                <p> No records found. </p>
                                                            </td>
                                                        </tr>
                                                    )} */}
                                                </tbody>
                                            )} 
                                        </table>
                                    </div> 
					): (
						<section
						  style={{
							height: "80vh",
							paddingBottom: "20vh",
							backgroundColor: "#fff",
						  }}
						  className="flex center-center"
						>
						  <p>No records found.</p>
						</section>
					)}
 
                    {data && !loading && (
                        <div
                            className='flex center-center'
                            style={{
                                padding: "10px 15px 10px 15px",
                                gap: "12px",
                            }}>
                            <p
                                className='f-w-300'
                                style={{ fontSize: "11px" }}>
                                Page no:
                            </p>
                            <Pagination
                                totalRecords={data.totalRecords}
                                pageNo={data.pageNo}
                                pageSize={data.pageSize}
                            />
                        </div>
                    )}
				</section>
			</section>
			{viewAssistant && (
				<PopUpModal>
					<SurgicalAssistantDetails
						setViewAssistant={setViewAssistant}
						id={viewAssistant}
						history={history}
					/>
				</PopUpModal>
			)}
		</section>
	);
};

export default withRouter(SurgicalAssistantPage);