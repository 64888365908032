import { useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import LoginErrorImg from "../static/login-error.svg";
import {
	ANDROID_APP_NAME,
	API_URL,
	IOS_APP_NAME,
	LARAVEL_API_URL,
	SYSTEM_TOKEN,
} from "../opl-utilities/constants";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";

const AzureCallback = ({ history, location }) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const validateApi = async (code, sessionState) => {
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/login/client/azure/validate`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify({
						code: code,
						redirectURL: window.location.origin + "/auth/callback",
						sessionState: sessionState,
						platform: "WEB",
					}),
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (
					response.result.responseCode === 200 &&
					response.data.email
				) {
					localStorage.setItem( "token", response.data.token );
					// Legacy code 
					const respLaravel = await fetch(LARAVEL_API_URL + `login`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							email: response.data.email,
							api_type: "react",
						}),
					});
					if (respLaravel.ok) {
						const responseLaravel = await respLaravel.json();
						if (responseLaravel && responseLaravel.success) {
							// Legacy
							localStorage.setItem(
								"user",
								responseLaravel.data.email
							);
							localStorage.setItem(
								"accessToken",
								responseLaravel.data.token
							);
							localStorage.setItem(
								"LOGGED_IN_USER",
								JSON.stringify(responseLaravel.data)
							);
							localStorage.setItem("LOGGED_IN_TIME", new Date());
							localStorage.removeItem("IMPERSONATE_EMAIL");
							history.push("/scheduling");
						} else {
							const responseLaravel = await respLaravel.json();
							setError(
								responseLaravel?.message ||
									"Oops, something went wrong."
							);
							setLoading(false);
						}
					} else {
						const responseLaravel = await respLaravel.json();
						setError(
							responseLaravel?.message ||
								"Oops, something went wrong."
						);
						setLoading(false);
					}
				} else {
					const response = await resp.json();
					setError(
						response?.responseDescription ||
							"Oops, something went wrong."
					);
					setLoading(false);
				}
			} else {
				const response = await resp.json();
				setError(
					response?.responseDescription ||
						"Oops, something went wrong."
				);
				setLoading(false);
			}
		} catch (e) {
			setError("Oops, something went wrong.");
			setLoading(false);
		}
	};

	useEffect(() => {
		const code = new URLSearchParams(location.search).get("code");
		const state = new URLSearchParams(location.search).get("state");
		const sessionState = new URLSearchParams(location.search).get(
			"session_state"
		);
		if (code && sessionState && state) {
			switch (state) {
				case "web":
					validateApi(code, sessionState);
					break;
				case "android":
					window.location.href =
						ANDROID_APP_NAME +
						`://app?code=${encodeURIComponent(
							code
						)}&session_state=${encodeURIComponent(sessionState)}`;
					break;
				case "ios":
					window.location.href =
						IOS_APP_NAME +
						`://app?code=${encodeURIComponent(
							code
						)}&session_state=${encodeURIComponent(sessionState)}`;

					break;

				default:
					setError("Oops, something went wrong.");
					setLoading(false);
					return;
			}
		} else {
			setError("Oops, something went wrong.");
			setLoading(false);
		}
	}, []);

	return (
		<section id='opl_coremax' style={{ height: "100vh" }}>
			<div
				className='flex center-center'
				style={{ backgroundColor: "white", height: "100vh" }}>
				{!error && loading && (
					<LoaderIcon
						style={{
							width: "32px",
							height: "32px",
						}}
					/>
				)}
				{!loading && error && (
					<div className='t-a-c'>
						<img
							src={LoginErrorImg}
							style={{
								maxWidth: "320px",
								height: "auto",
								margin: "0 auto",
								display: "block",
							}}
						/>
						<br />
						<p className='color-primary font-size-14 f-w-600'>
							We could not log you into CoreMax at the moment,
							please try again later.
						</p>
						<p className='color-primary font-size-12 f-w-300'>
							{error}
						</p>
						<br />
						<NavLink to='/login'>
							<ButtonTextIcon
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'>
										<path
											d='M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1ZM7.172,12.5l4.608,3.763L11.1,17,5,12l6.112-5,.666.753L7.174,11.5H19v1Z'
											fillRule='evenodd'
										/>
									</svg>
								}
								title='Back to Login'
							/>
						</NavLink>
					</div>
				)}
			</div>
		</section>
	);
};

export default withRouter(AzureCallback);
