import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL } from "../opl-utilities/constants";
import { withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ActivityCard from "./activities/ActivityCard";
import Pagination from "../components/common/Pagination";
import ActivityFilter from "./activities/ActivityFilter"; 
import { decodeModuleAccessFromToken } from "../opl-utilities/helpers";

const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");
	return queryParams;
};

const ActivityPage = ({ history, location, activePage }) => {
	const [controller, setController] = useState(null); 
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState(null);
	const queryParams = new URLSearchParams(location.search);
	const pageSize = queryParams.get("pageSize") || 12;
	const pageNo = queryParams.get("pageNo") || 0;
	const status = queryParams.get("status") || "incomplete";

	// Decode permissions for the "groups" module
	const permissions = decodeModuleAccessFromToken("activities");

	// Redirect to login if no permissions
	useEffect(() => {
		if (!permissions) {
			history.push("/login");
		}
	}, [permissions, history]);

	// ====== FILTER QUERY PARAMS

	const defaultFilterVal = queryParams.get("defaultFilterVal") || "";
	const startDate = queryParams.get("startDate") || "";
	const endDate = queryParams.get("endDate") || "";
	const company = queryParams.get("company") || "";
	const assistant = queryParams.get("assistant") || "";
	const facility = queryParams.get("facility") || null;
	const patient = queryParams.get("patient") || null;
	const createdBy = queryParams.get("createdBy") || null;
	const physician = queryParams.get("physician") || null;
	const statusFilter = queryParams.get("statusFilter") || "";
	const billoption = queryParams.get("billoption") || "";

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("defaultFilterVal", "")
		updateQueryParam("startDate", "");
		updateQueryParam("endDate", "");
		updateQueryParam("company", "");
		updateQueryParam("assistant", "");
		updateQueryParam("facility", "");
		updateQueryParam("patient", "");
		updateQueryParam("createdBy", "");
		updateQueryParam("physician", "");
		updateQueryParam("statusFilter", "");
		updateQueryParam("billoption", "");
		updateQueryParam("pageNo", 0);
	};

	const getList = async (s) => {
		controller?.abort();
		const newController = new AbortController();
        setController(newController);
        const { signal } = newController;
		setLoading(true);
		try {
			const query = jsonToQueryParams({
				fromDate:startDate,
				toDate:endDate,
				statusFilter,
				billoption,
				companyId: company ? JSON.parse(company)?.value : null,
				assistantId: assistant ? JSON.parse(assistant)?.value : null,
				facilityId: facility ? JSON.parse(facility)?.value : null,
				patientId: patient ? JSON.parse(patient)?.value : null,
				createdBy: createdBy ? JSON.parse(createdBy)?.value : null,
				physicianId: physician ? JSON.parse(physician)?.value : null,
			});
			console.log("Query", query);
			const getMyUserRole = () => {
				const _LOGGED_IN_USER = JSON.parse(
					localStorage.getItem("LOGGED_IN_USER")
				);
				return _LOGGED_IN_USER ? _LOGGED_IN_USER.type : "";
			};
			const getMyUserId = () => {
				const _LOGGED_IN_USER = JSON.parse(
					localStorage.getItem("LOGGED_IN_USER")
				);
				return _LOGGED_IN_USER ? _LOGGED_IN_USER.id : "";
			};
			const resp = await fetch(
				API_URL +
					`/api/1.0.0/activity/status/${s}?pageSize=${pageSize}&pageNo=${pageNo}&${query}&role=${getMyUserRole()}&userId=${getMyUserId()}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					signal,
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setData(response.data);
				}
			}
			setLoading(false);
		} catch (e) {
			if (e.name === "AbortError") {
                console.log("Previous request was aborted");
            }else{
				setLoading(false);
			}
			console.log("Error:", e);
		} finally {
		}
	};

	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	useEffect(() => {
		// if (!defaultFilterVal) {
		// 	updateQueryParam("defaultFilterVal", "set");
		// 	updateQueryParam("startDate", new Date().toISOString().split("T")[0]);
		// 	updateQueryParam("endDate", new Date().toISOString().split("T")[0]);
		// }
		getList(status);
		return () => {
            if (controller) {
                controller.abort();
            }
        };
	}, [location.search, status]);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='activity-v2'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
							Activities
						</h5>
						<ul className='tab-selector'>
							<li
								className={`transition cursor-pointer pos-rel ${
									status === "incomplete" ? "active-tab" : ""
								}`}
								onClick={() => {
									updateQueryParam("status", "incomplete"); 
								}}>
								Ongoing
							</li>
							<li
								className={`transition cursor-pointer pos-rel ${
									status === "complete" ? "active-tab" : ""
								}`}
								onClick={() => {
									updateQueryParam("status", "complete"); 
								}}>
								Completed
							</li>
						</ul>
						{permissions?.isCreateAllowed && (
						<ButtonTextIcon
							title='Add New'
							className='mar-l-a'
							onClick={() => history.push("/add-activity")}
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19.995'
									height='19.995'
									viewBox='0 0 19.995 19.995'>
									<path
										d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
										transform='translate(-2.005 -2)'
									/>
								</svg>
							}
						/>
						)}
					</div>
					<ActivityFilter
						resetFilters={() => {
							resetFilters();
						}}
					/>
					<div>
						{data && !loading && (
							<div
								className='flex center-left'
								style={{
									padding: "10px 15px 10px 15px",
									gap: "12px",
								}}>
								<p
									className='f-w-300'
									style={{ fontSize: "11px" }}>
									Page no:
								</p>
								<Pagination
									totalRecords={data.totalRecords}
									pageNo={data.pageNo}
									pageSize={data.pageSize}
								/>
							</div>
						)}
					</div>
					{loading ? (
						<section
							style={{
								height: "100vh",
								paddingBottom: "20vh",
								backgroundColor: "#fff",
							}}
							className='flex center-center'>
							<LoaderIcon
								style={{ height: "32px", width: "32px" }}
							/>
						</section>
					) : data?.records.length ? (
						<div className='activity-cards'>
							{data?.records?.map((data, i) => (
								<ActivityCard key={i} data={data} />
							))}
						</div>
					) : (
						<section
							style={{
								height: "80vh",
								paddingBottom: "20vh",
								backgroundColor: "#fff",
							}}
							className='flex center-center'>
							<p>No records found.</p>
						</section>
					)}
					<div>
						{data && !loading && (
							<div
								className='flex center-center'
								style={{
									padding: "24px 15px 32px 15px",
									gap: "12px",
								}}>
								<p
									className='f-w-300'
									style={{ fontSize: "11px" }}>
									Page no:
								</p>
								<Pagination
									totalRecords={data.totalRecords}
									pageNo={data.pageNo}
									pageSize={data.pageSize}
								/>
							</div>
						)}
					</div>
				</section>
			</section>
		</section>
	);
};
 
export default withRouter(ActivityPage);