import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import { LOCAL_STORAGE_KEYS } from "../javascript/constants/common";
import { formatServerDate, getUserTypeInfo } from "../opl-utilities/helpers";
import { useFetch } from "../hooks/useFetch";
import PopUpModal from "../components/common/PopUpModal";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import DragFileUpload from "../components/common/ui/DragFileUpload";
import { useUI } from "../context/ui";
const OverviewSection = lazy(() => import("./profile/OverviewSection"));

const Label = ({ title, required }) => (
	<p className='color-primary f-w-600' style={{ paddingBottom: "5px" }}>
		{title}
		{required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
	</p>
);

const SectionLoader = () => {
	return (
		<section className='flex center-center' style={{ height: "480px" }}>
			<LoaderIcon
				style={{ height: "32px", width: "32px", opacity: "0.2" }}
			/>
		</section>
	);
};

const ProfilePage = ({ history, location, activePage }) => {
	const [show, setShow] = useState(false);
	const { addToastMessage } = useUI();
	const userId = JSON.parse(localStorage.getItem("LOGGED_IN_USER"));
	const { data, error, loading } = useFetch(
		API_URL + "/api/1.0.0/profile/" + userId?.id,
		{ method: "GET" }
	);
	const fileUploadRef = useRef();
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	const handleReload = () => {
		window.location.reload();
	};

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='insurance'>
					<section id='profile-page'>
						<div className='cover-image b-r-10 default-image'>
							{/* <ButtonTextIcon
							onClick={() => setShow(true)}
							title={`${data.data.coverPictureURL
								? "Change Cover Photo"
								: "Add Cover Photo"
								}`}
							icon={<CiCamera />}
                        /> */}
						</div>
						<div className='user-info'>
							<div
								className='default-image user-picture b-r-100 pos-rel'
								style={{
									backgroundImage: data?.data?.image
										? `url(${data?.data?.image})`
										: null,
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}></div>
							<div className='t-a-l'>
								<div
									className='flex bottom-left'
									style={{ gap: "8px" }}>
									<h5 className='color-primary l-h-1'>
										{data?.data?.name}{" "}
										{data?.data?.lastName}
									</h5>
									<p
										className='color-light f-w-300'
										style={{ fontSize: "11px" }}>
										(
										{
											getUserTypeInfo(data?.data?.type)
												?.label
										}
										)
									</p>
								</div>
								<p className='color-light font-size-14'>
									Joined{" "}
									{data?.data?.createdAt
										? formatServerDate(data?.data.createdAt)
										: "--"}
								</p>
								<p className='color-light font-size-12'>
									{data?.data.email}
								</p>
							</div>
							<div className='mar-l-a'>
								<ButtonTextIcon
									onClick={() => setShow(true)}
									title='Edit Profile'
									icon={
										<svg
											width='20'
											height='14'
											style={{
												height: "16px",
												width: "16px",
											}}
											viewBox='0 0 24 24'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z'
												fill='#000000'
											/>
										</svg>
									}
								/>
							</div>
						</div>
						<div className='rs-tabs'>
							<ul className='tab-selector'>
								<li className='transition cursor-pointer pos-rel active-tab'>
									About
								</li>
							</ul>
							<div className='tab-content'>
								<div className='about-content'>
									<div className='left'>
										<br />
										<ul>
											<li
												className={`font-size-14 b-r-5 transition cursor-pointer pos-rel active-tab`}
												style={{
													backgroundColor: "#cfdbe5",
												}}>
												Overview
											</li>
										</ul>
									</div>
									<div className='right'>
										<Suspense fallback={<SectionLoader />}>
											<OverviewSection
												data={data?.data}
											/>
										</Suspense>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
			</section>
			{show && (
				<PopUpModal>
					<section id='opl_coremax'>
						<section
							style={{
								position: "fixed",
								inset: 0,
								zIndex: 9999,
								backgroundColor: "rgba(255, 255, 255, 0.86)",
							}}>
							<section
								className='col-1-1 flex center-center'
								style={{
									height: "100vh",
									width: "100vw",
								}}>
								<div
									style={{
										width: "486px",
										padding: "24px",
										height: "auto",
										maxHeight: "80vh",
										border: "solid 1px #f0f0f0",
										background: "white",
										borderRadius: "8px",
										margin: "0 auto",
										boxShadow:
											"0px 0px 20px rgba(0,0,0,0.08)",
									}}>
									<div
										className='col-1-1 flex center-left'
										style={{
											borderBottom: "solid 1px #f0f0f0",
											paddingBottom: "12px",
											marginBottom: "16px",
										}}>
										<h6
											className='color-primary f-w-300 opl-tooltip'
											data-tooltip='View entire history of the activity below.'>
											Update Profile
										</h6>
										<div
											className='mar-l-a cursor-pointer opl-tooltip'
											data-tooltip='Close to return to the previous section.'
											onClick={() => setShow(false)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'>
												<path
													d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
													fill='#283f54'
												/>
											</svg>
										</div>
									</div>
									<div>
										<section>
											<div style={{ marginLeft: "auto" }}>
												<Formik
													initialValues={{
														email:
															data?.data?.email ||
															"",
														firstName:
															data?.data?.name ||
															"",
														lastName:
															data?.data
																?.lastName ||
															"",
														file: "",
													}}
													validationSchema={Yup.object(
														{
															firstName:
																Yup.string().required(
																	"Name is required"
																),
															lastName:
																Yup.string().required(
																	"lastName is required"
																),
														}
													)}
													onSubmit={async (
														values,
														{ resetForm }
													) => {
														if (!userId) {
															addToastMessage({
																status: false,
																message: `Something went wrong, please try again later.`,
															});
															return;
														}

														try {
															const payloadJSON =
																{
																	firstName:
																		values.firstName,
																	lastName:
																		values.lastName,
																	email: values.email,
																	id: userId?.id,
																};

															if (values?.file) {
																payloadJSON[
																	"imageUrl"
																] = values.file;
															}

															const resp =
																await fetch(
																	API_URL +
																		`/api/1.0.0/profile`,
																	{
																		method: "PUt",
																		headers:
																			{
																				"Content-Type":
																					"application/json",
																				"X-JWT-Assertion":
																					localStorage.getItem(
																						"token"
																					),
																			},
																		body: JSON.stringify(
																			payloadJSON
																		),
																	}
																);

															if (resp.ok) {
																const response =
																	await resp.json();
																if (response) {
																	if (
																		response
																			.result
																			.responseCode ===
																		200
																	) {
																		addToastMessage(
																			{
																				status: true,
																				message: `You have successfully update profile details, thank you.`,
																			}
																		);
																		handleReset();
																		handleReload();
																		setShow(
																			false
																		);
																		return true;
																	} else {
																		addToastMessage(
																			{
																				status: false,
																				message: `Something went wrong, please try again later.`,
																			}
																		);
																		return false;
																	}
																}
															} else {
																const response =
																	await resp.json();
																if (response) {
																	addToastMessage(
																		{
																			status: false,
																			message: `${
																				response.responseDescription ||
																				"Something went wrong, please try again later."
																			}`,
																		}
																	);
																	return false;
																}
															}
														} catch (error) {
															console.log(error);
															addToastMessage({
																status: false,
																message: `Something went wrong, please try again later.`,
															});
														}
														//

														resetForm();
													}}>
													{({
														handleSubmit,
														setFieldValue,
														values,
														errors,
														touched,
													}) => (
														<Form
															onSubmit={
																handleSubmit
															}>
															<div className='col-1-1'>
																<table>
																	<tbody>
																		<tr>
																			<td
																				style={{
																					padding:
																						"10px",
																				}}>
																				<Label
																					title='First Name'
																					required
																				/>
																				<Field
																					name='firstName'
																					type='text'
																					placeholder='Enter  '
																					className={`primary ${
																						errors.firstName &&
																						touched.firstName
																							? "error"
																							: ""
																					}`}
																				/>
																			</td>
																			<td
																				style={{
																					padding:
																						"10px",
																				}}>
																				<Label
																					title='Last Name'
																					required
																				/>
																				<Field
																					name='lastName'
																					type='text'
																					placeholder='Enter  '
																					className={`primary ${
																						errors.lastName &&
																						touched.lastName
																							? "error"
																							: ""
																					}`}
																				/>
																			</td>
																		</tr>
																		<tr>
																			<td
																				colSpan='2'
																				style={{
																					padding:
																						"10px",
																				}}>
																				<Label
																					title='Profile Image'
																					required
																				/>
																				<DragFileUpload
																					ref={
																						fileUploadRef
																					}
																					fileType='PROFILE_PICTURE'
																					error={
																						errors.file &&
																						touched.file
																					}
																					afterUpload={(
																						url
																					) => {
																						if (
																							url
																						) {
																							setFieldValue(
																								"file",
																								url
																							);
																						} else {
																							setFieldValue(
																								"file",
																								""
																							);
																						}
																					}}
																				/>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan='2'>
																				{" "}
																				<hr />{" "}
																			</td>
																		</tr>
																		<tr>
																			<td colSpan='2'>
																				<div
																					className='col-1-1 flex center-left'
																					style={{
																						marginTop:
																							"16px",
																						borderTop:
																							"solid 1px #f0f0f0",
																						paddingTop:
																							"16px",
																					}}>
																					<ButtonLightTextIcon
																						title='Cancel'
																						type='button'
																						onClick={() =>
																							setShow(
																								false
																							)
																						}
																						icon={
																							<svg
																								clipRule="evenodd"
																								fillRule="evenodd"
																								strokeLinejoin="round"
																								strokeMiterlimit="2"
																								viewBox="0 0 24 24"
																								xmlns="http://www.w3.org/2000/svg"
																								width='19.995'
																								height='19.996'
																							>
																								<path
																									d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
																									fillRule="nonzero" 
																								/>
																							</svg>
																						}
																					/>
																					<div
																						style={{
																							marginLeft:
																								"auto",
																						}}>
																						<ButtonTextIcon
																							title='Update'
																							type='submit'
																							disabled={
																								loading
																							}
																							icon={
																								loading ? (
																									<LoaderIcon />
																								) : (
																									<svg
																										xmlns='http://www.w3.org/2000/svg'
																										width='24'
																										height='24'
																										viewBox='0 0 24 24'>
																										<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
																									</svg>
																								)
																							}
																						/>
																					</div>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
															<br />
														</Form>
													)}
												</Formik>
											</div>
										</section>
									</div>
								</div>
							</section>
						</section>
					</section>
				</PopUpModal>
			)}
		</section>
	);
};

export default withRouter(ProfilePage);
