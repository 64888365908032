import { useEffect, useState } from "react";
import FileViewer from "../../components/common/ui/FileViewer";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";

const ScanFlowImages = ({ p, files, handleAddMore, showAddbutton }) => {
	const [allData, setAllData] = useState(null);
	const [active, setActive] = useState("");
	const [subActive, setSubActive] = useState("");

	const groupedFiles = (files) => {
		const x = files.reduce((acc, file) => {
			if (!acc[file.fileType]) {
				acc[file.fileType] = [];
			}
			acc[file.fileType].push(file);
			return acc;
		}, {});
		return x;
	};

	useEffect(() => {
		if (files.length) {
			const d = groupedFiles(files);
			setAllData(d);
			setActive(Object.keys(d)[0]);
			setSubActive(d[Object.keys(d)[0]][0].fileS3Url);
		}
	}, [files]);

	useEffect(() => {
		if (allData) {
			const f = allData[active][0].fileS3Url;
			setSubActive(f);
		}
	}, [active]);

	const Selector = ({ f, ...rest }) => {
		return (
			<li
				onClick={() => {
					setActive(f);
				}}
				className='t-t-u l-s-n'
				style={{
					fontSize: "12px",
					fontWeight: 600,
					lineHeight: "48px",
					color: active === f ? "#8f8f8f" : "#283f54",
					padding: "0 24px",
					background: active === f ? "white" : "#F0F0F0",
					boxShadow:
						active === f
							? "none"
							: "inset 0 0 4px rgba(0,0,0,0.12)",
					cursor: "pointer",
				}}
				{...rest}>
				<span className='flex center-left'>
					<svg
						width='800px'
						height='800px'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						style={{
							height: "18px",
							width: "auto",
							marginRight: "4px",
						}}>
						<path
							d='M15 19L17 21L21 17M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H11.5M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V13.4M9 17H11.5M9 13H15M9 9H10'
							stroke={active === f ? "#8f8f8f" : "#283f54"}
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
					{f}
				</span>
			</li>
		);
	};

	const SubSelector = ({ a, index, ...rest }) => {
		return (
			<li
				className='l-s-n cursor-pointer'
				onClick={() => setSubActive(a.fileS3Url)}
				style={{
					position: "relative",
					fontSize: "10px",
					padding: "0 4px",
					fontWeight: 600,
					textTransform: "uppercase",
					color: subActive === a.fileS3Url ? "#8f8f8f" : "#283f54",
					lineHeight: "36px",
					whiteSpace: "nowrap",
				}}
				{...rest}>
				<span
					style={{
						position: "absolute",
						content: '""',
						left: 0,
						bottom: "-2px",
						height: "3px",
						width: "100%",
						display: subActive === a.fileS3Url ? "block" : "none",
						background: "#8f8f8f",
					}}></span>
				{p?.firstName || ""} {p?.lastName || ""} {a.fileType} ({index + 1})
			</li>
		);
	};

	return (
		<div className='col-1-1'>
			<div className=''>
				{allData && (
					<div>
						<div className='col-1-1 flex center-left'>
							<ul className='flex center-left'>
								{Object.keys(allData).map((f, i) => (
									<Selector f={f} key={f} />
								))}
							</ul>
							{showAddbutton &&
								<ButtonTextIcon
									icon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='469.865'
											height='469.481'
											viewBox='0 0 469.865 469.481'>
											<g transform='translate(-21.031 490.632)'>
												<path d='M251-490.1a31.73,31.73,0,0,0-5.5,2.4c-1.7.8-33,31.6-69.7,68.4C115-358.4,109.1-352.1,108-348c-4.3,16.3,8.9,30.5,25,27l5.5-1.2L186-369.6c26.1-26.1,47.9-47.4,48.3-47.4s.7,61.4.7,136.5c0,132.4.1,136.7,1.9,140.3,8.5,16.6,31.5,16,38.5-1,1.4-3.3,1.6-19,1.6-139.8,0-74.8.3-136,.7-136s22.2,21.3,48.3,47.4l47.5,47.4L379-321c16.1,3.5,29.3-10.7,25-27-1.1-4.1-7-10.4-67.8-71.3-37.6-37.7-68.3-67.7-70.4-68.8C261.4-490.4,254.6-491.3,251-490.1Z' />
												<path d='M36.8-233.8c-6.2,1.7-12.5,8.1-14.4,14.4-2.3,7.9-1.5,106.4.9,116.5,9.6,39.8,37.2,68.6,75.2,78.4,13.2,3.4,23.7,3.6,164.5,3.2,151.6-.4,142.1,0,159.1-5.8,27.9-9.4,52.3-33.5,62.2-61.3,5.9-16.9,5.9-16.8,6.4-72.6.5-58.3.5-59.3-5.8-66.4a20.762,20.762,0,0,0-33.6,3.2l-2.8,4.7-.5,54c-.6,52.3-.6,54.2-2.7,59.8C437.7-85.6,425-73.2,405-66.4c-5.3,1.8-11.4,1.9-149,1.9-138.1,0-143.7-.1-149-1.9C94.6-70.6,85-77.1,77.3-86.2c-4.7-5.6-10.8-18.7-12.2-26.2-.7-3.8-1.1-24.3-1.1-55.7v-49.7l-2.8-5.3A21.153,21.153,0,0,0,36.8-233.8Z' />
											</g>
										</svg>
									}
									style={{
										marginLeft: "auto",
										marginRight: "8px",
									}}
									type='button'
									title={"Upload Files"}
									onClick={() => handleAddMore()}
								/>
							}
						</div>
						<div
							className='col-1-1'
							style={{
								background: "white",
								padding: "0px 12px 12px 12px",
							}}>
							<ul
								className='col-1-1 flex'
								style={{
									gap: "24px",
									borderBottom: "solid 1px #cfcfcf",
									paddingTop: "8px",
									marginBottom: "12px",
								}}>
								{allData[active].map((a, x) => (
									<SubSelector key={x} index={x} a={a} />
								))}
							</ul>
							{subActive && (
								<>
								<div className='col-1-1'>
									<FileViewer fileUrl={subActive} />
								</div>
									<div className="col-1-1 flex center-right" style={{padding:"10px"}}>
										<ButtonTextIcon
											icon={<svg
												clipRule="evenodd"
												fillRule="evenodd"
												strokeLinejoin="round"
												strokeMiterlimit="2"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="m6.864 3.424c.502-.301 1.136.063 1.136.642 0 .264-.138.509-.365.644-2.476 1.486-4.135 4.197-4.135 7.292 0 4.691 3.808 8.498 8.498 8.498s8.497-3.807 8.497-8.498c0-3.093-1.656-5.803-4.131-7.289-.225-.136-.364-.38-.364-.644 0-.582.635-.943 1.137-.642 2.91 1.748 4.858 4.936 4.858 8.575 0 5.519-4.479 9.998-9.997 9.998s-9.998-4.479-9.998-9.998c0-3.641 1.951-6.83 4.864-8.578zm.831 8.582s2.025 2.021 3.779 3.774c.147.147.339.22.53.22.192 0 .384-.073.531-.22 1.753-1.752 3.779-3.775 3.779-3.775.145-.145.217-.336.217-.526 0-.192-.074-.384-.221-.531-.292-.293-.766-.294-1.056-.004l-2.5 2.499v-10.693c0-.414-.336-.75-.75-.75s-.75.336-.75.75v10.693l-2.498-2.498c-.289-.289-.762-.286-1.054.006-.147.147-.221.339-.222.531 0 .19.071.38.215.524z"
													fillRule="nonzero"
												/>
											</svg>}
											onClick={()=>{
												window.open(subActive, '_blank')
											}}
											title="Download"
										/>
									</div>
								</>
							)}
						</div>
					</div>
				)}
				{/*  */}
			</div>
		</div>
	);
};

export default ScanFlowImages;
