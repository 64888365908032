import {
	faCalendar,
	faClipboardList,
	faFile,
	faLevelUpAlt,
	faLocationArrow,
	faLockOpen,
	faStopwatch,
	faTasks,
	faUserCircle,
	faUserCog,
	faPeopleArrows,
	faMedkit,
	faFileInvoiceDollar,
	faSwatchbook,
	faComments,
} from "@fortawesome/free-solid-svg-icons";

export const RECORDS_PER_PAGE = 15;

//export const BASE_URL = 'https://coremaxsurgicalcloud.com/portal/api/';
//export const BASE_URL = 'https://xcitesurgical.com/portal/api/';
// export const BASE_URL = 'https://usp.coremaxcloud.com/portal/api/';
//export const BASE_URL = 'https://coremax-api.pointofit.us/api/';
//export const BASE_URL = 'http://dev.coremaxsurgical.com/portal/api/';

// Live

// export const BASE_URL = 'https://usp.coremaxcloud.com/portal/api/';

// Staging
// export const BASE_URL = 'https://staging.universalscloud.com/portal/api/';

// Developement
// export const BASE_URL = 'https://universalscloud.com/portal/api/';

//Local
// console.log(window.location.href);
//export const BASE_URL = 'http://localhost:8000/api/';

// Local Multi Tenant
//export const BASE_URL = 'http://newdomain.localhost:8000/';

export const EMAIL_REGEX =
	/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

export const LOCAL_STORAGE_KEYS = {
	USER: "user",
	ACCESS_TOKEN: "accessToken",
	LOGGED_IN_USER: "LOGGED_IN_USER",
	LOGGED_IN_TIME: "LOGGED_IN_TIME",
	IMPERSONATE_EMAIL: "IMPERSONATE_EMAIL",
	META_DATA: "META_DATA",
	OFFICE_365: "OFFICE_365",
	FILE: "FILE",
	TENANT_ID: "TENANT_NAME",
	TENANT_FOUND: "TENANT_FOUND",
	SCANFLOW_FILTERS: "SCANFLOW_FILTERS",
	PROCEDURE_FILTERS: "PROCEDURE_FILTERS",
	SURGEON_FILTERS: "SURGEON_FILTERS",
	PHYSICIAN_ASSISTANT_FILTERS: "PHYSICIAN_ASSISTANT_FILTERS",
	SURGICAL_ASSISTANT_FILTERS: "SURGICAL_ASSISTANT_FILTERS",
	FACILITY_FILTERS: "FACILITY_FILTERS",
	SUBSCRIBER_ID: "SUBSCRIBER_ID", //OPL ADDED
	COMPANY_DETAILS: "COMPANY_DETAILS", //OPL ADDED
};

export const APP_ROUTES = {
	LOGIN: "/login",
	REGISTER: "/register",
	LOGIN_MID: "/login-middleware",
	DASHBOARD: "/scheduling",
	TRANSACTIONS: "/transactions",
	SUBSCRIBERS: "/subscribers",
	SCAN_FLOW_V2: "/scan-flow-v2",
	NOTIFICATION: "/notification",
	FEEDBACK: "/feedback",
	ACTIVITY_DASHBOARD: "/activities",
	ADD_ACTIVITY: "/add-activity",
	EDIT_ACTIVITY: "/edit-activity",
	PATIENTS_DASHBOARD: "/patients",
	ADD_PATIENT: "/add-patient",
	EDIT_PATIENT: "/edit-patient",
	SURGEONS_DASHBOARD: "/surgeons",
	ADD_SURGEON: "/add-surgeon",
	EDIT_SURGEON: "/edit-surgeon",
	CONTRACTS_DASHBOARD: "/contracts",
	ADD_CONTRACT: "/add-contracts",
	EDIT_CONTRACT: "/edit-contracts",
	FACILITIES_DASHBOARD: "/facilities",
	ADD_FACILITY: "/add-facility",
	EDIT_FACILITY: "/edit-facility",
	USER_DASHBOARD: "/users",
	ADD_USER: "/add-user",
	EDIT_USER: "/edit-user",
	KNOWLEDGE_BASE_CATEGORIES: "/knowledge-base-categories",
	ADD_KNOWLEDGE_BASE_CATEGORIES: "/add-knowledge-base-categories",
	EDIT_KNOWLEDGE_BASE_CATEGORIES: "/edit-knowledge-base-categories",
	KNOWLEDGE_BASE_SUB_CATEGORIES: "/knowledge-base-sub-categories",
	ADD_KNOWLEDGE_BASE_SUB_CATEGORIES: "/add-knowledge-base-sub-categories",
	EDIT_KNOWLEDGE_BASE_SUB_CATEGORIES: "/edit-knowledge-base-sub-categories",
	KNOWLEDGE_BASE_ARTICLES: "/knowledge-base",
	ADD_KNOWLEDGE_BASE_ARTICLES: "/add-knowledge-base",
	EDIT_KNOWLEDGE_BASE_ARTICLES: "/edit-knowledge-base",
	SURGEON_ASSOCIATION: "/surgeon-association",
	ADD_SURGEON_ASSOCIATION: "/add-surgeon-association",
	ARCHIVED_USER_DASHBOARD: "/archived-users",
	ADD_ARCHIVED_USER: "/add-archived-user",
	EDIT_ARCHIVED_USER: "/edit-archived-user",
	NOT_AUTHORIZED: "/not-authorized",
	SCAN_FLOW_DASHBOARD: "/scan-flow",
	EDIT_SCAN_FLOW: "/edit-scan-flow",
	CASELOG_DASHBOARD: "/pac-data",
	ADD_CASELOG: "/add-pac-data",
	EDIT_CASELOG: "/edit-pac-data",
	Bill_DASHBOARD: "/bills",
	ADD_BILL: "/add-bills",
	EDIT_BILL: "/edit-bills",
	INSURANCE_DASHBOARD: "/insurance",
	ADD_INSURANCE: "/add-insurance",
	EDIT_INSURANCE: "/edit-insurance",
	CLAIMS_AND_COLLECTION: "/claims-and-collections",
	EDIT_CLAIM_AND_COLLECTION: "/edit-claims-and-collections",
	INVOICE_CLAIM_AND_COLLECTION: "/invoice-claims-and-collections",
	CLAIM_FORM: "/claim-form",
	PRACTICE_DASHBOARD: "/practices",
	ADD_PRACTICE: "/add-practice",
	EDIT_PRACTICE: "/edit-practice",
	REGIONS: "/regions",
	ADD_REGION: "/add-region",
	UPDATE_REGION: "/edit-region",
	SURGICAL_ASSISTANT: "/surgical_assistance",
	SURGICAL_ASSISTANT_DASHBOARD: "/surgical_assistance-dashboard",
	ADD_ASSISTANT: "/add-assistant",
	EDIT_ASSISTANT: "/edit-assistant",
	PHYSICIAN_ASSISTANT: "/physicians_assistance",
	PHYSICIAN_ASSISTANT_DASHBOARD: "/physicians_assistance-dashboard",
	ADD_PHYSICIAN_ASSISTANT: "/add-physician-assistant",
	EDIT_PHYSICIAN_ASSISTANT: "/edit-physician-assistant",
	PROCEDURE_DASHBOARD: "/procedures",
	ADD_PROCEDURE: "/add-procedure",
	EDIT_PROCEDURE: "/edit-procedure",
	BATCH_DASHBOARD: "/Batch",
	BATCH_ADD: "/add-batch",
	BATCH_UPDATE: "/edit-batch",
	PAYMENT_POSTING: "/payment-posting",
	UPDATE_PAYMENT_POSTING: "/update-payment-posting",
	FILE_DISPLAY: "/view-file",
	ACCOUNT_DASHBOARD: "/accounts",
	ACCOUNT_INVOICE_DETAIL: "/account-detail",
	ACCOUNT_INVOICE_UPDATE: "/account-invoice-update",
	ORGANIZATION_DASHBOARD: "/organizations",
	ADD_ORGANIZATION: "/add-organization",
	EDIT_ORGANIZATION: "/edit-organization",
	PROFILE: "/profile",
	INVOICING: "/invoicing",
	INVOICINGV1: "/invoicingv1",
	ADD_INVOICE: "/add-new-invoice",
	INVOICING_DASHBOARD: "/invoicing",
	EDIT_INVOICING: "/edit-invoicing",
	REVENUE_SCHEDULER_DASHBOARD: "/revenue-scheduler",
	LOGS: "/logs",
	CLIENTS_DASHBOARD: "/tenant-clients",
	ADD_CLIENT: "/add-client",
	EDIT_CLIENT: "/edit-client",
	GROUP_DASHBOARD: "/groups",
	GROUP_ADD: "/group-add",
	GROUP_EDIT: "/group-edit",
	GROUP_DELETE: "/group-delete",
	DIVISION: "/division",
	ADD_DIVISION: "/add-division",
};

export const MENU_LIST = [
	{
		module_name: "Scheduling",
		slug: "scheduling",
		icon: faCalendar,
		padding: "15px",
	},
	{
		module_name: "Activities",
		slug: "activities",
		icon: faTasks,
		padding: "15px",
	},
	{
		module_name: "Scan Flow",
		slug: "scan-flow",
		icon: faFile,
		padding: "20px",
	},
	{
		module_name: "Patients",
		slug: "patients",
		icon: faUserCircle,
		padding: "18px",
	},
	{
		module_name: "Procedures",
		slug: "procedures",
		icon: faMedkit,
		padding: "18px",
	},
	// {
	// 	"Revenue Cycle": [
	// 		{
	// 			module_name: "Claims and Collection",
	// 			slug: "claims-and-collections",
	// 		},
	// 		{ module_name: "Revenue Scheduler", slug: "revenue-scheduler" },
	// 		{ module_name: "TDI/IDR", slug: "tdi-idr" },
	// 		{ icon: faLevelUpAlt, padding: "24px" },
	// 	],
	// },
	// {
	// 	Accounts: [
	// 		{
	// 			module_name: "Bill",
	// 			slug: "bills",
	// 			icon: faFileInvoiceDollar,
	// 			padding: "15px",
	// 		},
	// 		{
	// 			module_name: "Invoicing",
	// 			slug: "invoicing",
	// 			icon: faFileInvoiceDollar,
	// 			padding: "15px",
	// 		},
	// 		{
	// 			module_name: "Invoicing V1",
	// 			slug: "invoicingv1",
	// 			icon: faFileInvoiceDollar,
	// 			padding: "15px",
	// 		},
	// 		{ module_name: "Contracts", slug: "contracts" },
	// 		{ icon: faLocationArrow, padding: "15px" },
	// 	],
	// },
	{
		Setup: [
			// {module_name:'Fee Structure', slug: 'fee_structure'},
			{ module_name: "Manage Groups", slug: "groups" },
			{ module_name: "Manage Users", slug: "users" },
			{ module_name: "Archived Users", slug: "archived-users" },
			{ module_name: "Manage Insurance", slug: "insurance" },
			{ module_name: "Manage Practice", slug: "practices" },
			{ icon: faUserCog, padding: "15px" },
		],
	},
	{
		Credentialing: [
			{
				module_name: "Physician Assistants",
				slug: "physicians_assistance",
			},
			{ module_name: "Surgical Assistants", slug: "surgical_assistance" },
			{ module_name: "Surgeons", slug: "surgeons" },
			{ module_name: "Surgeon Association", slug: "surgeon-association" },
			{ module_name: "Facilities", slug: "facilities" },
			// { module_name: "Division Management", slug: "division" },
			{ icon: faLockOpen, padding: "15px" },
		],
	},
	{
		"Knowledge Base": [
			{ module_name: "Categories", slug: "knowledge-base-categories" },
			{
				module_name: "Sub Categories",
				slug: "knowledge-base-sub-categories",
			},
			{ module_name: "Knowledge Base", slug: "knowledge-base" },
			{ icon: faSwatchbook, padding: "15px" },
		],
	},
	{
		"PBFUSA": [
			{ module_name: "Patient Registrations", slug: "patient-registrations" },
			{ module_name: "Volunteer Registrations", slug: "volunteer-registrations" },
			{ icon: faSwatchbook, padding: "15px" },
		],
	},
	{
		module_name: "Regions",
		slug: "regions",
		icon: faLocationArrow,
		padding: "19px",
	},
	// {
	// 	module_name: "Batches",
	// 	slug: "Batch",
	// 	icon: faStopwatch,
	// 	padding: "23px",
	// },
	// {
	// 	module_name: "Logs",
	// 	slug: "logs",
	// 	icon: faClipboardList,
	// 	padding: "24px",
	// },
	// {
	// 	module_name: "Clients",
	// 	slug: "tenant-clients",
	// 	icon: faPeopleArrows,
	// 	padding: "24px",
	// },
	// {
	// 	module_name: "Feedback",
	// 	slug: "feedback",
	// 	icon: faComments,
	// 	padding: "24px",
	// },
	// {
	// 	module_name: "Subscribers",
	// 	slug: "subscribers",
	// 	icon: faComments,
	// 	padding: "24px",
	// },
	// {
	// 	module_name: "Transactions",
	// 	slug: "transactions",
	// 	icon: faComments,
	// 	padding: "24px",
	// },
	// {
	// 	module_name: "Scan Flow V2",
	// 	slug: "scan-flow-v2",
	// 	icon: faComments,
	// 	padding: "24px",
	// },
];

export const SUB_MENU = {
	claims_and_collections: "Revenue Cycle",
	revenue_scheduler: "Revenue Cycle",
	fee_structure: "Setup",
	insurance: "Setup",
	users: "Setup",
	groups: "Setup",
	practices: "Setup",
	surgical_assistants: "Credentialing",
	facilities: "Credentialing",
	surgeons: "Credentialing",
	speciality: "Credentialing",
};

export const NOTIFICATION_TYPES = {
	SUCCESS: "success",
	INFO: "info",
	WARNING: "warning",
	ERROR: "error",
};

export const ACTIVITY_LISTING_COLUMNS = [
	"ID",
	"Assistant",
	"Patient/Ins",
	"Surgeon",
	"Facility",
	"Procedure",
	"Event Type",
	"Date/Time",
	"Created By",
	"Modified By",
	"Bill Generated",
];

export const INVOICING_V1_LISTING_COLUMNS = [
	"Invoice Date",
	"Invoice Number",
	"Bill IDs",
	"Payment Entities",
	"Total Amount",
	"Discount Amount",
	"Net Amount",
	"Received Amount",
	"Remaining Amount",
	"Invoice Start Date",
	"Invoice Due Date",
	"Activities",
	"Action",
];
export const DIVISION_LISTING_COLUMNS = [
	"ID",
	"Division Name",
	"Manager Name",
	"Manager Email",
	"Action",
];

export const INVOICING_LISTING_COLUMNS = [
	"Account#",
	"DOS",
	"Patient",
	"DOB",
	"Insurance",
	"Assistant",
	"Surgeon",
	"Facility",
	"Modified By",
	"Fee",
	"Status",
	"Action",
];
export const LOGS_LISTING_COLUMNS = [
	"ID",
	"Event",
	"Name",
	"Module",
	"Url",
	"Method",
	"Ip",
	"Agent",
	"User",
	"Created at",
];
export const PATIENT_LISTING_COLUMNS = [
	"",
	"Id",
	"Name",
	"DOB",
	"Age",
	"Gender",
	"Insurance",
];

export const SURGEON_LISTING_COLUMNS = [
	"",
	"ID",
	"Physician's Name",
	"License Number",
	"NPI",
	"Taxanomy",
	"Address",
	"Fax",
	"Status",
];

export const FACILITY_LISTING_COLUMNS = [
	"ID",
	"Contact",
	"Address",
	"Phone",
	"Fax",
	"NPI",
];

export const CONTRACT_LISTING_COLUMNS = [
	"ID",
	"Type",
	"Facility/Patient",
	"Contact Person Name",
	"Contact Person Phone",
	"Contact Person Email",
];

export const CLIENTS_LISTING_COLUMNS = [
	"Client Domain",
	"Client/Tenant Name",
	"Case Capacity",
	"Address",
	"Phone",
	"Fax",
	"NPI",
	"Status",
];
export const BILLING_LISTING_COLUMNS = [
	"Invoice",
	"Service Date",
	"Facesheet",
	"Report List",
	"Correspondance",
	"Patient Name",
	"Assistant",
	"Facility",
	"Assign Sorting",
	"Bill Status",
	"Insurance",
	"Practice",
	"Patient Chart No:",
	"Assign Reg",
	"Reg Status",
	"Assign Coder",
	"Coding Status",
	"Assign Charge",
	"Charge Status",
	"Assign Claim",
	"Claim Status",
	"Assistant Location",
	"Hosp Contract",
	"Codes",
	"Notes",
	"Created By",
	"Modified By",
	"Modified Date",
	"Account Number",
	"Created",
	"Invoice Count",
	"Admission Date",
	"Event Flags",
	"Diagnosis Codes",
];

export const REVENUESCHEDULER_LISTING_COLUMNS = [
	"Event ID",
	"Patient ID",
	"Patient Name",
	"Patient DOB",
	"Has Bill",
	"Relief ID",
	"Ready to Code",
	"Bill IDs",
	"Billing Notes",
	"Surgical Assistant Last Name",
	"Surgical Assistant First Name",
	"Surgical Assistant NPI",
	"Surgical Assistant License",
	"Surgeon ID",
	"Surgeon Name",
	"Surgeon NPI",
	"Surgeon Billing Notes",
	"Facility ID",
	"Facility Name",
	"Facility Short Name",
	"Facility EMR Access",
	"Region /Market ID",
	"Region Name",
	"Schedule Start Date/Time",
	"Schedule End Date/Time",
	"Schedule By",
	"Date/Time Created",
	"Primary Payer ID",
	"Primary Payer Name",
	"Secondary Payer Name",
	"Pre Auth Number",
	"Insurance Demo Entered",
	"Event Facesheet Uploaded",
	"Op report Uploaded",
	"Event Notes",
	"Schedule CPT code",
	"Diagnosis code",
	"Event Type",
];

export const GROUP_LISTING_COLUMNS = ["ID", "Group Name"];

export const USER_LISTING_COLUMNS = [
	"ID",
	"Name",
	"Email",
	"Type",
	"Active Start",
	"Active End",
	"Notes",
];

export const KNOWLEDGE_BASE_CATEGORY_LISTING_COLUMNS = ["ID", "Category Name"];

export const KNOWLEDGE_BASE_SUB_CATEGORY_LISTING_COLUMNS = [
	"ID",
	"Category Name",
	"Sub Category Name",
];

export const KNOWLEDGE_BASE_ARTICLE_LISTING_COLUMNS = [
	"ID",
	"Category Name",
	"Sub Category Name",
	"Name",
	"Description",
];

export const SURGEON_ASSOCIATION_LISTING_COLUMNS = [
	"ID",
	"Surgical Assistant Name",
	"Surgeon First Name",
	"Surgeon Last Name",
];

export const CASELOG_LISTING_COLUMNS = [
	"Patient Name",
	"DOB",
	"Gender",
	"Insurance",
	"Surgeon",
	"Facility",
	"DOS",
];

export const INSURANCE_LISTING_COLUMNS = [
	"ID",
	"Insurance Name",
	"Address",
	"Contact Details",
	"Electronic Payer ID",
	"Master Payer ID",
];

export const PRACTICE_LISTING_COLUMNS = [
	"NPI",
	"NAME",
	"NPI",
	"Taxonomy",
	"Tax ID/EIN Number",
	"Regions/Markets",
];

export const BILLINGS_LISTING_COLUMNS = [
	"Bill ID",
	"Event ID",
	"Missing Data",
	"Patient ID",
	"Patient DOB",
	"Name",
	"DOS",
	"Charge Amount",
	"Billing Notes",
	"Provider",
	"Bill CPT Codes",
	"Insurance",
	"Surgeon",
	"Facility",
	"Pay to Name",
];

export const CLAIMS_LISTING_COLUMNS = [
	"Bill ID",
	"Event ID",
	"Missing Data",
	"Patient ID",
	"Patient DOB",
	"Name",
	"DOS",
	"Charge Amount",
	"Billing Notes",
	"Claim Notes",
	"Provider",
	"Bill CPT Codes",
	"Insurance Claim",
	"Primary Insurance",
	"Secondary Insurance",
	"Surgeon",
	"Facility",
	"Pay to Name",
];

export const REGIONS = ["Region ID", "Region Name"];

export const ASSISTANTS = [
	"",
	"ID",
	"Name",
	"License Number",
	"NPI",
	"Taxonomy ID",
	"Address",
	"Phone",
	"Division",
	"Status",
];

export const PHYSICIAN_ASSISTANTS = [
	"",
	"ID",
	"Name",
	"License Number",
	"NPI",
	"Taxonomy ID",
	"Address",
	"Phone",
	"Status",
];

export const PROCEDURE_LISTING_COLUMNS = [
	"id",
	"CPT Code",
	"CPT Description",
	"Name",
	"Speciality",
	"Related Diagnosis",
];

export const BATCH_LISTING_COLUMNS = [
	"Name",
	"TRN",
	"Date",
	"Total",
	"Payment Mode",
];

export const PAYMENT_POSTING_COLUMNS = [
	"DOS",
	"Event ID",
	"Assistant",
	"Patient",
	"Bill ID",
];

export const ACCOUNTS_COLUMNS = [
	"Invoice #",
	"Facility",
	"Receivable",
	"Amount Received",
	"Balance",
];

export const ORGANIZATION_LISTING_COLUMNS = [
	"Name",
	"Email",
	"Phone",
	"Address",
	"Active",
];

export const NOTIFICATION_LISTING_COLUMN = [
	"Type",
	"Created at",
	"Message",
	"Facility",
	"DOS",
];

export const FEEDBACK_LISTING_COLUMN = [
	"ID",
	"Date",
	"User Name",
	"User Email",
	"Type",
	"Comments",
];
