import "../../../styles/base.scss";
import "../../../styles/pages.scss";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { updateUser } from "../../actions/profile";
import {
	APP_ROUTES,
	LOCAL_STORAGE_KEYS,
	NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import dummyprofileimage from "../../../assets/images/img.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faFolder } from "@fortawesome/free-solid-svg-icons";
import FileInput from "../../../components/common/ui/FileInput";
import ButtonTextIcon from "../../../components/common/ui/ButtonTextIcon";
import {
	API_URL,
	LARAVEL_API_URL,
	SCANFLOW_STATUS_LIST,
	SYSTEM_TOKEN,
} from "../../../opl-utilities/constants";

const REQUIRED_FIELDS = {
	FIRST_NAME: "firstName",
	LAST_NAME: "lastName",
	EMAIL: "email",
};
class Profile extends React.Component {
	state = {
		errors: [],
		firstName: "",
		lastName: "",
		email: "",
		file: null,
		uploadedURL: "",
		imagePreview: dummyprofileimage,
	};
	comp;

	componentDidMount() {
		this.fetchUserProfile();
		// Retrieve the logged-in user details from local storage
		const userId = JSON.parse(
			localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
		);

		if (userId) {
			// Set the user profile details in the state (first name, last name, email)
			this.setState({
				firstName: userId.name,
				lastName: userId.last_name,
				email: userId.email,
			});

			// Check if the image is valid and not the default avatar
			if (userId.image !== "assets/avatar/") {
				// Retrieve tenant-related information from local storage
				const tenantId =
					localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID) || null;
				const tenantFound =
					localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_FOUND) ||
					null;

				// Get the base URL from the environment variables
				const baseURL = LARAVEL_API_URL;

				// Construct the tenant URL based on the tenant ID and subdomain
				let tenantURL = baseURL.replace(
					"{tenant}",
					tenantId && tenantFound && tenantFound === "1"
						? `${tenantId}/`
						: ""
				);

				// Extract the subdomain from the current URL
				let url = window.location.href.split(".");
				let firstPart = url[0]
					.replace("http://", "")
					.replace("https://", "");

				// Update tenant URL with the subdomain
				tenantURL = tenantURL.replace("{subdomain}", firstPart);

				// Set the image preview state with the proper URL
				this.setState({
					imagePreview: tenantURL.replace("api/", "") + userId.image,
				});
			}
		}
	}

	// onentDidMount() {
	//   const userId = JSON.parse(
	//     localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
	//   );

	//   if (userId) {
	//     // userProfileService.getUserProfile(userId).then((response) => {

	//     this.setState({
	//       firstName: userId.name,
	//       lastName: userId.last_name,
	//       email: userId.email,
	//     });
	//     // });
	//     if (userId.image !== "assets/avatar/") {
	//       const tenantid = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID) || null;
	//       const tenantfound = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_FOUND) || null;

	//       // eslint-disable-next-line
	//       let tenantURL = baseURL.replace('{tenant}', ((tenantid && tenantfound && tenantfound == "1") ? tenantid + '/' : ''));

	//       let url = window.location.href;
	//       url = url.split('.');
	//       let firstPart = url[0].replace('http://', '').replace('https://', '');
	//       tenantURL = tenantURL.replace('{subdomain}', firstPart);

	//       this.setState({
	//         imagePreview: tenantURL.replace("api/", '') + userId.image,
	//       });
	//     }
	//   }
	// }

	hasErrors = (field) => {
		return this.state.errors.includes(field);
	};
	resetErrors = (field) => {
		this.setState({
			errors: this.state.errors.filter(
				(fieldName) => fieldName !== field
			),
		});
	};

	validateActivity = () => {
		const { firstName, lastName, errors } = this.state;

		let isValid = true;

		if (firstName.length === 0) {
			this.setState({ errors: [...errors, REQUIRED_FIELDS.FIRST_NAME] });
			isValid = false;
		} else if (lastName.length === 0) {
			this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
			isValid = false;
		}

		if (!isValid) {
			createNotification(
				NOTIFICATION_TYPES.ERROR,
				"Please select required values"
			);
		}

		return isValid;
	};

	// submitUser = () => {
	//   if (!this.validateActivity()) {
	//     return;
	//   }
	//   const { updateUser } = this.props;
	//   const { firstName, lastName, email, file } = this.state;

	//   // const request = {
	//   //   first_name: firstName,
	//   //   last_name: lastName,
	//   //   email: email,
	//   //   image: file,
	//   // };

	//   const userId = JSON.parse(
	//     localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
	//   );

	//   const formData = new FormData();
	//   formData.append("file", file);
	//   formData.append("first_name", firstName);
	//   formData.append("last_name", lastName);
	//   formData.append("email", email);
	//   formData.append("id", userId["id"]);
	//   if (userId) {
	//     // const payload = { ...request, id: userId, formData };
	//     updateUser(formData)
	//       .then((res) => {

	//         if (res.response.success) {
	//           createNotification(
	//             NOTIFICATION_TYPES.SUCCESS,
	//             res.response.message
	//           );
	//           localStorage.setItem(
	//             LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
	//             JSON.stringify({
	//               ...userId,
	//               name: this.state.firstName,
	//               last_name: this.state.lastName,
	//               image: res.response.data.image,
	//             })
	//           );
	//           this.props.history.push(APP_ROUTES.PROFILE);
	//         } else {
	//           createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
	//           //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
	//         }
	//       })
	//   }
	// };

	submitUser = (data) => {
		if (!this.validateActivity()) {
			return;
		}

		const { firstName, lastName, email, file } = this.state;
		const { updateUser } = this.props;

		// Get the logged-in user details from localStorage
		const userId = JSON.parse(
			localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
		);

		if (!userId) {
			createNotification(NOTIFICATION_TYPES.ERROR, "User not logged in.");
			return;
		}

		const formData = new FormData();
		formData.append("file", file); // Profile picture
		formData.append("firstName", firstName);
		formData.append("lastName", lastName || "");
		formData.append("email", email);
		formData.append("id", userId["id"]);

		fetch(`${API_URL}/api/1.0.0/profile`, {
			method: "PUT",
			headers: {
				"accept": "application/json, text/plain, */*",
				"Content-Type": "application/json",
				"X-JWT-Assertion": localStorage.getItem("token"),
			},
			body: JSON.stringify({
				id: userId["id"],
				firstName: firstName,
				lastName: lastName || "",
				email: email,
				imageUrl: file,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				// Handle the response
				if (res.result.responseCode === 200) {
					createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
					// Update local storage with new user info
					localStorage.setItem(
						LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
						JSON.stringify({
							...userId,
							firstName: firstName,
							lastName: lastName,
							// imageUrl: "res.data.imageUrl",  // Updated image URL from response
						})
					);
					this.fetchUserProfile();
					this.props.history.push(APP_ROUTES.PROFILE); // Redirect to the profile page
					window.location.reload();
				}
			})
			.catch((error) => {
				console.error("Error updating profile:", error);
				createNotification(
					NOTIFICATION_TYPES.ERROR,
					"Failed to update profile."
				);
			});
	};

	onProfileImageChangeHandler = (event) => {
		this.setState({
			imagePreview: URL.createObjectURL(event.target.files[0]),
			file: event.target.files[0],
		});
	};

	handleUpload = async () => {
		const { file } = this.state;

		if (file) {
			this.setState({ loading: true });

			try {
				const formData = new FormData();
				formData.append("file", file, file.name);
				console.log("file.name", file.name);

				formData.append("type", "PROFILE_PICTURE");

				const response = await fetch(
					`${API_URL}/api/1.0.0/uploadFile`,
					{
						method: "POST",
						headers: {
							"X-JWT-Assertion": localStorage.getItem("token"),
						},
						body: formData,
					}
				);

				if (response.ok) {
					const responseData = await response.json();
					if (responseData.result.responseCode === 200) {
						this.setState({ uploadedURL: responseData.data });
						this.setImage(responseData.data);
					}
				} else {
					const errorData = await response.json();
					throw new Error(
						errorData.responseDescription || "Upload failed."
					);
				}
			} catch (error) {
				console.error("Error uploading or processing file:", error);
				// Add error handling or user notification here if needed
			} finally {
				this.setState({ loading: false });
			}
		}
	};

	fetchUserProfile = async () => {
		const userId = JSON.parse(
			localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
		);

		if (!userId) {
			createNotification(NOTIFICATION_TYPES.ERROR, "User not logged in.");
			return;
		}

		try {
			const response = await fetch(
				`${API_URL}/api/1.0.0/profile/${userId.id}`,
				{
					method: "GET",
					headers: {
						"accept": "application/json, text/plain, */*",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);

			if (response.ok) {
				const resData = await response.json();

				if (resData.result.responseCode === 200) {
					// Assuming 'data' contains the user profile information
					const { name, lastName, email, image } = resData.data;
					console.log("image", image);
					this.setState({
						firstName: name || "", // If undefined, set to empty string
						lastName: lastName || "", // If undefined, set to empty string
						email: email || "", // If undefined, set to empty string
						imagePreview: image || "", // If undefined, set to empty string
					});
					localStorage.setItem(
						LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
						JSON.stringify({
							...userId,
							firstName: name,
							lastName: lastName,
							imageUrl: image,
						})
					);
					// createNotification(NOTIFICATION_TYPES.SUCCESS, "Profile data fetched successfully!");
				}
			}
		} catch (error) {
			console.error("Error fetching profile:", error);
			createNotification(
				NOTIFICATION_TYPES.ERROR,
				"Failed to fetch user profile."
			);
		}
	};

	setImage = (file) => {
		this.setState({ file: file });
		console.log("filee", file);
	};

	render() {
		const userId = JSON.parse(
			localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
		);
		const { firstName, lastName, email, imagePreview } = this.state;
		const { file, uploadedURL, loading } = this.state;

		return (
			<div className='main_container'>
				<div className='col-md-3 custom-sidebar-menu left_col'>
					<Sidebar activePage={this.props.activePage} />
				</div>
				<TopNavbar />
				<div className='manage-activity-right-col'>
					<div className='activity-row'>
						<div className='page-title'>
							{<h2 className='heading-custom'>Edit User</h2>}
						</div>
						<div class='container'>
							<div className='row'>
								<div className=' mrgn-btm10'>
									<div>
										<img
											style={{
												width: "100px",
												height: "100px",
												borderRadius: "50%",
												objectFit: "cover",
												border: "2px solid midnightblue ",
											}}
											// src={this.state.file}
											src={imagePreview}
											// alt="Profile"
											// src={this.state.imagePreview}
											alt=''
											accept='image/*'
										/>
									</div>
									&nbsp;
									{/* <div>
                    <input
                      type="file"
                      name="file"
                      className="file-upload"
                      onChange={this.onProfileImageChangeHandler}
                    />
                  </div> */}
									<div id='opl_coremax'>
										<div
											className='flex center-left'
											style={{
												gap: "10px",
												backgroundColor: "#f7f7f7",
											}}>
											<div style={{ width: "100%" }}>
												<label>Upload File</label>
												<br />
												<FileInput
													style={{
														background: "white",
													}}
													ref={this.fileInputRef}
													defaultText=' file'
													accept='image/jpeg, image/jpg, image/png,'
													callback={this.setImage}
												/>
												{file && (
													<p
														className='cursor-pointer'
														style={{
															fontSize: "12px",
															color: "red",
														}}
														onClick={() => {
															this.setState({
																file: null,
																uploadedURL: "",
															});
															if (
																this
																	.fileInputRef
																	.current
															) {
																this.fileInputRef.current.reset();
															}
														}}>
														Delete file
													</p>
												)}
											</div>
											<div className='col-md-1'>
												<ButtonTextIcon
													style={{
														marginTop: "30px",
													}}
													type='button'
													title={
														<FontAwesomeIcon
															icon={
																uploadedURL
																	? faFolder
																	: faFileUpload
															}
															color='white'
														/>
													}
													disabled={loading || !file}
													onClick={this.handleUpload}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-4 mrgn-btm10'>
									<label>
										First Name{" "}
										<span className='required'>*</span>
									</label>
									<input
										type='text'
										className={
											this.hasErrors(
												REQUIRED_FIELDS.FIRST_NAME
											)
												? "error code-input"
												: "code-input"
										}
										value={this.state.firstName}
										onChange={(event) => {
											this.resetErrors(
												REQUIRED_FIELDS.FIRST_NAME
											);
											this.setState({
												firstName: event.target.value,
											});
										}}
									/>
								</div>

								<div className='col-md-4 mrgn-btm10'>
									<label>
										Last Name{" "}
										<span className='required'>*</span>
									</label>
									<input
										type='text'
										className={
											this.hasErrors(
												REQUIRED_FIELDS.LAST_NAME
											)
												? "error code-input"
												: "code-input"
										}
										value={this.state.lastName}
										onChange={(event) => {
											this.resetErrors(
												REQUIRED_FIELDS.LAST_NAME
											);
											this.setState({
												lastName: event.target.value,
											});
										}}
									/>
								</div>

								<div className='col-md-4 mrgn-btm10'>
									<label>
										Email{" "}
										<span className='required'>*</span>
									</label>
									<input
										disabled='disabled'
										type='text'
										className={
											this.hasErrors(
												REQUIRED_FIELDS.EMAIL
											)
												? "error code-input"
												: "code-input"
										}
										value={this.state.email}
										onChange={(event) => {
											this.resetErrors(
												REQUIRED_FIELDS.EMAIL
											);
											this.setState({
												lastName: event.target.value,
											});
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='filter-footer'>
						{userId && (
							<button
								onClick={
									this.submitUser || this.fetchUserProfile
								}
								className='filter-save-button'>
								Update
							</button>
						)}
						<button
							onClick={() => {
								this.props.history.push(
									APP_ROUTES.USER_DASHBOARD
								);
							}}
							className='filter-cancel-button'>
							Cancel
						</button>
					</div>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateUser,
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(withRouter(Profile));
