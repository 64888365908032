import { withRouter } from "react-router-dom"; 
import LoginErrorImg from "../static/login-error.svg"; 
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import Header from "../components/common/ui/Header";

const NotAuthorized = withRouter(({location, history}) => { 

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <section id='opl_coremax' style={{ height: "100vh" }}>
            <section className='main-section'>
                <Header location={location} />
                <div
                    className='flex center-center'
                    style={{ backgroundColor: "white", height: "100vh" }}>

                    <div className='t-a-c'>
                        <img
                            src={LoginErrorImg}
                            style={{
                                maxWidth: "320px",
                                height: "auto",
                                margin: "0 auto",
                                display: "block",
                            }}
                        />
                        <br />
                        <p className='color-primary font-size-14 f-w-600'>
                            Access Denied.
                        </p>
                        <p className='color-primary font-size-12 f-w-300'>
                            You are not authorized to view this content.
                        </p>
                        <br />

                        <ButtonTextIcon
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'>
                                    <path
                                        d='M12,0A12,12,0,1,1,0,12,12.006,12.006,0,0,1,12,0Zm0,1A11,11,0,1,1,1,12,11.005,11.005,0,0,1,12,1ZM7.172,12.5l4.608,3.763L11.1,17,5,12l6.112-5,.666.753L7.174,11.5H19v1Z'
                                        fillRule='evenodd'
                                    />
                                </svg>
                            }
                            onClick={handleGoBack}
                            title='Go back'
                        />
                    </div>
                </div>
            </section>
        </section>
    );
});

export default NotAuthorized;
