import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import ButtonTextIcon from '../../components/common/ui/ButtonTextIcon';
import LoaderIcon from '../../components/common/ui/LoaderIcon';
import {
    API_URL,
    SYSTEM_TOKEN,
} from "../../opl-utilities/constants";

const validationSchema = Yup.object({
    name: Yup.string().required('Region name is required'),
    email: Yup.string().email('Invalid email format').required('Region email is required'),
});

function UpdateForm({ history, match }) {
    const [initialValues, setInitialValues] = useState({ name: '', email: '' });
    const [loading, setLoading] = useState(false);
    const [regionId, setRegionId] = useState(null);
    const [loadingData, setLoadingData] = useState(true);
    const Label = ({ title, required }) => (
        <p className='color-primary f-w-600' style={{ paddingBottom: '5px' }}>
            {title}
            {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        </p>
    );

    const fetchRegionData = async () => {
        try {
            setLoadingData(true);
            const response = await fetch(`${API_URL}/api/1.0.0/region/${regionId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
            });
            if (response.ok) {
                const data = await response.json();
                console.log("data is", data.data);

                setInitialValues({
                    name: data?.data?.name,
                    email: data?.data?.email,
                });
                console.log("values are", initialValues);

            } else {
                console.error("Failed to fetch region data");
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoadingData(false);
        }
    };
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("id");
        setRegionId(id);
    }, []);

    useEffect(() => {
        fetchRegionData();
    }, [regionId]);

    const updateRegion = async (values) => {
        setLoading(true);
        try {
            const payload = JSON.stringify({
                name: values.name,
                email: values.email,
            });
            // const regionId = 1;

            console.log("Update Payload:", payload);


            const response = await fetch(API_URL +`/api/1.0.0/region/${regionId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
                body: payload,
            });
            if (response.ok) {
                console.log(await response.json());

                history.push('/regions');
            } else {
                console.error("Failed to update region data");
            }

        } catch (e) {
            console.error("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    if (loadingData) {
        // Show loader while data is being fetched
        return (
            <p
                style={{
                    height: "100vh",
                    paddingBottom: "20vh",
                    backgroundColor: "#fafaff"
                }}
                className='flex center-center'>
                <LoaderIcon
                    style={{ height: "32px", width: "32px" }}
                />
            </p>
        );
    }
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                await updateRegion(values);
                resetForm();
            }}
        >
            {({ handleSubmit, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Label title="Region Name" required />
                                        <Field
                                            name="name"
                                            type="text"
                                            placeholder="Enter Region Name"
                                            className={`primary ${errors.name && touched.name ? 'error' : ''}`}
                                        />

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="Region Email" required />
                                        <Field
                                            name="email"
                                            type="email"
                                            placeholder="Enter Region Email"
                                            className={`primary ${errors.email && touched.email ? 'error' : ''}`}
                                        />

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push('/regions')}
                            icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Update Now'
                            className='mar-l-a'
                            icon={loading ? <LoaderIcon /> : (
                                <svg xmlns='http://www.w3.org/2000/svg' width='19.995' height='19.996' viewBox='0 0 19.995 19.996'>
                                    <path d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z' transform='translate(-2.014 -1.995)' />
                                </svg>
                            )}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default UpdateForm;