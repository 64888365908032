
import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken, formatServerDate } from "../opl-utilities/helpers";
import TableFilter from "./surgeon/TableFilter";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import FullScreenFileViewerModal from "../components/common/ui/FullScreenFileViewerModal";

const jsonToQueryParams = (json) => {
    const queryParams = Object.keys(json)
        .filter((key) => json[key] !== null && json[key] !== "")
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
        )
        .join("&");

    return queryParams;
};
const getRandomColor = () => {
    const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};

const SurgeonPage = ({ history, location, activePage }) => {
    const [ids, setIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const pageSize = queryParams.get("pageSize") || 12;
    const pageNo = queryParams.get("pageNo") || 0;
    const { addToastMessage } = useUI();

    const toggleNumberInArray = (number) => {
        setIds((prevArray) => {
            const index = prevArray.indexOf(number);
            if (index === -1) {
                return [...prevArray, number];
            } else {
                const newArray = [...prevArray];
                newArray.splice(index, 1);
                return newArray;
            }
        });
    };

    // Decode permissions for the "groups" module
    const permissions = decodeModuleAccessFromToken("surgeons");

    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);
    // ====== FILTER QUERY PARAMS
    const search = queryParams.get("search") || "";
    const status = queryParams.get("status") || "";

    // ====== RESET FILTER
    const resetFilters = () => {
        updateQueryParam("status", "");
        updateQueryParam("search", "");
        updateQueryParam("pageNo", 0);
    };



    const getListApi = async (s) => {
        setLoading(true);
        try {
            const query = jsonToQueryParams({
                search: search,
                status: status,
            });
            const resp = await fetch(
                API_URL +
                `/api/1.0.0/surgeon?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token"),
                    },
                }
            );
            if (resp.ok) {
                const response = await resp.json();
                if (response.result.responseCode === 200) {
                    setData(response.data);
                } else {
                    setData(null);
                }
            }
        } catch (e) {
            console.log("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    const updateQueryParam = (key, value) => {
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };

    const active = async (type) => {
        try {
            if (!ids.length) {
                addToastMessage({
                    status: false,
                    message: `Something went wrong while ${type} surgeons. Please try again.`,
                });
                return false;
            }
            const response = await fetch(
                API_URL +
                `/api/1.0.0/surgeon/change-status/${type}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                    body: JSON.stringify({
                        "ids": ids
                    })
                }
            );
            if (response.ok) {
                const res = await response.json();
                console.log(res);
                if (res.result.responseCode === 200) {
                    getListApi();
                    setIds([]);
                    addToastMessage({
                        status: true,
                        message: `Surgeon ${type === "0" ? "Deactivated" : type === "1" ? "Activated" : "NA"} successfully.`,
                    });
                    return true;
                } else {
                    addToastMessage({
                        status: false,
                        message: res?.responseDescription || "Invalid Request",
                    });
                    return false;
                }
            } else {
                const res = await response.json();
                addToastMessage({
                    status: false,
                    message: res?.responseDescription || "Invalid Request",
                });
                return false;
            }
        } catch (error) {
            addToastMessage({
                status: false,
                message: `Something went wrong while  ${type === "0" ? "Deactivated" : type === "1" ? "Activated" : "NA"} surgeons. Please try again.`,
            });
            return false;
        }
    }

    const handleDelete = async (id) => {
        try {
            if (!id) {
                addToastMessage({
                    status: false,
                    message: `Something went wrong while deleting surgeons. Please try again.`,
                });
                return false;
            }
            const response = await fetch(
                API_URL +
                `/api/1.0.0/surgeon/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
            );
            if (response.status === 200) {
                getListApi();
                addToastMessage({
                    status: true,
                    message: `surgeon deleted successfully.`,
                });
                return true;
            }

            addToastMessage({
                status: false,
                message: `Something went wrong while deleting  surgeon. Please try again.`,
            });

            return false;
        } catch (error) {
            addToastMessage({
                status: false,
                message: `Something went wrong while deleting surgeon. Please try again.`,
            });
            return false;
        }
    };

    useEffect(() => {
        getListApi();
    }, [location.search]);

    return (
        <section id='opl_coremax'>
            <section className='main-section'>
                <Header active={activePage} location={location} />
                <section id='physician-assistant'>
                    <div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
                        <h5 className='page-active color-primary f-w-700'>
                            Surgeon
                        </h5>
                        {permissions?.isCreateAllowed &&
                            <ButtonTextIcon
                                title='Add New'
                                className='mar-l-a'
                                onClick={() => history.push("/add-surgeon")}
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.995'
                                        viewBox='0 0 19.995 19.995'>
                                        <path
                                            d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
                                            transform='translate(-2.005 -2)'
                                        />
                                    </svg>
                                }
                            />
                        }
                        &nbsp;
                    </div>
                    <TableFilter
                        ids={ids}
                        callback={(type) => { active(type) }}
                        resetFilters={() => {
                            resetFilters();
                        }}
                    />
                    <div>
                        {data && !loading && (
                            <div
                                className='flex center-left'
                                style={{
                                    padding: "10px 15px 10px 15px",
                                    gap: "12px",
                                }}>
                                <p
                                    className='f-w-300'
                                    style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
                    {loading ? (
                        <section
                            style={{
                                height: "100vh",
                                paddingBottom: "20vh",
                                backgroundColor: "#fafaff"
                            }}
                            className='flex center-center'>
                            <LoaderIcon
                                style={{ height: "32px", width: "32px" }}
                            />
                        </section>
                    ) : data?.records.length ? (
                        <div className='rs-table-holder'>
                            <table className='rs-table'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>License Number</th>
                                        <th>NPI	</th>
                                        <th>Taxonomy ID</th>
                                        <th>Address</th>
                                        <th>Fax</th>
                                        <th>Status</th>
                                        <th style={{ textAlign: 'center' }}>Action</th>
                                    </tr>
                                </thead>
                                {data && (
                                    <tbody>
                                        {data?.records?.map((item, i) => (
                                            <tr key={i}>
                                                <td><input onClick={() => { toggleNumberInArray(item.id); }} className="primary" type="checkbox" checked={ids.includes(item.id)} /></td>
                                                <td >{pageNo * pageSize + i + 1}</td>
                                                <td>{item.firstName + " " + item.lastName}</td>
                                                <td>{item.surgeonDetails?.license}</td>
                                                <td>{item.npi}</td>
                                                <td>{item.surgeonDetails?.primaryTaxonomy}</td>
                                                <td>{item.surgeonDetails?.address} - {item.surgeonDetails?.state} - {item.surgeonDetails?.city} {item.surgeonDetails?.zip}</td>
                                                <td>{item?.surgeonDetails?.fax}</td>
                                                <td><p className="f-w-500">{item.statusId === 0 ? "In-Active" : item.statusId === 1 ? "Active" : "NA"}</p></td>
                                                <td>
                                                    <div className="flex center-center" style={{ gap: '12px' }}>
                                                        {permissions?.isReadAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">

                                                                <CustomModal
                                                                    selector={
                                                                        <ButtonTertiary
                                                                            actionType='view'
                                                                            title='View'
                                                                        />
                                                                    }
                                                                    content={
                                                                                <SurgeonDetailsContent
                                                                                    id={item?.id}
                                                                                    history={history}
                                                                                />
                                                                    }
                                                                />
                                                            </div>

                                                        )}
                                                        {permissions?.isUpdateAllowed && <div>
                                                            <NavLink
                                                                to={{
                                                                    pathname: '/edit-surgeon',
                                                                    state: { id: item.id }
                                                                }}
                                                            >
                                                                <ButtonTertiary
                                                                    actionType='edit'
                                                                    title='Edit'
                                                                />
                                                            </NavLink>
                                                        </div>
                                                        }

                                                        {permissions?.isDeleteAllowed &&
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer " >
                                                                <CustomModal
                                                                    selector={<ButtonTertiary
                                                                        actionType='delete'
                                                                        title='Delete'
                                                                    />}
                                                                    content={
                                                                        <ConfirmationModal
                                                                            onConfirm={async () => { await handleDelete(item.id); return true; }}
                                                                            message="Are you sure you want to delete this surgeon?"
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    ) : (
                        <section
                            style={{
                                height: "80vh",
                                paddingBottom: "20vh",
                                backgroundColor: "#fff",
                            }}
                            className="flex center-center"
                        >
                            <p>No records found.</p>
                        </section>
                    )}

                    {data && !loading && (
                        <div
                            className='flex center-center'
                            style={{
                                padding: "10px 15px 10px 15px",
                                gap: "12px",
                            }}>
                            <p
                                className='f-w-300'
                                style={{ fontSize: "11px" }}>
                                Page no:
                            </p>
                            <Pagination
                                totalRecords={data.totalRecords}
                                pageNo={data.pageNo}
                                pageSize={data.pageSize}
                            />
                        </div>
                    )}
                </section>
            </section>
        </section>
    );
};

const SurgeonDetailsContent = ({ setShow, id, status, history }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // Decode permissions for the "facilities" module
    const permissions = decodeModuleAccessFromToken("surgeons");

    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);

    const InfoItem = ({ icon, label, name, ...rest }) => {
        return (
            <div {...rest}>
                <div className='flex center-left' style={{ gap: "10px" }}>
                    <div>{icon}</div>
                    <div style={{ overflow: "hidden" }}>
                        <p
                            className='f-w-300'
                            style={{
                                color: "#aaaaaa",
                                fontSize: "10px",
                                lineHeight: 1,
                                paddingBottom: "2px",
                            }}>
                            {label}
                        </p>
                        <p
                            style={{
                                width: "200px",
                                lineHeight: 1,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}>
                            {name}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const fetchFacilityData = async (id) => {
        try {
            const resp = await fetch(
                API_URL +
                `/api/1.0.0/surgeon/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
            );
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data);
                    setLoading(false);
                }
            }
        } catch (e) {
            setError("Failed to fetch facility data.");
            setLoading(false);
            console.log("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) fetchFacilityData(id);
    }, [id]);

    return (
      <section id="opl_coremax">
        <section id="custom-modal">
          <div className="inner">
            <div className="box" id="activity-detail-box">
              <div className="flex center-left">
                <div>
                  <h6 className="color-primary f-w-300">Surgeon Details</h6>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <ButtonLightTextIcon
                    title="Cancel"
                    onClick={() => setShow(false)}
                    icon={
                      <svg
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.995"
                        height="19.996"
                      >
                        <path
                          d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                          fillRule="nonzero"
                        />
                      </svg>
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  height: "60vh",
                  margin: "10px",
                  overflowY: "scroll",
                  padding: "20px 5px",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                }}
              >
                {loading && (
                  <div
                    className="col-1-1"
                    style={{
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LoaderIcon
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                )}
                {!loading && error && (
                  <p
                    className="f-w-300 color-primary"
                    style={{ color: "#c0392b" }}
                  >
                    {error}
                  </p>
                )}
                {data && !loading && !error && (
                  <div className="activity-card pos-rel">
                    <div
                      style={{
                        position: "absolute",
                        content: "",
                        height: "4px",
                        left: "-1px",
                        right: "-1px",
                        bottom: "-2px",
                        borderBottomLeftRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    ></div>
                    <div
                      className="col-1-1 flex center-left"
                      style={{
                        gap: "10px",
                        paddingBottom: "5px",
                      }}
                    >
                      <h5 className="f-w-700">
                        <span className="f-w-300">#</span>
                        &nbsp;
                        {data?.id}
                      </h5>
                      <hr
                        style={{
                          outline: "none",
                          border: "none",
                          height: "16px",
                          width: "2px",
                          background: "#EBEBEB",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: "12px 0 12px 0 ",
                      }}
                    >
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M18 20a6 6 0 0 0-12 0"></path>
                              <circle cx="12" cy="10" r="4"></circle>
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                          }
                          label={"Surgeon"}
                          name={data?.firstName + " " + data?.lastName}
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="lucide lucide-map-pin h-4 w-4 text-muted-foreground"
                              data-id="72"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
                              <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                          }
                          label={"Address"}
                          name={data?.surgeonDetails?.address || "NA"}
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              data-id="28"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1"></path>
                              <path d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4"></path>
                            </svg>
                          }
                          label={"Designation"}
                          name={data?.designation || "NA"}
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              data-id="28"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1"></path>
                              <path d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4"></path>
                            </svg>
                          }
                          label={"Suffix"}
                          name={data?.suffix || "NA"}
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M4.8 2.3A.3.3 0 1 0 5 2H4a2 2 0 0 0-2 2v5a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V4a2 2 0 0 0-2-2h-1a.2.2 0 1 0 .3.3"></path>
                              <path d="M8 15v1a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6v-4"></path>
                              <circle cx="20" cy="10" r="2"></circle>
                            </svg>
                          }
                          label={"NPI"}
                          name={data?.npi || "NA"}
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="23"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                            </svg>
                          }
                          label={"Email"}
                          name={data?.surgeonDetails?.email || "NA"}
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M5.829 6c-.412 1.165-1.524 2-2.829 2-1.656 0-3-1.344-3-3s1.344-3 3-3c1.305 0 2.417.835 2.829 2h13.671c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5h-4.671c-.412 1.165-1.524 2-2.829 2-1.305 0-2.417-.835-2.829-2h-4.671c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h13.671c.412-1.165 1.524-2 2.829-2 1.656 0 3 1.344 3 3s-1.344 3-3 3c-1.305 0-2.417-.835-2.829-2h-13.671c-2.484 0-4.5-2.016-4.5-4.5s2.016-4.5 4.5-4.5h4.671c.412-1.165 1.524-2 2.829-2 1.305 0 2.417.835 2.829 2h4.671c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h-13.671zm6.171 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
                            </svg>
                          }
                          label={"License"}
                          name={data?.surgeonDetails?.license || "NA"}
                        />

                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z"></path>
                              <path d="M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5"></path>
                              <path d="M4 15v-3a6 6 0 0 1 6-6h0"></path>
                              <path d="M14 6h0a6 6 0 0 1 6 6v3"></path>
                            </svg>
                          }
                          label={"Primary Taxonomy"}
                          name={data?.surgeonDetails?.primaryTaxonomy || "NA"}
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                              className="lucide lucide-info h-4 w-4 text-muted-foreground"
                              data-id="30"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="M12 16v-4"></path>
                              <path d="M12 8h.01"></path>
                            </svg>
                          }
                          label={"Secondary Taxonomy"}
                          name={data?.surgeonDetails?.secondaryTaxonomy || "NA"}
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="lucide lucide-flag h-4 w-4 text-muted-foreground"
                              data-id="76"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                              <line x1="4" x2="4" y1="22" y2="15"></line>
                            </svg>
                          }
                          label={"Primary Specialty"}
                          name={
                            data?.surgeonDetails?.primarySpecialty?.name || "NA"
                          }
                        />
                      </div>

                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M18 20a6 6 0 0 0-12 0"></path>
                              <circle cx="12" cy="10" r="4"></circle>
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                          }
                          label={"Sub Specialty"}
                          name={
                            data?.surgeonDetails?.primarySpecialty?.name || "NA"
                          }
                        />

                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M12 6v4"></path>
                              <path d="M14 14h-4"></path>
                              <path d="M14 18h-4"></path>
                              <path d="M14 8h-4"></path>
                              <path d="M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2"></path>
                              <path d="M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18"></path>
                            </svg>
                          }
                          label={"Facility"}
                          name={
                            data?.surgeonDetails?.facility?.facilityName || "NA"
                          }
                        />
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          icon={
                            <svg
                              color={getRandomColor()}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <path d="M18 20a6 6 0 0 0-12 0"></path>
                              <circle cx="12" cy="10" r="4"></circle>
                              <circle cx="12" cy="12" r="10"></circle>
                            </svg>
                          }
                          label={"Financial Clearance"}
                          name={
                            data?.surgeonDetails?.financialClearance || "NA"
                          }
                        />
                      </div>

                      <hr
                        style={{
                          marginTop: "10px",
                          paddingBottom: "10px",
                        }}
                      />
                      {/* <p
                        style={{
                          fontWeight: "700",
                          paddingBottom: "8px",
                        }}
                      >
                        Degree
                      </p>
                      {data?.degrees?.length ? (
                        data.degrees.map((degree, index) => (
                          <>
                            <div
                              key={index}
                              className="info-row flex center-center"
                              style={{
                                gap: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              <InfoItem
                                className="col-1-1 info-row-column"
                                icon={
                                  <svg
                                    color={getRandomColor()}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  >
                                    <path d="M18 20a6 6 0 0 0-12 0"></path>
                                    <circle cx="12" cy="10" r="4"></circle>
                                    <circle cx="12" cy="12" r="10"></circle>
                                  </svg>
                                }
                                label="Degree"
                                name={degree.degree || "NA"}
                              />
                              <InfoItem
                                className="col-1-1 info-row-column"
                                icon={
                                  <svg
                                    color={getRandomColor()}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  >
                                    <path d="M18 20a6 6 0 0 0-12 0"></path>
                                    <circle cx="12" cy="10" r="4"></circle>
                                    <circle cx="12" cy="12" r="10"></circle>
                                  </svg>
                                }
                                label={"Status"}
                                name={degree.status || "NA"}
                              />
                            </div>
                            <div
                              key={index}
                              className="info-row flex center-center"
                              style={{
                                gap: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              <InfoItem
                                className="col-1-1 info-row-column"
                                icon={
                                  <svg
                                    color={getRandomColor()}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    data-id="71"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  >
                                    <path d="M8 2v4"></path>
                                    <path d="M16 2v4"></path>
                                    <rect
                                      width="18"
                                      height="18"
                                      x="3"
                                      y="4"
                                      rx="2"
                                    ></rect>
                                    <path d="M3 10h18"></path>
                                  </svg>
                                }
                                label="Acquired Date"
                                name={
                                  degree?.dateAcquired
                                    ? formatServerDate(degree?.dateAcquired)
                                    : "NA"
                                }
                              />
                              <InfoItem
                                className="col-1-1 info-row-column"
                                icon={
                                  <svg
                                    color={getRandomColor()}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    data-id="71"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  >
                                    <path d="M8 2v4"></path>
                                    <path d="M16 2v4"></path>
                                    <rect
                                      width="18"
                                      height="18"
                                      x="3"
                                      y="4"
                                      rx="2"
                                    ></rect>
                                    <path d="M3 10h18"></path>
                                  </svg>
                                }
                                label={"Expired Date"}
                                name={
                                  degree?.dateExpired
                                    ? formatServerDate(degree?.dateExpired)
                                    : "NA"
                                }
                              />
                            </div>
                            {degree?.s3attachment && (
                              <CustomModal
                                selector={
                                  <InfoItem 
                                    className="col-1-1 info-row-column cursor-pointer"
                                    icon={
                                      <svg
                                        color={getRandomColor()}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      >
                                        <path d="M17.843 1c2.159 0 3.912 1.753 3.912 3.912 0 .395-.053 1.704-1.195 2.813l-8.465 8.465c-.596.671-2.12 1.279-3.299.099-1.178-1.177-.586-2.685.088-3.29l4.409-4.409.707.707-3.164 3.163.014.003-1.411 1.413.004.003c-.97 1.151.618 2.93 1.977 1.572l8.383-8.384c.656-.652.94-1.393.94-2.155 0-1.601-1.299-2.9-2.9-2.9-.783 0-1.495.311-2.018.818l-.003-.003c-.573.573-11.502 11.494-11.534 11.527l-.002-.002c-.795.812-1.286 1.923-1.286 3.148 0 2.483 2.017 4.5 4.5 4.5.65 0 1.84.007 3.52-1.668l10.273-10.267.707.707-10.477 10.477c-1.004 1.077-2.435 1.751-4.023 1.751-3.035 0-5.5-2.465-5.5-5.5 0-1.577.666-3 1.731-4.004 10.668-10.667 10.835-10.839 11.295-11.297.277-.278 1.215-1.199 2.817-1.199" />
                                      </svg>
                                    }
                                    label={"Attachment"}
                                    name="Click to View"
                                  />
                                }
                                content={
                                  <FullScreenFileViewerModal
                                    title={`Document`}
                                    fileUrl={degree?.s3attachment || null}
                                  />
                                }
                              />
                            )}

                            <hr
                              style={{
                                marginTop: "10px",
                                paddingBottom: "10px",
                              }}
                            />
                          </>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px 12px",
                            backgroundColor: "rgb(245, 245, 245)",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                No Data Available
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}

                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "20px",
                        }}
                      >
                        <div className="col-1-1 info-row-column">
                          <div
                            className="flex center-left"
                            style={{ gap: "10px" }}
                          >
                            <div>
                              <svg
                                color={getRandomColor()}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-info h-4 w-4 text-muted-foreground"
                                data-id="30"
                                style={{
                                  height: "20px",
                                  width: "20px",
                                }}
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 16v-4"></path>
                                <path d="M12 8h.01"></path>
                              </svg>
                            </div>
                            <div
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <p
                                className="f-w-300"
                                style={{
                                  color: "#aaaaaa",
                                  fontSize: "10px",
                                  lineHeight: 1,
                                  paddingBottom: "2px",
                                }}
                              >
                                Note
                              </p>
                              <p
                                style={{
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100%",
                                }}
                              >
                                {data?.billingNotes || "NA"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="info-row flex center-center"
                        style={{
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <InfoItem
                          className="col-1-1 info-row-column"
                          label={"Created At"}
                          name={
                            data?.createdAt
                              ? formatServerDate(data?.createdAt)
                              : "NA"
                          }
                        />
                        <InfoItem
                          className="col-1-1 info-row-column"
                          label={"Updated At "}
                          name={
                            data?.updatedAt
                              ? formatServerDate(data?.updatedAt)
                              : "NA"
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {permissions?.isUpdateAllowed && (
                  <NavLink
                    to={
                      data?.id
                        ? {
                            pathname: "/edit-surgeon",
                            state: {
                              id: data?.id,
                            },
                          }
                        : "#"
                    }
                  >
                    <ButtonLightTextIcon
                      title="Edit Details"
                      icon={
                        <svg
                          width="15"
                          height="15"
                          style={{
                            height: "15px",
                            width: "15px",
                          }}
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z"
                            fill="#283f54"
                          />
                        </svg>
                      }
                    />
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    );
};

export default withRouter(SurgeonPage);