import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { RECORDS_PER_PAGE } from "../../constants/common";
import { facilityService } from "../../services/facilities";
import { configVaccine } from "../../services/configVaccine";
import { regionsService } from "../../services/regions";
import { assistantService } from "../../services/assistants";
import { procedureService } from "../../services/procedures";
import { patientService } from "../../services/patients";
import { surgeonService } from "../../services/surgeons";
import { eventFlagService } from "../../services/eventFlags";
import { eventTypesService } from "../../services/eventTypes";
import { practiceService } from "../../services/practices";
import { userService } from "../../services/users";
import { insuranceService } from "../../services/insurance";
import { procedureSpecialityService } from "../../services/procedureSpeciality";
import { knowledgeBaseCategoryService } from "../../services/knowledgeBaseCategories";
import { moduleService } from "../../services/modules";
import { knowledgeBaseSubCategoryService } from "../../services/knowledgeBaseSubCategories";
import { specialityService } from "../../services/speciality";
import { groupService } from "../../services/groups";
import { divisionService } from "../../services/division";
import { API_URL, SYSTEM_TOKEN } from "../../../opl-utilities/constants";

class CustomDropDown extends React.Component {
	static propTypes = {
		type: PropTypes.string.isRequired,
		isMulti: PropTypes.bool.isRequired,
		isRequired: PropTypes.bool,
		hideLabel: PropTypes.bool,
		isDisabled: PropTypes.bool,
		className: PropTypes.string,
		region: PropTypes.string,
		all: PropTypes.bool,
		selectedCategory: PropTypes.object,
		selectedValue: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
	};

	typeProperties = [];

	constructor(props) {
		super(props);
		this.cancel = "";

		if (this.props.isMulti) {
			this.typeProperties["facilities"] = {
				label: this.props.label ? this.props.label : "Facilities",
			};

			this.typeProperties["vaccinations"] = {
				label: this.props.label ? this.props.label : "Vaccinations",
			};

			this.typeProperties["regions"] = {
				label: this.props.label ? this.props.label : "Regions",
			};

			this.typeProperties["certificates"] = {
				label: this.props.label ? this.props.label : "Certificates",
			};

			this.typeProperties["procedures"] = {
				label: this.props.label ? this.props.label : "Procedures",
			};

			this.typeProperties["patients"] = {
				label: this.props.label ? this.props.label : "Patients",
			};

			this.typeProperties["divisions"] = {
				label: this.props.label ? this.props.label : "Divisions",
			};

			this.typeProperties["assistants"] = {
				label: this.props.label ? this.props.label : "Assistants",
			};

			this.typeProperties["regional_assistants"] = {
				label: this.props.label ? this.props.label : "Assistant",
			};

			this.typeProperties["surgeons"] = {
				label: this.props.label ? this.props.label : "Surgeons",
			};

			this.typeProperties["event_flags"] = {
				label: this.props.label ? this.props.label : "Event Flags",
			};

			this.typeProperties["event_types"] = {
				label: this.props.label ? this.props.label : "Event Types",
			};

			this.typeProperties["practices"] = {
				label: this.props.label ? this.props.label : "Practices",
			};

			this.typeProperties["status"] = {
				label: this.props.label ? this.props.label : "Status",
			};

			this.typeProperties["invoice_statuses"] = {
				label: this.props.label ? this.props.label : "Status",
			};

			this.typeProperties["activites_statuses"] = {
				label: this.props.label ? this.props.label : "Status",
			};

			this.typeProperties["users"] = {
				label: this.props.label ? this.props.label : "Users",
			};

			this.typeProperties["insurances"] = {
				label: this.props.label ? this.props.label : "Insurances",
			};

			this.typeProperties["procedure_specialities"] = {
				label: this.props.label ? this.props.label : "Specialities",
			};

			this.typeProperties["surgeon_specialities"] = {
				label: this.props.label ? this.props.label : "Specialities",
			};

			this.typeProperties["modules"] = {
				label: this.props.label ? this.props.label : "Modules Access",
			};

			this.typeProperties["app_modules"] = {
				label: this.props.label
					? this.props.label
					: "App Modules Access",
			};

			this.typeProperties[
				"knowledgebase_categories_with_sub_categories"
			] = {
				label: this.props.label
					? this.props.label
					: "Article Categories/Sub Categories",
			};

			this.typeProperties["knowledgebase_categories"] = {
				label: this.props.label
					? this.props.label
					: "Article Categories",
			};

			this.typeProperties["knowledgebase_sub_categories"] = {
				label: this.props.label
					? this.props.label
					: "Article Sub Categories",
			};

			this.typeProperties["groups"] = {
				label: this.props.label ? this.props.label : "Groups",
			};

			this.typeProperties["tenant_groups"] = {
				label: this.props.label ? this.props.label : "Tenant Groups",
			};
		} else {
			this.typeProperties["divisions"] = {
				label: this.props.label ? this.props.label : "Division",
			};

			this.typeProperties["facilities"] = {
				label: this.props.label ? this.props.label : "Facility",
			};

			this.typeProperties["vaccinations"] = {
				label: this.props.label ? this.props.label : "Vaccination",
			};

			this.typeProperties["regions"] = {
				label: this.props.label ? this.props.label : "Region",
			};

			this.typeProperties["certificates"] = {
				label: this.props.label ? this.props.label : "Certificate",
			};

			this.typeProperties["procedures"] = {
				label: this.props.label ? this.props.label : "Procedure",
			};

			this.typeProperties["patients"] = {
				label: this.props.label ? this.props.label : "Patient",
			};

			this.typeProperties["assistants"] = {
				label: this.props.label ? this.props.label : "Assistant",
			};

			this.typeProperties["regional_assistants"] = {
				label: this.props.label ? this.props.label : "Assistant",
			};

			this.typeProperties["surgeons"] = {
				label: this.props.label ? this.props.label : "Surgeon",
			};

			this.typeProperties["event_flags"] = {
				label: this.props.label ? this.props.label : "Event Flag",
			};

			this.typeProperties["event_types"] = {
				label: this.props.label ? this.props.label : "Event Type",
			};

			this.typeProperties["practices"] = {
				label: this.props.label ? this.props.label : "Practice",
			};

			this.typeProperties["status"] = {
				label: this.props.label ? this.props.label : "Status",
			};

			this.typeProperties["invoice_statuses"] = {
				label: this.props.label ? this.props.label : "Status",
			};

			this.typeProperties["activites_statuses"] = {
				label: this.props.label ? this.props.label : "Status",
			};

			this.typeProperties["users"] = {
				label: this.props.label ? this.props.label : "User",
			};

			this.typeProperties["insurances"] = {
				label: this.props.label ? this.props.label : "Insurance",
			};

			this.typeProperties["procedure_specialities"] = {
				label: this.props.label ? this.props.label : "Speciality",
			};

			this.typeProperties["surgeon_specialities"] = {
				label: this.props.label ? this.props.label : "Speciality",
			};

			this.typeProperties["modules"] = {
				label: this.props.label ? this.props.label : "Module Access",
			};

			this.typeProperties["app_modules"] = {
				label: this.props.label
					? this.props.label
					: "App Module Access",
			};

			this.typeProperties[
				"knowledgebase_categories_with_sub_categories"
			] = {
				label: this.props.label
					? this.props.label
					: "Article Category/Sub Category",
			};

			this.typeProperties["knowledgebase_categories"] = {
				label: this.props.label ? this.props.label : "Article Category",
			};

			this.typeProperties["knowledgebase_sub_categories"] = {
				label: this.props.label
					? this.props.label
					: "Article Sub Category",
			};

			this.typeProperties["groups"] = {
				label: this.props.label ? this.props.label : "Group",
			};

			this.typeProperties["tenant_groups"] = {
				label: this.props.label ? this.props.label : "Tenant Groups",
			};
		}
	}

	componentDidMount() {}

	loadEntities = (search, prevOptions) => {
		console.log(this.props.type);
		let options;

		if (search) {
			options = {
				isFromSearch: true,
				search,
				page: prevOptions.length / RECORDS_PER_PAGE + 1,
			};
		} else {
			options = {
				isFromSearch: true,
				page: prevOptions.length / RECORDS_PER_PAGE + 1,
			};
		}

		if (this.props.all) {
			options["all"] = true;
		}

		if (this.cancel) {
			this.cancel.cancel();
		}

		this.cancel = axios.CancelToken.source();

		if (this.props.type === "facilities") {
			return facilityService
				.getFacilities({ params: options })
				.then((response) => {
					const options = response.data.map((facility) => ({
						value: facility.id,
						label: facility.facility_name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		}
		if (this.props.type === "divisions") {
			return divisionService
				.getDivisions({ params: options })
				.then((response) => {
					const options = response.data.map((division) => ({
						value: division.id,
						label: division.name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "vaccinations") {
			return configVaccine
				.getVaccines({ params: options })
				.then((response) => {
					const options = response.data.map((vaccine) => ({
						value: vaccine.id,
						label: vaccine.name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "regions") {
			return regionsService
				.getRegions({ params: options })
				.then((response) => {
					const options = response.data.map((region) => ({
						value: region.id,
						label: region.name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "certificates") {
			return assistantService
				.getCertificates({ params: options })
				.then((response) => {
					const options = response.data.map((certificate) => ({
						value: certificate.id,
						label: certificate.name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "procedures") {
			return procedureService
				.getProcedures({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((procedure) => ({
						value: procedure.id,
						label: procedure.procedure_name,
						procedure_price: procedure.price,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "patients") {
			return patientService
				.getPatients({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((patient) => ({
						value: patient.id,
						label: `${patient.first_name} ${patient.last_name}`,
						account_number: patient.account_number,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "assistants") {
			return assistantService
				.getAssistants({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((assistant) => ({
						value: assistant.assistant_id,
						label: `${assistant.first_name} ${assistant.last_name}`,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "regional_assistants") {
			return assistantService
				.getRegionAssistants(this.props.region)
				.then((response) => {
					const options = response.data.map((assistant) => ({
						value: assistant.assistant_id,
						label: `${assistant.name} ${assistant.last_name}`,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "surgeons") {
			return surgeonService
				.getSurgeons({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((surgeon) => ({
						value: surgeon.id,
						label: `${surgeon.first_name} ${surgeon.last_name}`,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "event_flags") {
			return eventFlagService
				.getEventFlags({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((eventFlag) => ({
						value: eventFlag.id,
						label: eventFlag.schedule_flag,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "event_types") {
			return eventTypesService
				.getEventTypes({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((eventType) => ({
						value: eventType.id,
						label: eventType.event_type_name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "practices") {
			return practiceService
				.getPractices({ params: options })
				.then((response) => {
					const options = response.data.map((practice) => ({
						value: practice.id,
						label: practice.paytoname,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "status") {
			const options = [
				{
					value: "new",
					label: "New Schedule",
				},
				{
					value: "started",
					label: "Surgery In Progress",
				},
				{
					value: "completed",
					label: "Surgery Completed",
				},
				{
					value: "cancelled",
					label: "Cancelled Surgery",
				},
			];

			return {
				options: options,
				hasMore: false,
			};
		} else if (this.props.type === "invoice_statuses") {
			const options = [
				{
					value: "NOT STARTED",
					label: "NOT STARTED",
				},
				{
					value: "SUBMITTED",
					label: "SUBMITTED",
				},
				{
					value: "CORRECTED CLAIM",
					label: "CORRECTED CLAIM",
				},
				{
					value: "PAPER CLAIM",
					label: "PAPER CLAIM",
				},
				{
					value: "RECONSIDERATION",
					label: "RECONSIDERATION",
				},
				{
					value: "FOLLOW-UP",
					label: "FOLLOW-UP",
				},
				{
					value: "APPEAL 1",
					label: "APPEAL 1",
				},
				{
					value: "APPEAL 2",
					label: "APPEAL 2",
				},
				{
					value: "HOLD TO BILL",
					label: "HOLD TO BILL",
				},
				{
					value: "BILL PATIENT",
					label: "BILL PATIENT",
				},
				{
					value: "BILL HOSPITAL",
					label: "BILL HOSPITAL",
				},
				{
					value: "BILL PHYSICIAN",
					label: "BILL PHYSICIAN",
				},
				{
					value: "REVIEW ADJUSTMENT",
					label: "REVIEW ADJUSTMENT",
				},
				{
					value: "CLOSED",
					label: "CLOSED",
				},
				{
					value: "CLOSED NON-BILL",
					label: "CLOSED NON-BILL",
				},
				{
					value: "CLOSED ADJUSTMENT",
					label: "CLOSED ADJUSTMENT",
				},
				{
					value: "CLOSED HC-2nd Scrub",
					label: "CLOSED HC-2nd Scrub",
				},
			];

			return {
				options: options,
				hasMore: false,
			};
		} else if (this.props.type === "activites_statuses") {
			const options = [
				{
					value: "new",
					label: "New",
				},
				{
					value: "started",
					label: "Started",
				},
				{
					value: "completed",
					label: "Completed",
				},
				{
					value: "cancelled",
					label: "Cancelled",
				},
				{
					value: "1",
					label: "Billable",
				},
				{
					value: "2",
					label: "Non-Billable",
				},
				{
					value: "3",
					label: "Hospital Contract",
				},
			];

			return {
				options: options,
				hasMore: false,
			};
		} else if (this.props.type === "users") {
			return userService
				.getUsers({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((user) => ({
						value: user.id,
						label: `${user.name} ${user.last_name}`,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "insurances") {
			return insuranceService
				.getInsurances({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((insurance) => ({
						value: insurance.id,
						label: `${insurance.description}`,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "procedure_specialities") {
			return procedureSpecialityService
				.getSpecialities({ params: options })
				.then((response) => {
					const options = response.data.map((speciality) => ({
						value: speciality.id,
						label: speciality.name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "surgeon_specialities") {
			return specialityService
				.getSpecialities({ params: options })
				.then((response) => {
					const options = response.data.map((speciality) => ({
						value: speciality.id,
						label: speciality.name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "modules") {
			return moduleService
				.getModules({ params: options })
				.then((response) => {
					const options = response.data.map((module) => ({
						value: module.id,
						label: module.module_name,
						isAddAccess: module.isAddAccess,
						isUpdateAccess: module.isUpdateAccess,
						isDeleteAccess: module.isDeleteAccess,
					}));

					return {
						options: options,
						hasMore: false,
					};
				});
		} else if (this.props.type === "app_modules") {
			return moduleService
				.getAppModules({ params: options })
				.then((response) => {
					const options = response.data.map((module) => ({
						value: module.id,
						label: module.module_name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (
			this.props.type === "knowledgebase_categories_with_sub_categories"
		) {
			options["isForDropDown"] = true;
			return knowledgeBaseCategoryService
				.getCategories({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((category) => ({
						value: category.id + "_" + category.sub_category_id,
						label:
							category.category + " > " + category.sub_category,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "knowledgebase_categories") {
			return knowledgeBaseCategoryService
				.getCategories({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((procedure) => ({
						value: procedure.id,
						label: procedure.category,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "knowledgebase_sub_categories") {
			if (this.props.selectedCategory && this.props.selectedCategory.id) {
				options["categoryId"] = this.props.selectedCategory.id;
			}
			return knowledgeBaseSubCategoryService
				.getSubCategories({ params: options }, this.cancel.token)
				.then((response) => {
					const options = response.data.map((procedure) => ({
						value: procedure.id,
						label: procedure.sub_category,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		} else if (this.props.type === "tenant_groups") {
			const fetchApi = () =>
				new Promise((resolve, reject) => {
					const loggedInUser = localStorage.getItem("LOGGED_IN_USER");
					const userdata = loggedInUser
						? JSON.parse(loggedInUser)
						: null;

					axios
						.get(
							`${API_URL}api/1.0.0/subscriber/${userdata.id}/groups`,
							{
								headers: {
									"Content-Type": "application/json",
									"X-JWT-Assertion": localStorage.getItem("token"),
								},
							}
						)
						.then((data) => {
							resolve(data);
						})
						.catch((data) => {
							reject(data);
						});
				});
			return fetchApi()
				.then((response) => {
					console.log("response", response);
					const options = response.data.data.groupsDAOS.map(
						(group) => ({
							value: group.id,
							label: group.groupName,
						})
					);

					return {
						options: options,
						hasMore: false,
					};
				})
				.catch((e) => {
					console.log("err");
				});
		} else if (this.props.type === "groups") {
			console.log("groups");
			return groupService
				.getGroups({ params: options })
				.then((response) => {
					const options = response.data.map((group) => ({
						value: group.id,
						label: group.group_name,
					}));

					return {
						options: options,
						hasMore:
							response.records >
							prevOptions.length + RECORDS_PER_PAGE,
					};
				});
		}
	};

	render() {
		return (
			<>
				{!this.props.hideLabel && (
					<label>
						{this.typeProperties[this.props.type].label}
						{this.props.isRequired && (
							<span className='required'> *</span>
						)}
					</label>
				)}
				<AsyncPaginate
					placeholder={
						"Select " + this.typeProperties[this.props.type].label
					}
					loadOptions={this.loadEntities}
					isMulti={this.props.isMulti}
					className={this.props.className}
					isDisabled={this.props.isDisabled}
					defaultOptions={[
						{
							value: "",
							label:
								"Select " +
								this.typeProperties[this.props.type].label,
						},
					]}
					value={this.props.selectedValue}
					onChange={(value) => this.props.onChange(value)}
				/>
			</>
		);
	}
}

export default withRouter(CustomDropDown);
