import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import ActivityFilter from "./activities/ActivityFilter";
import PaginatedDropdown from "../components/common/ui/PaginatedDropdown";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken } from "../opl-utilities/helpers";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import FacilityFilter from "./facilities/FacilityFilter";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import PopUpModal from "../components/common/PopUpModal";

const jsonToQueryParams = (json) => {
    const queryParams = Object.keys(json)
        .filter((key) => json[key] !== null && json[key] !== "")
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join("&");

    return queryParams;
};
const getRandomColor = () => {
    const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};
const Facilities = ({ history, location, activePage }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
    const pageSize = new URLSearchParams(location.search).get("pageSize") || 12;
    const { addToastMessage } = useUI();
    const queryParams = new URLSearchParams(location.search);
    const permissions = decodeModuleAccessFromToken("facilities");
    const [isOpen, setIsOpen] = useState(false);
    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);

    const resetFilters = () => {
        updateQueryParam("search", "");
        updateQueryParam("pageNo", 0);
    };
    const updateQueryParam = (key, value) => {
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };

    const callListApi = async () => {
        setLoading(true);
        try {
            const query = jsonToQueryParams({
                search: queryParams.get("search") || "",
            });
            const resp = await fetch(
                `${API_URL}/api/1.0.0/facility?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token"),
                    },
                }
            );

            if (resp.ok) {
                const response = await resp.json();
                if (response.result.responseCode === 200) {
                    setData(response.data);
                } else {
                    setData(null);
                }
            } else {
                setData(null);
            }
        } catch (e) {
            console.error("Error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        callListApi();
    }, [location.search]);

    const handleDelete = async (id) => {
        try {
            if (!id) {
                return false;
            }
            const response = await fetch(
                API_URL + `/api/1.0.0/facility/change-status/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 200) {
                callListApi();
                addToastMessage({
                    status: true,
                    message: `Facility deleted successfully.`,
                });
                return true;
            }

            addToastMessage({
                status: false,
                message: `Something went wrong while deleting procedure. Please try again.`,
            });

            return false;
        } catch (error) {
            addToastMessage({
                status: false,
                message: `Something went wrong while deleting procedure. Please try again.`,
            });
            return false;
        }
    };
    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
        }));
    return (
        <section id="opl_coremax">
            <section className="main-section">
                <Header active={activePage} location={location} />
                <section id="activity-v2">
                    <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
                        <h5 className="page-active color-primary f-w-700">
                            Facilities
                        </h5>
                        {permissions?.isCreateAllowed && (
                            <ButtonTextIcon
                                title="Add New"
                                className="mar-l-a"
                                onClick={() => history.push("/add-facility")}
                                icon={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19.995"
                                        height="19.995"
                                        viewBox="0 0 19.995 19.995"
                                    >
                                        <path
                                            d="M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z"
                                            transform="translate(-2.005 -2)"
                                        />
                                    </svg>
                                }
                            />
                        )}
                        &nbsp;
                    </div>
                    <FacilityFilter resetFilters={resetFilters} />
                    <br />
                    <br />
                    <div>
                        {data && !loading && (
                            <div
                                className="flex center-left"
                                style={{
                                    padding: "10px 15px 10px 15px",
                                    gap: "12px",
                                }}
                            >
                                <p className="f-w-300" style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
                    {loading ? (
                        <section
                            style={{
                                height: "100vh",
                                paddingBottom: "20vh",
                                backgroundColor: "#fafaff",
                            }}
                            className="flex center-center"
                        >
                            <LoaderIcon style={{ height: "32px", width: "32px" }} />
                        </section>
                    ) : data?.facilityList.length ? (
                        <div className="rs-table-holder">
                            <table className="rs-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Contact</th>
                                        <th>Address</th>
                                        <th>Phone</th>
                                        <th>Fax</th>
                                        <th>Npi</th>
                                        <th style={{ textAlign: 'center' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data &&
                                        data.facilityList.map((data, index) => (
                                            <tr key={index}>
                                                <td>{pageNo * pageSize + index + 1}</td>
                                                <td>{data.facilityName}</td>
                                                <td>{data.address}</td>
                                                <td>{data.contactPhone}</td>
                                                <td>{data.contactFax}</td>
                                                <td>{data.npi}</td>
                                                <td>
                                                    <div
                                                        className="flex center-center"
                                                        style={{ gap: "12px" }}
                                                    >
                                                        {permissions?.isReadAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">

                                                                    <CustomModal
                                                                        selector={
                                                                            <ButtonTertiary
                                                                                actionType='view'
                                                                                title='View'
                                                                            />
                                                                        }
                                                                        content={
                                                                            <FacilityDetailsContent
                                                                                id={data?.id}
                                                                                history={history}
                                                                            />
                                                                        }
                                                                    />
                                                            </div>
                                                            
                                                        )}
                                                        {permissions?.isUpdateAllowed && (
                                                            <div>
                                                                <NavLink
                                                                    to={{
                                                                        pathname: "/edit-facility",
                                                                        state: { id: data.id },
                                                                    }}
                                                                >
                                                                    <ButtonTertiary
                                                                        actionType="edit"
                                                                        title="Edit"
                                                                    />
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {permissions?.isDeleteAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                                                <CustomModal
                                                                    selector={
                                                                        <ButtonTertiary
                                                                            actionType="delete"
                                                                            title="Delete"
                                                                        />
                                                                    }
                                                                    content={
                                                                        <ConfirmationModal
                                                                            onConfirm={async () => {
                                                                                await handleDelete(data.id);
                                                                                return true;
                                                                            }}
                                                                            message="Are you sure you want to delete this facility ?"
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <section
                            style={{
                                height: "80vh",
                                paddingBottom: "20vh",
                                backgroundColor: "#fff",
                            }}
                            className="flex center-center"
                        >
                            <p>No records found.</p>
                        </section>
                    )}
                    <div>
                        {data && !loading && (
                            <div
                                className="flex center-center"
                                style={{
                                    padding: "10px 15px 10px 15px",
                                    gap: "12px",
                                }}
                            >
                                <p className="f-w-300" style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </section>
        </section>
    );
};


const FacilityDetailsContent = ({ setShow, id, status, history }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // Decode permissions for the "facilities" module
    const permissions = decodeModuleAccessFromToken("facilities");

    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);

    const InfoItem = ({ icon, label, name, ...rest }) => {
        return (
            <div {...rest}>
                <div className='flex center-left' style={{ gap: "10px" }}>
                    <div>{icon}</div>
                    <div style={{ overflow: "hidden" }}>
                        <p
                            className='f-w-300'
                            style={{
                                color: "#aaaaaa",
                                fontSize: "10px",
                                lineHeight: 1,
                                paddingBottom: "2px",
                            }}>
                            {label}
                        </p>
                        <p
                            style={{
                                width: "200px",
                                lineHeight: 1,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}>
                            {name}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const fetchFacilityData = async (id) => {
        try {
            const resp = await fetch(
                API_URL +
                `/api/1.0.0/facility/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
            );
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data);
                    setLoading(false);
                }
            }
        } catch (e) {
            setError("Failed to fetch facility data.");
            setLoading(false);
            console.log("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) fetchFacilityData(id);
    }, [id]);

    return (
        <section id='opl_coremax'>
            <section id='custom-modal'>
                <div className='inner'>
                    <div className='box' id='activity-detail-box'>
                        <div className='flex center-left'>
                            <div>
                                <h6 className='color-primary f-w-300'>
                                    Facility Details
                                </h6>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <ButtonLightTextIcon
                                    title='Cancel'
                                    onClick={() => setShow(false)}
                                    icon={
                                        <svg
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="2"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width='19.995'
                                            height='19.996'
                                        >
                                            <path
                                                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                                fillRule="nonzero" 
                                            />
                                        </svg>
                                    }
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                height: "60vh",
                                margin: "10px",
                                overflowY: "scroll",
                                padding: "20px 5px",
                                overflowX: "hidden",
                                scrollbarWidth: "thin",
                            }}>
                            {loading && (
                                <div
                                    className='col-1-1'
                                    style={{
                                        height: "40px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <LoaderIcon
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                            )}
                            {!loading && error && (
                                <p
                                    className='f-w-300 color-primary'
                                    style={{ color: "#c0392b" }}>
                                    {error}
                                </p>
                            )}
                            {data && !loading && !error && (
                                <div className='activity-card pos-rel'>
                                    <div
                                        style={{
                                            position: "absolute",
                                            content: "",
                                            height: "4px",
                                            left: "-1px",
                                            right: "-1px",
                                            bottom: "-2px",
                                            borderBottomLeftRadius: "4px",
                                            borderBottomRightRadius: "4px",
                                        }}></div>
                                    <div
                                        className='col-1-1 flex center-left'
                                        style={{
                                            gap: "10px",
                                            paddingBottom: "5px",
                                        }}>
                                        <h5 className='f-w-700'>
                                            <span className='f-w-300'>#</span>
                                            &nbsp;
                                            {data?.id}
                                        </h5>
                                        <hr
                                            style={{
                                                outline: "none",
                                                border: "none",
                                                height: "16px",
                                                width: "2px",
                                                background: "#EBEBEB",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            padding: "12px 0 12px 0 ",
                                        }}>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"Facility"}
                                                name={
                                                    data?.facilityName
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                        data-id='72'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='3'></circle>
                                                    </svg>
                                                }
                                                label={"Address"}
                                                name=
                                                {data?.address || "NA"}
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='28'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
                                                        <path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
                                                    </svg>
                                                }
                                                label={"Facility Type"}
                                                name={
                                                    data?.facilityType
                                                    || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M4.8 2.3A.3.3 0 1 0 5 2H4a2 2 0 0 0-2 2v5a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V4a2 2 0 0 0-2-2h-1a.2.2 0 1 0 .3.3'></path>
                                                        <path d='M8 15v1a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6v-4'></path>
                                                        <circle
                                                            cx='20'
                                                            cy='10'
                                                            r='2'></circle>
                                                    </svg>
                                                }
                                                label={"NPI"}
                                                name={
                                                    data?.npi || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="23"
                                                        height="23"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    >
                                                        <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                                                    </svg>
                                                }
                                                label={"Email"}
                                                name={
                                                    data?.contactEmail || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"GPO"}
                                                name={
                                                    data?.gpo || "NA"
                                                }
                                            />

                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z'></path>
                                                        <path d='M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5'></path>
                                                        <path d='M4 15v-3a6 6 0 0 1 6-6h0'></path>
                                                        <path d='M14 6h0a6 6 0 0 1 6 6v3'></path>
                                                    </svg>
                                                }
                                                label={"IDN"}
                                                name={
                                                    data?.idn || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                        data-id='72'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='3'></circle>
                                                    </svg>
                                                }
                                                label={"Place of Service"}
                                                name={
                                                    data?.facilityPlaceOfService || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-flag h-4 w-4 text-muted-foreground'
                                                        data-id='76'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z'></path>
                                                        <line
                                                            x1='4'
                                                            x2='4'
                                                            y1='22'
                                                            y2='15'></line>
                                                    </svg>
                                                }
                                                label={"EMR Access"}
                                                name={
                                                    data?.facilityEmrAccess || "NA"
                                                }
                                            />
                                        </div>

                                        <hr
                                            style={{
                                                marginTop: "10px",
                                                paddingBottom: "10px",
                                            }}
                                        />
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"Facility Additional Contact:"}
                                            name={
                                            	data?.facilityAdditionalContact || "NA"
                                            }
                                            />

                                            <InfoItem
												className='col-1-1 info-row-column'
												icon={
                                                    <svg
                                                    color={getRandomColor()}
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                    }}>
                                                    <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                    <circle
                                                        cx='12'
                                                        cy='10'
                                                        r='4'></circle>
                                                    <circle
                                                        cx='12'
                                                        cy='12'
                                                        r='10'></circle>
                                                </svg>
												
												}
												label={"Internal Identifier"}
												name={
                                                    data?.internalIdentifier || "NA"
                                                }
											/>
										</div>
										<div
											className='info-row flex center-center'
											style={{
												gap: "10px",
												paddingBottom: "10px",
											}}>
											<InfoItem
												className='col-1-1 info-row-column'
												icon={
                                                    <svg
                                                    color={getRandomColor()}
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                    }}>
                                                    <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                    <circle
                                                        cx='12'
                                                        cy='10'
                                                        r='4'></circle>
                                                    <circle
                                                        cx='12'
                                                        cy='12'
                                                        r='10'></circle>
                                                </svg>
												}
												label={"Case Capacity"}
												name={
                                                    data?.caseCapacity || "NA"
                                                }
											/>
                                        </div>

                                        <hr
                                            style={{
                                                marginTop: "10px",
                                                paddingBottom: "10px",
                                            }}
                                        />

                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "20px",
                                            }}>
                                            <div className='col-1-1 info-row-column'>
                                                <div
                                                    className='flex center-left'
                                                    style={{ gap: "10px" }}>
                                                    <div>
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            className='lucide lucide-info h-4 w-4 text-muted-foreground'
                                                            data-id='30'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                            <path d='M12 16v-4'></path>
                                                            <path d='M12 8h.01'></path>
                                                        </svg>
                                                    </div>
                                                    <div
                                                        style={{
                                                            overflow: "hidden",
                                                        }}>
                                                        <p
                                                            className='f-w-300'
                                                            style={{
                                                                color: "#aaaaaa",
                                                                fontSize:
                                                                    "10px",
                                                                lineHeight: 1,
                                                                paddingBottom:
                                                                    "2px",
                                                            }}>
                                                            Note
                                                        </p>
                                                        <p
                                                            style={{
                                                                whiteSpace:
                                                                    "normal",
                                                                overflowWrap:
                                                                    "break-word",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                                width: "100%",
                                                            }}>
                                                            {data
                                                                ?.patientActivityDAO
                                                                ?.event_notes ||
                                                                "NA"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
							{permissions?.isUpdateAllowed &&
								<NavLink
									to={
										data?.id
											? {
												pathname: "/edit-facility",
												state: {
													id: data?.id,
												},
											}
											: "#"
									}>
									<ButtonLightTextIcon
										title='Edit Details'
										icon={
											<svg
												width='15'
												height='15'
												style={{
													height: "15px",
													width: "15px",
												}}
												viewBox='0 0 24 24'>
												<path
													fillRule='evenodd'
													clipRule='evenodd'
													d='M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z'
													fill='#283f54'
												/>
											</svg>
										}
									/>
								</NavLink>
							}
						</div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default withRouter(Facilities);
