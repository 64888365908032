import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import {
  decodeModuleAccessFromToken,
  formatServerDate,
  getUserTypeInfo,
} from "../opl-utilities/helpers";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import UserFilter from "./setupuser/UserFilter";
import DefaultUserImage from "../static/default-image.png";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import PopUpModal from "../components/common/PopUpModal";
import UserView from "./setupuser/ViewUser";

const jsonToQueryParams = (json) => {
  const queryParams = Object.keys(json)
    .filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
    .join("&");

  return queryParams;
};

const SetUpUsers = ({ history, location, activePage }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
  const pageSize = new URLSearchParams(location.search).get("pageSize") || 12;
  const queryParams = new URLSearchParams(location.search);
  const { addToastMessage } = useUI();
  const [viewActivity, setViewActivity] = useState(false);
  const [ueserid, setueserid] = useState(null);
  const [editButton, setEditButton] = useState(false);

  // Decode permissions for the "users" module
  const permissions = decodeModuleAccessFromToken("users");

  // Redirect to login if no permissions
  useEffect(() => {
    if (!permissions) {
      history.push("/login");
    }
  }, [permissions, history]);

  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("userType", "");
    updateQueryParam("pageNo", 0);
  };
  const updateQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting user. Please try again.`,
        });
        return false;
      }
      const response = await fetch(API_URL + `/api/1.0.0/users/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        await callListApi();
        addToastMessage({
          status: true,
          message: `User deleted successfully.`,
        });
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting user. Please try again.`,
        });
      }
      return true;
    } catch (error) {
      console.error("Error deleting patient:", error);
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting user. Please try again.`,
      });
      return false;
    }
  };

  const callListApi = async () => {
    setLoading(true);
    const query = jsonToQueryParams({
      search: queryParams.get("search") || "",
      userType: queryParams.get("userType") || "",
    });
    try {
      const resp = await fetch(
        `${API_URL}/api/1.0.0/users/list?pageSize=${pageSize}&pageNo=${pageNo}&${query}&showArchivedUsers=hide&sortOrder=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );

      if (resp.ok) {
        const response = await resp.json();
        if (response.result.responseCode === 200) {
          setData(response.data);
        } else {
          setData(null);
        }
      } else {
        setData(null);
      }
    } catch (e) {
      console.error("Error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    callListApi();
  }, [location.search]);
  return (
    <section id="opl_coremax">
      <section className="main-section">
        <Header active={activePage} location={location} />
        <section id="activity-v2">
          <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
            <h5 className="page-active color-primary f-w-700">Users</h5>
            {permissions?.isCreateAllowed && (
              <ButtonTextIcon
                title="Add New"
                className="mar-l-a"
                onClick={() => history.push("/add-user")}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.995"
                    height="19.995"
                    viewBox="0 0 19.995 19.995"
                  >
                    <path
                      d="M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z"
                      transform="translate(-2.005 -2)"
                    />
                  </svg>
                }
              />
            )}
            &nbsp;
          </div>
          <UserFilter
            resetFilters={() => {
              resetFilters();
            }}
          />
          <br />

          <div>
            {data && !loading && (
              <div
                className="flex center-left"
                style={{
                  padding: "10px 15px 10px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
          {loading ? (
            <section
              style={{
                height: "100vh",
                paddingBottom: "20vh",
                backgroundColor: "#fafaff",
              }}
              className="flex center-center"
            >
              <LoaderIcon style={{ height: "32px", width: "32px" }} />
            </section>
          ) : data?.records.length ? (
            <div className="rs-table-holder">
              <table className="rs-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Activated on</th>
                    <th>Expires at</th>
                    <th>Notes</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.records.map((data, index) => (
                      <tr key={index}>
                        <td>{pageNo * pageSize + index + 1}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              src={data.image || DefaultUserImage}
                              alt={data.fullName}
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                            <span>{data.fullName}</span>
                          </div>
                        </td>
                        <td>{data.email}</td>
                        <td>{getUserTypeInfo(data.type)?.label || "--"}</td>
                        <td>
                          {data.activeStart
                            ? formatServerDate(data.activeStart)
                            : "--"}
                        </td>
                        <td>
                          {data.activeEnd
                            ? formatServerDate(data.activeEnd)
                            : "--"}
                        </td>
                        <td>{data.notes}</td>
                        <td>
                          <div
                            className="flex center-center"
                            style={{ gap: "4px" }}
                          >
                            {permissions?.isReadAllowed /* Later add view pop up as well... */ && (
                              <div
                                onClick={() => {
                                  setueserid(data.id);
                                  setViewActivity(true);
                                  setEditButton(permissions?.isUpdateAllowed && data.type !== "assistant" &&  data.type !== "Physician Assistant");
                                }}
                              >
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              </div>
                            )}
                           

                            {permissions?.isDeleteAllowed && (
                              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      actionType="delete"
                                      title="Delete"
                                    />
                                  }
                                  content={
                                    <ConfirmationModal
                                      onConfirm={async () => {
                                        await handleDelete(data.id);
                                        return true;
                                      }}
                                      message="Are you sure you want to delete this user?"
                                    />
                                  }
                                />
                              </div>
                            )}
                             {permissions?.isUpdateAllowed &&
                              data.type !== "assistant" &&
                              data.type !== "Physician Assistant" && (
                                <NavLink
                                  to={{
                                    pathname: "/edit-user",
                                    state: {
                                      id: data.id,
                                    },
                                  }}
                                >
                                  <ButtonTertiary
                                    actionType="edit"
                                    title="Edit"
                                  />
                                </NavLink>
                              )}
                            
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <section
              style={{
                height: "80vh",
                paddingBottom: "20vh",
                backgroundColor: "#fff",
              }}
              className="flex center-center"
            >
              <p>No records found.</p>
            </section>
          )}
          <div>
            {data && !loading && (
              <div
                className="flex center-center"
                style={{
                  padding: "24px 15px 32px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
        </section>
      </section>
      {viewActivity && (
        <PopUpModal>
          <UserView
            history={history}
            ueserid={ueserid}
            setViewActivity={setViewActivity}
            redirectUrl="/users"
            editbutton={editButton}
          />
        </PopUpModal>
      )}
    </section>
  );
};
export default withRouter(SetUpUsers);
