import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import RegionsFilter from "./regions/TableFilter";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";

const jsonToQueryParams = (json) => {
  const queryParams = Object.keys(json)
    .filter((key) => json[key] !== null && json[key] !== "")
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
    .join("&");

  return queryParams;
};
const RegionPage = ({ history, location, activePage }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;

  const search = queryParams.get("search") || "";

  const { addToastMessage } = useUI();

  const resetFilters = () => {
    updateQueryParam("pageNo", 0);
    updateQueryParam("search", "");
  };

  const getCategoryApi = async () => {
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        name: search,
      });
      const resp = await fetch(
        `${API_URL}/api/1.0.0/region?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      console.log("token=:", localStorage.getItem("token"));
      if (resp.ok) {
        const response = await resp.json();
        if (response) {
          setData(response.data);
        }
        else {
          setData(null);
        }
      }
      else {
        setData(null);
      }

    } catch (e) {
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };



  const updateQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };
  const handleDelete = async (id) => {
    // setLoading(true);
    // try {
    //     const resp = await fetch(`${API_URL}/api/1.0.0/region/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //         "Content-Type": "application/json",
    //         "X-JWT-Assertion": localStorage.getItem("token"),
    //         },
    //     });
    //     if (resp.ok) {
    //         const response = await resp.json();
    //         if (response) {
    //         console.log(response);
    //         }
    //     }
    //     getCategoryApi();
    // } catch (e) {
    //     console.log("Error:", e);
    // } finally {
    //     setLoading(false);
    // }
  };

  useEffect(() => {
    getCategoryApi();
  }, [location.search]);

  return (
    <section id="opl_coremax">
      <section className="main-section">
        <Header active={activePage} location={location} />
        <section id="category">
          <div className="col-1-1 flex center-left pad-0-10">
            <h5 className="page-active color-primary f-w-700">Regions</h5>
            <ButtonTextIcon
              title="Add New"
              className="mar-l-a"
              onClick={() => history.push("/add-region")}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.995"
                  height="19.995"
                  viewBox="0 0 19.995 19.995"
                >
                  <path
                    d="M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z"
                    transform="translate(-2.005 -2)"
                  />
                </svg>
              }
            />
          </div>
          <RegionsFilter resetFilters={resetFilters} />
          <div>
            {data && !loading && (
              <div
                className="flex center-left"
                style={{
                  padding: "10px 15px 10px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
          {loading ? (
            <section
              className="flex center-center"
              style={{ height: "100vh", backgroundColor: "#fafaff" }}
            >
              <LoaderIcon style={{ height: "32px", width: "32px" }} />
            </section>
          ) : (
            <div className="rs-table-holder">
              <table className="rs-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Region Name</th>
                    <th>Email</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.regionList.map((item, index) => (
                    <tr key={item.id}>
                      <td>{pageNo * pageSize + index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>
                        <div
                          className="flex center-center"
                          style={{ gap: "12px" }}
                        >
                          <div>
                            <NavLink
                              to={{
                                pathname: "/edit-region",
                                search: `?id=${item.id}`,
                              }}
                            >
                              <ButtonTertiary
																	actionType='edit'
																	title='Edit'
																/>
                            </NavLink>
                          </div>
                          <div className="color-secondary transition hover-color-primary cursor-pointer ">
                            <CustomModal
                              selector={
                                <ButtonTertiary
																			actionType='delete'
																			title='Delete'
																		/>
                              }
                              content={
                                <ConfirmationModal
                                  onConfirm={async () => {
                                    await handleDelete(data.id);
                                    return true;
                                  }}
                                  message="Are you sure you want to delete this group?"
                                />
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div>
            {data && !loading && (
              <div
                className="flex center-center"
                style={{
                  padding: "24px 15px 32px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
        </section>
      </section>
    </section>
  );
};

export default withRouter(RegionPage);
