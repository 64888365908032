import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import {
	API_URL,
} from "../../opl-utilities/constants";
import { withRouter } from "react-router-dom";

const Label = ({ title }) => {
	return (
		<p
			className='color-primary f-w-300 l-h-1'
			style={{ fontSize: "12px", paddingBottom: "6px" }}>
			&nbsp;{title}
		</p>
	);
};

const SchedulingFilter = ({ history, location, resetFilters }) => {
	const queryParams = new URLSearchParams(location.search);

	const handleQueryParamChange = (key, value) => {
		// queryParams.delete("id");
		queryParams.set("pageNo", 0);
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	const DROPDOWNS = [ 
		{
			title: "Regions",
			url: "/reference/regions",
			param: "company",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o.name}`,
			placeholder: "Search region",
		},
		{
			title: "Assistants",
			url: "/reference/assistants",
			param: "assistant",
			mapFunc: mapOptions,
			labelFunc: (o) => o.fullName,
			placeholder: "Search assistant",
		},
		{
			title: "Event Type",
			url: "/reference/event-type",
			param: "eventType",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o.eventTypeName}`,
			placeholder: "Search event type",
		},
	];

	return (
		<div className='col-1-1 filters'>
			{DROPDOWNS.map(
				({ title, url, param, mapFunc, labelFunc, placeholder }) => (
					<div key={param}>
						<Label title={title} />
						<PaginatedDropdown
							url={`${API_URL}/api/1.0.0${url}?`}
							selectedValue={
								queryParams.get(param)
									? JSON.parse(queryParams.get(param))
									: null
							}
							onChange={(v) =>
								handleQueryParamChange(
									param,
									v ? JSON.stringify(v) : null
								)
							}
							mapOptions={(records) =>
								mapFunc(records, "id", labelFunc)
							}
							placeHolder={placeholder}
						/>
					</div>
				)
			)} 
			<div>
				<Label title='Start Date' />
				<input
					className='primary'
					type='date'
					value={queryParams.get("startDate") || ""}
					onChange={(v) =>
						handleQueryParamChange("startDate", v.target.value)
					}
					// max={queryParams.get("endDate") || ""}
				/>
			</div>
			<div>
				<Label title='End Date' />
				<input
					className='primary'
					type='date'
					value={queryParams.get("endDate") || ""}
					onChange={(v) =>
						handleQueryParamChange("endDate", v.target.value)
					}
					// min={queryParams.get("startDate") || ""}
				/>
			</div>
			<div className='flex center-left' style={{ gap: "12px" }}>
				<div>
					<Label title='Reset' />
					<ButtonLightTextIcon
						title='Reset'
						className='light'
						icon={
							<svg
								width='800px'
								height='800px'
								viewBox='0 0 512 512'>
								<path d='M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z' />
							</svg>
						}
						onClick={resetFilters}
					/>
				</div> 
			</div>
		</div>
	);
};

export default withRouter(SchedulingFilter);
