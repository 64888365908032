import "../styles/base.scss";
import "../styles/pages.scss";
import React, { useEffect, useState } from "react";
import Header from "../components/common/ui/Header";
import { NavLink, withRouter } from "react-router-dom";
import { API_URL } from "../opl-utilities/constants";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import SchedulingFilter from "./scheduling/SchedulingFilter";

const localizer = momentLocalizer(moment);

const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");
	return queryParams;
};

const SingleEvent = ({ event }) => {
	const getStatusColor = (str) => {
		switch (str) {
			case "new":
				return "rbc-event-single-new-event";
			case "started":
				return "rbc-event-single-started-event";
			case "cancelled":
				return "rbc-event-single-cancelled-event";
			case "completed":
				return "rbc-event-single-completed-event";
			default:
				return "";
		}
	};

	return (
		<div
			tabIndex='0'
			className={`rbc-event-single ${getStatusColor(event.status)}`}>
			<NavLink
				to={{
					pathname: "/edit-activity",
					state: {
						id: event.id,
					},
				}}>
				<div className='rbc-event-content'>{event.title}</div>
			</NavLink>
		</div>
	);
};

const SchedulingPage = ({ history, location, activePage }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [events, setEvents] = useState(null);
	const queryParams = new URLSearchParams(location.search);

	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

		// ====== FILTER QUERY PARAMS
 
		const startDate = queryParams.get("startDate") || "";
		const endDate = queryParams.get("endDate") || "";
		const company = queryParams.get("company") || null;
		const assistant = queryParams.get("assistant") || null;
		const eventType = queryParams.get("eventType") || null; 

		// ====== RESET FILTER
		const resetFilters = () => {
			updateQueryParam("eventType", "")
			updateQueryParam("startDate", "");
			updateQueryParam("endDate", "");
			updateQueryParam("company", "");
			updateQueryParam("assistant", ""); 
			updateQueryParam("pageNo", 0);
		};

	function formatDate(date) {
		if (validDate(date)) {
			date = typeof date === "string" ? date.replace(/-/g, "/") : date;
			var d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			if (month === "01" && day === "01" && year === 1970) return "";

			return [month, day, year].join("/");
		} else {
			return "";
		}
	}

	function validDate(date) {
		date = typeof date === "string" ? date.replace(/-/g, "/") : date;
		return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
	}

	const filteredArray = (arr) => {
		let tEvents = arr
			.filter((obj) => obj.patientActivityDAO.event_start_date)
			.map((event) => {
				let startDT = formatDate(
					event.patientActivityDAO.event_start_date
				);
				if (
					event.patientActivityDAO.event_start_time &&
					event.patientActivityDAO.event_start_time !== ""
				)
					startDT += " " + event.patientActivityDAO.event_start_time;
				startDT = new Date(startDT);

				let endDT = null;
				if (validDate(event.patientActivityDAO.event_end_date)) {
					endDT = formatDate(event.patientActivityDAO.event_end_date);
					if (
						event.patientActivityDAO.event_end_time &&
						event.patientActivityDAO.event_end_time !== ""
					) {
						endDT += " " + event.patientActivityDAO.event_end_time;
						endDT = new Date(endDT);
					}
				} else {
					endDT = startDT;
				}

				let title = event.patientActivityDAO.event_surgical_assitant
					? `${event.patientActivityDAO.event_surgical_assitant.name} ${event.patientActivityDAO.event_surgical_assitant.lastName}`
					: "";
				title += " (" + startDT.toLocaleTimeString("en-US");
				try {
					if (endDT)
						title += " - " + endDT?.toLocaleTimeString("en-US");
				} catch (ex) {}

				title += ")";

				return {
					start: startDT,
					end: endDT,
					title: title,
					id: event.patientActivityDAO.id,
					status: event.status,
				};
			});

		return tEvents;
	};

	const callApi = async () => {
		try {
			const query = jsonToQueryParams({
				fromDate:startDate,
				toDate:endDate, 
				companyId: company ? JSON.parse(company)?.value : null,
				assistantId: assistant ? JSON.parse(assistant)?.value : null, 
				eventType: eventType ? JSON.parse(eventType)?.value : null,
			});
			setLoading(true);
			const api = await fetch(
				API_URL +
					`/api/1.0.0/activity/status/incomplete?pageSize=100&pageNo=0&${query}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			const response = await api.json();
			if (response) {
				const f = filteredArray(response.data.records);
				console.log("f", JSON.stringify(f));
				setEvents(f);
			} else {
				setError("Something happened, shoot!");
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callApi();
	}, [location.search]);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section
					id='opl-scheduling'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
							Scheduling
						</h5>
					</div>  
					<SchedulingFilter resetFilters={resetFilters} /> 
					<br />
					{loading ? (
						<section
							style={{
								height: "100vh",
								paddingBottom: "20vh",
								backgroundColor: "#fafaff",
							}}
							className='flex center-center'>
							<LoaderIcon style={{ height: "32px", width: "32px" }} />
						</section>
					) : (
						<section
						style={{
							padding: "16px",
							fontFamily: '"Inter", sans-serif',
						}}>
							{events && (<Calendar
								popup
								selectable={false}
								localizer={localizer}
								defaultDate={new Date()}
								defaultView='month'
								events={events}
								components={{
									eventWrapper: SingleEvent,
								}}
								style={{
									height: "100vh",
								}}
							/>)}
						</section>
					)}
				</section> 
			</section>
		</section>
	);
};

export default withRouter(SchedulingPage);
