import { NavLink } from "react-router-dom";
import LogoImage from "../../../static/logo.png"; 
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { API_URL, APP_VERSION, MENU_LIST } from "../../../opl-utilities/constants";
import CustomModal from "../CustomModal";
import ConfirmationModal from "./ConfirmationModal";
import { decodeModuleAccessFromToken, getPBFUSAaccess } from "../../../opl-utilities/helpers";
import { useFetch } from "../../../hooks/useFetch";

const ProfileMenu = () => {
	const [show, setShow] = useState(false);

	return (
		<li className='cursor-pointer'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				onClick={() => setShow(!show)}>
				<path
					d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm7.753,18.305a2.371,2.371,0,0,0-1.871-1.241c-2.293-.529-4.428-.993-3.393-2.945C17.634,8.177,15.322,5,12,5,8.612,5,6.356,8.3,9.511,14.119c1.066,1.964-1.148,2.427-3.393,2.945A2.355,2.355,0,0,0,4.251,18.31a10,10,0,1,1,15.5-.005Z'
					fill='#293f54'
				/>
			</svg>
			{show && (
				<ul>
					<li className='cursor-pointer transition'>
						<NavLink to='/profile'>
							<div
								className='col-1-1 flex center-left'
								style={{ gap: "8px" }}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									style={{
										height: "12px",
										width: "auto",
										display: "block",
									}}>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z'
										fill='#283f54'
									/>
								</svg>
								View Profile
							</div>
						</NavLink>
					</li>
					<CustomModal
						selector={
							<li className='cursor-pointer transition'>
								<div
									className='col-1-1 flex center-left'
									style={{ gap: "8px" }}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										style={{
											height: "12px",
											width: "auto",
											display: "block",
										}}>
										<path
											d='M8 9v-4l8 7-8 7v-4h-8v-6h8zm6-7c-1.787 0-3.46.474-4.911 1.295l.228.2 1.395 1.221c1.004-.456 2.115-.716 3.288-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.284-.26-3.288-.715l-1.395 1.221-.228.2c1.451.82 3.124 1.294 4.911 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z'
											fill='#283f54'
										/>
									</svg>
									Log out
								</div>
							</li>
						}
						content={
							<ConfirmationModal
								onConfirm={async () => {
									localStorage.clear();
									window.location.href = "/login";
								}}
								message='Are you sure you want to log out of CoreMax? Once logged out, you will need to log in again.'
							/>
						}
					/>
				</ul>
			)}
		</li>
	);
};

const hasAccess = (module) => {
	let menuExists = false;
	const TOKEN = JSON.parse(localStorage.getItem("LOGGED_IN_USER"));
	if (TOKEN.groups_users) {
		TOKEN.groups_users.map((group) => {
			if (group.modules_groups) {
				let tIndex = group.modules_groups.findIndex(
					(t) => t.slug === module
				);
				if (tIndex >= 0) menuExists = true;
			}
		});
	}
	return menuExists;
};

const Single = ({ active, title, href }) => {
	return (
		<li className={`${active === href ? "active-page" : ""}`}>
			<NavLink className='transition' to={href}>
				<span className='flex center-left'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='10'
						height='10'
						viewBox='0 0 10 10'>
						<path
							className='transition'
							d='M5,.833A4.167,4.167,0,1,1,.833,5,4.171,4.171,0,0,1,5,.833ZM5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Zm.417,5V2.5H4.583V5.833H7.5V5Z'
						/>
					</svg>
					{title}
				</span>
			</NavLink>
		</li>
	);
};

const WithSubMenu = ({ nestedMenu, subMenuHead }) => {
	const [show, setShow] = useState(false);

	return (
		<li className='cursor-pointer' onClick={() => setShow(!show)}>
			<a className='transition'>
				<span className='flex center-left'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='10'
						height='10'
						viewBox='0 0 10 10'>
						<path
							className='transition'
							d='M5,.833A4.167,4.167,0,1,1,.833,5,4.171,4.171,0,0,1,5,.833ZM5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Zm.417,5V2.5H4.583V5.833H7.5V5Z'
						/>
					</svg>
					{subMenuHead}
					<svg
						className={`transition arr ${show ? "arr-open" : ""}`}
						xmlns='http://www.w3.org/2000/svg'
						width='8'
						height='5'
						viewBox='0 0 8 5'
						style={{
							marginLeft: "auto",
						}}>
						<path
							className='transition'
							d='M0,5.443.943,4.5,4,7.613,7.057,4.5,8,5.443,4,9.5Z'
							transform='translate(0 -4.5)'
						/>
					</svg>
				</span>
			</a>
			{show && (
				<ul>
					{nestedMenu.map((menu, index) => {
						return (
							// (hasAccess(menu.slug) || true) &&
							// index !== nestedMenu.length - 1 && (
								<li key={index}>
									<NavLink
										className='transition'
										to={{
											pathname: menu.slug,
											state: {
												activePage: menu.slug,
												toggleMenu: true,
												subMenuHead,
											},
										}}>
										{menu.title}
									</NavLink>
								</li>
							// )
						);
					})}
				</ul>
			)}
		</li>
	);
};

const Header = ({ location }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const permissions = decodeModuleAccessFromToken("knowledge-base");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		setMenuOpen(false);
	}, [location.pathname]);
 
	const userId = JSON.parse(localStorage.getItem("LOGGED_IN_USER"));
	const { data, error, loading } = useFetch(
				API_URL + "/api/1.0.0/profile/" + userId?.id,
		{ method: "GET" }
	);

	const getSubMenuCount = (nestedMenu) => {
		if (isArray(nestedMenu)) {
			return nestedMenu.filter((menu) => {
				return hasAccess(menu.slug);
			}).length;
		}
		return 0;
	};

	const renderNestedMenu = (nestedMenu, subMenuHead) => {
		return (
			<WithSubMenu nestedMenu={nestedMenu} subMenuHead={subMenuHead} />
		);
	};

	  const hasCreateAccess = (menu) => {
		// Check the current menu item
		const access = decodeModuleAccessFromToken(menu.slug);
		if (access && access.isCreateAllowed) return true;
	
		// Check sub-menu items recursively
		if (menu.sub_menu && menu.sub_menu.length > 0) {
		  return menu.sub_menu.some(hasCreateAccess);
		}
	
		return false;
	  };


	const renderMenu = (m) => {
		// if (!hasCreateAccess(m)) return null;
		if (m.sub_menu && m.sub_menu.length) {
			return (
				<>
					{renderNestedMenu(
						m.sub_menu,
						m.title
					)}
				</>
			);
		}
		return (
			<Single 
				active='/'
				href={m.slug}
				title={m.title}
			/>
		);
	};
	  
	return (
		<div
			className={`${menuOpen ? "sidebar-overlay" : ""}`}
			onClick={() => setMenuOpen(!menuOpen)}>
			<header
				className={`${menuOpen ? "menu-open" : ""}`}
				onClick={(e) => e.stopPropagation()}>
				<section className='top'>
					<div
						className={`menu-btn flex center-center ${
							menuOpen ? "menu-btn-open" : ""
						}`}
						onClick={() => setMenuOpen(!menuOpen)}>
						<div>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<div
						className='logo flex center-center'
						style={{
							gap: "12px",
						}}>
						<img src={LogoImage} />
						<div className='flex center-left'>
							<div>
								<p
									className='l-h-1 color-primary'
									style={{
										fontSize: "13px",
									}}>
									CoreMax Cloud
								</p>
								<p
									className='l-h-1'
									style={{
										fontSize: "8px",
										color: "rgb(143, 143, 143)",
									}}>
									Version v{APP_VERSION}
								</p>
							</div>
						</div>
					</div>
					<div className='right'>
						<div className='user'>
							<div className='t-a-r'>
								<p className='l-h-1'>
									<span
										style={{
											color: "#8f8f8f",
										}}>
										Hi,
									</span>{" "}
									<span className='color-primary'>
										{data?.data?.fullName || "Loading..."}
									</span>
								</p>
								<p
									className='l-h-1 t-t-u'
									style={{
										fontSize: "12px",
										color: "#8F8F8F",
									}}>
									{data?.data?.type || "Type not available"}
								</p>
							</div>
						</div>
						<ul className='actions flex center-center'>
							{/* <li className='cursor-pointer'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'>
									<path
										id='iconmonstr-brightness-11'
										d='M12,2A10,10,0,1,1,2,12,10.011,10.011,0,0,1,12,2Zm0-2A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,4a8,8,0,0,0,0,16Z'
										fill='#283f54'
									/>
								</svg>
							</li> */}
							{ permissions?.isReadAllowed &&  
								<li className='cursor-pointer'>
									<NavLink to='/knowledge-base' style={{
										borderRight : 0,
										padding: "auto"
									}}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'>
											<path
												id='iconmonstr-help-2'
												d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,18.25A1.25,1.25,0,1,1,13.25,17,1.25,1.25,0,0,1,12,18.25Zm1.961-5.928a2.641,2.641,0,0,0-.935,2.178H11.021a3.681,3.681,0,0,1,1.431-3.468c.573-.544,1.025-.975.962-1.821a1.323,1.323,0,0,0-1.365-1.226c-.709,0-1.538.527-1.538,2.013H8.5c0-2.4,1.409-3.95,3.59-3.95A3.528,3.528,0,0,1,14.641,7a3.13,3.13,0,0,1,.854,2.3,4.474,4.474,0,0,1-1.534,3.021Z'
												fill='#283f54'
											/>
										</svg>
									</NavLink>
								</li>
							}
							
							{/* <li className='cursor-pointer'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='24'
									viewBox='0 0 20 24'>
									<path
										id='iconmonstr-bell-1'
										d='M15.137,3.945A2.1,2.1,0,0,1,14.1,2.125v0a2.1,2.1,0,1,0-4.193,0v0a2.1,2.1,0,0,1-1.041,1.82C4.195,6.657,6.877,15.66,2,17.251V19H22V17.251C17.123,15.66,19.805,6.657,15.137,3.945ZM12,1a1,1,0,1,1-1,1A1,1,0,0,1,12,1Zm3,20a3.066,3.066,0,0,1-2.971,3A3.118,3.118,0,0,1,9,21Z'
										transform='translate(-2)'
										fill='#293f54'
									/>
								</svg>
							</li> */}
							<ProfileMenu />
						</ul>
					</div>
				</section>
			</header>
			<nav
				className={`${menuOpen ? "menu-open" : ""}`}
				onClick={(e) => e.stopPropagation()}>
				<ul>
				    {MENU_LIST.map((menu) => renderMenu(menu))} 
					{
						getPBFUSAaccess() && <WithSubMenu nestedMenu={
							[
								{
									title: "Patient Registrations",
									slug: "patient-registrations",
									icon: null,
									sub_menu: [],
								},
								{
									title: "Volunteer Registrations",
									slug: "volunteer-registrations",
									icon: null,
									sub_menu: [],
								},
							]
						} subMenuHead={"PBFUSA"} />
					}
					<li className='cursor-pointer transition'>
						<NavLink className='transition' to='/profile'>
							<span className='flex center-left'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									style={{
										height: "12px",
										width: "auto",
										display: "block",
									}}>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z'
										fill='#283f54'
									/>
								</svg>
								View Profile
							</span>
						</NavLink>
					</li>
					<CustomModal
						selector={
							<li className='cursor-pointer transition'>
								<a className='transition'>
									<span
										className='flex center-left'
										style={{ gap: "8px" }}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											style={{
												height: "12px",
												width: "auto",
												display: "block",
											}}>
											<path
												d='M8 9v-4l8 7-8 7v-4h-8v-6h8zm6-7c-1.787 0-3.46.474-4.911 1.295l.228.2 1.395 1.221c1.004-.456 2.115-.716 3.288-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.284-.26-3.288-.715l-1.395 1.221-.228.2c1.451.82 3.124 1.294 4.911 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z'
												fill='#283f54'
											/>
										</svg>
										Log out
									</span>
								</a>
							</li>
						}
						content={
							<ConfirmationModal
								onConfirm={async () => {
									if (localStorage.clear() === undefined) {
										window.location.href = "/login";
									}
								}}
								message='Are you sure you want to log out of CoreMax? Once logged out, you will need to log in again.'
							/>
						}
					/>
				</ul>
			</nav>
		</div>
	);
};

export default Header;
