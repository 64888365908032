import React from "react";
import { APP_ROUTES } from "../../constants/common";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { PrivateRoute } from "../../privateRoute";
import Login from "../ecosystems/Login";
import Register from "../ecosystems/Register";
import NotAuthorized from "../ecosystems/NotAuthorized";
import PageNotFound from "../ecosystems/404NotFound";
import ActivityDashboard from "../ecosystems/ActivityDashboard";
import PatientsDashboard from "../ecosystems/PatientsDashboard";
import ClaimsAndCollectionDashboard from "../ecosystems/ClaimsAndCollectionDashboard";
import SurgeonDashboard from "../ecosystems/SurgeonDashboard";
import FacilityDashboard from "../ecosystems/FacilityDashboard";
import NotificationDashboard from "../ecosystems/NotificationDashboard";
import RegionsDashboard from "../ecosystems/RegionsDashboard";
import FileDisplay from "../ecosystems/FileDisplay";
import AssistantDashboard from "../ecosystems/AssistantDashboard";
import UserDashboard from "../ecosystems/UserDashboard";
import ManageActivity from "../molecules/ManageActivity";
import ManageBatches from "../molecules/ManageBatches";
import ManageOrganization from "../molecules/ManageOrganization";
import ManagePatient from "../molecules/ManagePatient";
import ManageClaimDetail from "../molecules/ManageClaimDetail";
import ManageFacility from "../molecules/ManageFacility";
import ManageRegions from "../molecules/ManageRegions";
import ManageAssistant from "../molecules/ManageAssistant";
import ManageScanFlow from "../molecules/ManageScanFlow";
import ManageSurgeons from "../molecules/ManageSurgeons";
import ManagePaymentPosting from "../molecules/ManagePaymentPosting";
import ManagePosting from "../molecules/ManagePosting";
import Dashboard from "../ecosystems/Dashboard";
import ScanFlowDashboard from "../ecosystems/ScanFlowDashboard";
import BatchDashboard from "../ecosystems/BatchDashboard";
import ManageBilling from "../molecules/ManageBilling";
import ManageUsers from "../molecules/ManageUsers";
import ManageCaseLogs from "../molecules/ManageCaseLogs";
import ManageInvoice from "../molecules/ManageInvoice";
import ClaimInvoice from "../atom/ClaimInvoice";
import ClaimForm from "../atom/ClaimForm";
import CaseLogDashboard from "../ecosystems/CaseLogDashboard";
import InsuranceDashboard from "../ecosystems/InsuranceDashboard";
import ManageInsurance from "../molecules/ManageInsurance";
import PracticeDashboard from "../ecosystems/PracticeDashboard";
import ManagePractice from "../molecules/ManagePractice";
import ProcedureDashboard from "../ecosystems/ProcedureDashboard";
import OrganizationDashboard from "../ecosystems/OrganizationDashboard";
import ManageProcedure from "../molecules/ManageProcedure";
import AccountsDashboard from "../ecosystems/AccountsDashboard";
import AccountDetailDashboard from "../ecosystems/AccountDetailDashboard";
import Profile from "../ecosystems/Profile";
import InvoicingDashboard from "../ecosystems/InvoicingDashboard";
import ManageInvoicing from "../molecules/ManageInvoicing";
import EditInvoicing from "../molecules/EditInvoicing";
import RevenueSchedulerDashboard from "../ecosystems/RevenueSchedulerDashboard";
import LogsDashboard from "../ecosystems/LogsDashboard";
import ManageClients from "../molecules/ManageClients";
import ManageGroups from "../molecules/ManageGroups";
import ClientsDashboard from "../ecosystems/ClientsDashboard";
import GroupDashboard from "../ecosystems/GroupDashboard";
import PhysicianAssistantDashboard from "../ecosystems/PhysicianAssistantDashboard";
import ManagePhysicianAssistant from "../molecules/ManagePhysicianAssistant";
import KnowledgeBaseCategoryDashboard from "../ecosystems/KnowledgeBaseCategoryDashboard";
import ManageKnowledgeBaseCategory from "../molecules/ManageKnowledgeBaseCategory";
import KnowledgeBaseSubCategoryDashboard from "../ecosystems/KnowledgeBaseSubCategoryDashboard";
import ManageKnowledgeBaseSubCategory from "../molecules/ManageKnowledgeBaseSubCategory";
import KnowledgeBaseArticleDashboard from "../ecosystems/KnowledgeBaseArticleDashboard";
import ManageKnowledgeBaseArticle from "../molecules/ManageKnowledgeBaseArticle";
import ContractDashboard from "../ecosystems/ContractDashboard";
import ManageContract from "../molecules/ManageContract";
import SurgeonAssociationDashboard from "../ecosystems/SurgeonAssociationDashboard";
import ManageSurgeonAssociation from "../molecules/ManageSurgeonAssociation";
import BillsDashboard from "../ecosystems/BillsDashboard";
import InvoicingV1Dashboard from "../ecosystems/InvoicingV1Dashboard";
import FeedbackDashboard from "../ecosystems/FeedbackDashboard";
import DivisionDashboard from "../ecosystems/DivisionDashboard";
import ManageDivision from "../molecules/ManageDivision";
import SchedulingPage from "../../../pages/SchedulingPage";

// ================ New Ones

// === ApplicationLayout Component with Access Control ===
import ApplicationLayout from "../../../hoc/ApplicationLayout";

import ScanflowV2 from "../../../pages/ScanflowV2";
import LoginPage from "../../../pages/LoginPage";
import ActivityPage from "../../../pages/ActivityPage";
import AddActivityPage from "../../../pages/AddActivityPage";
import UpdateActivity from "../../../pages/UpdateActivity";
import Subscribers from "../../../pages/Subscribers";
import Transactions from "../../../pages/Transactions";
import AzureCallback from "../../../pages/AzureCallback";
import { UIProvider } from "../../../context/ui";
import GroupPage from "../../../pages/GroupPage"
import AddGroupPage from "../../../pages/AddGroupPage";
import UpdateGroupPage from "../../../pages/UpdateGroupPage";
import LoginAzureByPass from "../ecosystems/LoginAzureByPass";
import DivisionManagement from "../../../pages/DivisionManagement";
import AddDivision from "../../../pages/division/AddDivision";
import Facilities from "../../../pages/Facilities";
import AddFacility from "../../../pages/facilities/AddFacility";
import UpdateFacility from "../../../pages/facilities/UpdateFacility";
import SurgeonAssociationPage from "../../../pages/SurgeonAssociationPage";
import AddPatientPage from "../../../pages/AddPatientPage";
import UpdatePatientPage from "../../../pages/UpdatePatientPage";
import PatientPage from "../../../pages/PatientPage";
import AddInsurancePage from "../../../pages/AddInsurancePage";
import UpdateInsurancePage from "../../../pages/UpdateInsurancePage";
import InsurancePage from "../../../pages/InsurancePage";
import AddPracticePage from "../../../pages/AddPracticePage";
import PracticePage from "../../../pages/PracticePage";
import EditPracticePage from "../../../pages/EditPracticePage";
import SetUpUsers from "../../../pages/SetUpUsers";
import AddUser from "../../../pages/setupuser/AddUser";
import UpdateUser from "../../../pages/setupuser/UpdateUser";
import ArchivedUser from "../../../pages/ArchivedUser";

import AddPhysicianAssistant from "../../../pages/AddPhysicianAssistant";
import UpdatePhysicianAssistant from "../../../pages/UpdatePhysicianAssistant";
import PhysicianAssistantPage from "../../../pages/PhysicianAssistantPage";

import ProcedurePage from "../../../pages/ProcedurePage";
import AddProcedurePage from "../../../pages/AddProcedurePage";
import UpdateProcedurePage from "../../../pages/UpdateProcedurePage";
import UpdateSurgicalAssistant from "../../../pages/UpdateSurgicalAssistant";
import SurgicalAssistantPage from "../../../pages/SurgicalAssistantPage";
import AddSurgicalAssistant from "../../../pages/AddSurgicalAssistant";
import UpdateSurgeonPage from "../../../pages/UpdateSurgeonPage";
import SurgeonPage from "../../../pages/SurgeonPage";
import AddSurgeonPage from "../../../pages/AddSurgeonPage";
import UpdateRegionPage from "../../../pages/UpdateRegionPage";
import RegionPage from "../../../pages/RegionPage";
import AddRegionPage from "../../../pages/AddRegionPage";
import UpdateKnowledgeBaseCategoryPage from "../../../pages/UpdateKnowledgeBaseCategoryPage";
import AddKnowledgeBaseCategoryPage from "../../../pages/AddKnowledgeBaseCategoryPage";
import KnowledgeBaseCategoryPage from "../../../pages/KnowledgeBaseCategoryPage";
import UpdateKnowledgeBaseSubCategoryPage from "../../../pages/UpdateKnowledgeBaseSubCategoryPage";
import KnowledgeBaseSubCategoryPage from "../../../pages/KnowledgeBaseSubCategoryPage";
import AddKnowledgeBaseSubCategoryPage from "../../../pages/AddKnowledgeBaseSubCategoryPage";
import UpdateKnowledgeBasePage from "../../../pages/UpdateKnowledgeBasePage";
import KnowledgeBasePage from "../../../pages/KnowledgeBasePage";
import AddKnowledgeBasePage from "../../../pages/AddKnowledgeBasePage";
import ProfilePage from "../../../pages/ProfilePage";
import AddSurgeonAssociationPage from "../../../pages/AddSurgeonAssociationPage"; 
import PatientRegistration from "../../../pages/pbfusa/PatientRegistration";
import VolunteerRegistration from "../../../pages/pbfusa/VolunteerRegistration";

function App() {
	return (
		<UIProvider>
		<BrowserRouter>
			<Switch>
				{/* ===============================
				New Routes / Pages added - Start
				================================ */}

				{/* =============== UI-PROVIDER ============= */}

				<Route exact path='/login'>
					<LoginPage />
				</Route>

				<Route exact path='/auth/callback' >
					<AzureCallback />
				</Route>
 

				{/* =================== Scheduling  ================== */}

					<Route exact path='/profile'>
						<ApplicationLayout module='scheduling' checkAccess={["isReadAllowed"]}>
							<ProfilePage />
						</ApplicationLayout>
					</Route>

            	{/* =================== Scheduling  ================== */}

				{/* <Route exact path='/'>
						<ApplicationLayout module='scheduling' checkAccess={[ "isReadAllowed", "isCreateAllowed", "isUpdateAllowed"]}>
							<SchedulingPage />
						</ApplicationLayout>
				</Route>

				<Route exact path='/scheduling'>
						<ApplicationLayout module='scheduling' checkAccess={[ "isReadAllowed", "isCreateAllowed", "isUpdateAllowed"]}>
							<SchedulingPage />
						</ApplicationLayout>
				</Route> */}

				{/* =================== Activites  ================== */}

				<Route exact path='/edit-activity'>
					<ApplicationLayout module="activities" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateActivity />
					</ApplicationLayout>
				</Route>
				<Route exact path='/activities'>
					<ApplicationLayout module="activities" checkAccess={["isReadAllowed"]} >
						<ActivityPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-activity'>
					<ApplicationLayout module="activities" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddActivityPage />
					</ApplicationLayout>
				</Route>

				{/* =================== Scanflow  ================== */}

				<Route exact path='/scan-flow'>
					<ApplicationLayout module="scan-flow" checkAccess={["isReadAllowed", "isUpdateAllowed", "isCreateAllowed"]} >
						<ScanflowV2 />
					</ApplicationLayout>
				</Route>

				{/* =================== Group  ================== */}

				<Route exact path='/group-edit'>
					<ApplicationLayout module="groups" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateGroupPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/groups'>
					<ApplicationLayout module="groups" checkAccess={["isReadAllowed"]} >
						<GroupPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/group-add'>
					<ApplicationLayout module="groups" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddGroupPage />
					</ApplicationLayout>
				</Route>

				{/* =================== User ================== */}

				<Route exact path='/edit-user'>
					<ApplicationLayout module="users" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateUser />
					</ApplicationLayout>
				</Route>
				<Route exact path='/users'>
					<ApplicationLayout module="users" checkAccess={["isReadAllowed"]} >
						<SetUpUsers />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-user'>
					<ApplicationLayout module="users" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddUser />
					</ApplicationLayout>
				</Route>
				<Route exact path='/archived-users'>
					<ApplicationLayout module="users" checkAccess={["isReadAllowed", "isUpdateAllowed", "isCreateAllowed"]} >
						<ArchivedUser />
					</ApplicationLayout>
				</Route>

				{/* =================== Patient  ================== */}

				<Route exact path='/edit-patient'>
					<ApplicationLayout module="patients" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdatePatientPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/patients'>
					<ApplicationLayout module="patients" checkAccess={["isReadAllowed"]} >
						<PatientPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-patient'>
					<ApplicationLayout module="patients" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddPatientPage />
					</ApplicationLayout>
				</Route>

				{/* =================== Practices  ================== */}

				<Route exact path='/edit-practice'>
					<ApplicationLayout module="practices" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<EditPracticePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/practices'>
					<ApplicationLayout module="practices" checkAccess={["isReadAllowed"]} >
						<PracticePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-practice'>
					<ApplicationLayout module="practices" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddPracticePage />
					</ApplicationLayout>
				</Route>

				{/* =================== Insurance  ================== */}

				<Route exact path='/edit-insurance'>
					<ApplicationLayout module="insurance" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateInsurancePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/insurance'>
					<ApplicationLayout module="insurance" checkAccess={["isReadAllowed"]} >
						<InsurancePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-insurance'>
					<ApplicationLayout module="insurance" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddInsurancePage />
					</ApplicationLayout>
				</Route>

				{/* =================== Division  ================== */}

               <Route exact path='/edit-division'>
					<ApplicationLayout module="division" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<AddDivision />
					</ApplicationLayout>
				</Route>
				<Route exact path='/insurance'>
					<ApplicationLayout module="division" checkAccess={["isReadAllowed"]} >
						<DivisionManagement />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-division'>
					<ApplicationLayout module="division" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddDivision />
					</ApplicationLayout>
				</Route>

				{/* =================== Facilities  ================== */}

				<Route exact path='/edit-facility'>
					<ApplicationLayout module="facilities" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateFacility />
					</ApplicationLayout>
				</Route>
				<Route exact path='/facilities'>
					<ApplicationLayout module="facilities" checkAccess={["isReadAllowed"]} >
						<Facilities />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-facility'>
					<ApplicationLayout module="facilities" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddFacility />
					</ApplicationLayout>
				</Route>

				{/* =================== Surgeon Association  ================== */}

				<Route exact path='/surgeon-association'>
					<ApplicationLayout module="surgeon-association" checkAccess={["isReadAllowed"]} >
						<SurgeonAssociationPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-surgeon-association'>
					<ApplicationLayout module="surgeon-association" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddSurgeonAssociationPage />
					</ApplicationLayout>
				</Route>

				{/* =================== physicians assistance  ================== */}

                <Route exact path='/edit-physician-assistant'>
					<ApplicationLayout module="physicians_assistance" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdatePhysicianAssistant />
					</ApplicationLayout>
				</Route>
				<Route exact path='/physicians_assistance'>
					<ApplicationLayout module="physicians_assistance" checkAccess={["isReadAllowed"]} >
						<PhysicianAssistantPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-physician-assistant'>
					<ApplicationLayout module="physicians_assistance" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddPhysicianAssistant />
					</ApplicationLayout>
				</Route> 

				{/* =================== physicians assistance  ================== */}

				<Route exact path='/edit-assistant'>
					<ApplicationLayout module="surgical_assistance" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
					<UpdateSurgicalAssistant />
					</ApplicationLayout>
				</Route>
				<Route exact path='/surgical_assistance'>
					<ApplicationLayout module="surgical_assistance" checkAccess={["isReadAllowed"]} >
						<SurgicalAssistantPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-assistant'>
					<ApplicationLayout module="surgical_assistance" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddSurgicalAssistant />
					</ApplicationLayout>
				</Route>

				{/* =================== region ================== */}

				<Route exact path='/edit-region'>
					<ApplicationLayout module="regions" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
					<UpdateRegionPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/regions'>
					<ApplicationLayout module="regions" checkAccess={["isReadAllowed"]} >
						<RegionPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-region'>
					<ApplicationLayout module="regions" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddRegionPage />
					</ApplicationLayout>
				</Route>

				{/* =================== procedure ================== */} 

				<Route exact path='/edit-procedure'>
					<ApplicationLayout module="procedures" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateProcedurePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/procedures'>
					<ApplicationLayout module="procedures" checkAccess={["isReadAllowed"]} >
						<ProcedurePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-procedure'>
					<ApplicationLayout module="procedures" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddProcedurePage />
					</ApplicationLayout>
				</Route> 

				{/* =================== knowledge base categories ================== */} 

				<Route exact path='/edit-knowledge-base-categories'>
					<ApplicationLayout module="knowledge-base-categories" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateKnowledgeBaseCategoryPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/knowledge-base-categories'>
					<ApplicationLayout module="knowledge-base-categories" checkAccess={["isReadAllowed"]} >
						<KnowledgeBaseCategoryPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-knowledge-base-categories'>
					<ApplicationLayout module="knowledge-base-categories" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddKnowledgeBaseCategoryPage />
					</ApplicationLayout>
				</Route> 

				{/* =================== knowledge base sub categories ================== */} 

				<Route exact path='/edit-knowledge-base-sub-categories'>
					<ApplicationLayout module="knowledge-base-sub-categories" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateKnowledgeBaseSubCategoryPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/knowledge-base-sub-categories'>
					<ApplicationLayout module="knowledge-base-sub-categories" checkAccess={["isReadAllowed"]} >
						<KnowledgeBaseSubCategoryPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-knowledge-base-sub-categories'>
					<ApplicationLayout module="knowledge-base-sub-categories" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddKnowledgeBaseSubCategoryPage />
					</ApplicationLayout>
				</Route> 

				{/* =================== knowledge base ================== */} 

				<Route exact path='/edit-knowledge-base'>
					<ApplicationLayout module="knowledge-base" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateKnowledgeBasePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/knowledge-base'>
					<ApplicationLayout module="knowledge-base" checkAccess={["isReadAllowed"]} >
						<KnowledgeBasePage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-knowledge-base'>
					<ApplicationLayout module="knowledge-base" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddKnowledgeBasePage />
					</ApplicationLayout>
				</Route> 
 
   				{/* =================== surgeon ================== */} 
			   
				<Route exact path='/edit-surgeon'>
					<ApplicationLayout module="surgeons" checkAccess={["isReadAllowed", "isUpdateAllowed"]} >
						<UpdateSurgeonPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/surgeons'>
					<ApplicationLayout module="surgeons" checkAccess={["isReadAllowed"]} >
						<SurgeonPage />
					</ApplicationLayout>
				</Route>
				<Route exact path='/add-surgeon'>
					<ApplicationLayout module="surgeons" checkAccess={["isReadAllowed", "isCreateAllowed"]} >
						<AddSurgeonPage />
					</ApplicationLayout>
				</Route>
				
				{/* =================== PBFUSA  ================== */}

				<Route exact path='/patient-registrations'>
					<PatientRegistration />
				</Route>
				<Route exact path='/volunteer-registrations'>
					<VolunteerRegistration />
				</Route>
				
            	{/* =================== PBFUSA  ================== */}


				{/* <PrivateRoute
					exact
					path='/subscribers'
					activePage='subscribers'
				/>
				<PrivateRoute
					exact
					path='/transactions'
					component={Transactions}
					activePage='transactions'
				/> */}
				{/* ===============================
				New Routes / Pages added - End
				================================ */}

				{/* ===============================
				Login Azure By Pass - End
				================================ */}
				{/* <Route exact path={APP_ROUTES.LOGIN}>
					<LoginAzureByPass />
				</Route> */}
				{/* ===============================
				Login Azure By Pass - End
				================================ */}

				{/* ===============================
				Login Azure - End
				================================ */}
				{/* <Route exact path={APP_ROUTES.LOGIN}>
					<Login />
				</Route> */}
				{/* ===============================
				Login Azure - End
				================================ */}

				<Route exact path={APP_ROUTES.REGISTER}>
					<Register />
				</Route>
				<Route
					exact
					path={APP_ROUTES.FILE_DISPLAY}
					component={FileDisplay}
				/>
				<Route exact path={APP_ROUTES.NOT_AUTHORIZED}>
					<NotAuthorized />
				</Route>
				<PrivateRoute
					exact
					path={APP_ROUTES.Bill_DASHBOARD}
					component={BillsDashboard}
					activePage={"bills"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.FEEDBACK}
					component={FeedbackDashboard}
					activePage={"feedback"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.NOTIFICATION}
					component={NotificationDashboard}
					activePage={"notification"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.PROFILE}
					component={Profile}
					activePage={"scheduling"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.SCAN_FLOW_V2}
					component={ScanflowV2}
					activePage={"scan-flow-v2"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.REVENUE_SCHEDULER_DASHBOARD}
					component={RevenueSchedulerDashboard}
					activePage={"revenue-scheduler"}
				/>

				<PrivateRoute
					exact
					path={APP_ROUTES.INVOICINGV1}
					component={InvoicingV1Dashboard}
					activePage={"invoicingv1"}
				/>

				<PrivateRoute
					exact
					path={APP_ROUTES.DIVISION}
					component={DivisionDashboard}
					activePage={"division"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_DIVISION}
					component={ManageDivision}
					activePage={"division"}
				/>

				<PrivateRoute
					exact
					path={APP_ROUTES.INVOICING}
					component={InvoicingDashboard}
					activePage={"invoicing"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_INVOICE}
					component={ManageInvoicing}
					activePage={"invoicing"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_INVOICING}
					component={EditInvoicing}
					activePage={"invoicing"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.LOGS}
					component={LogsDashboard}
					activePage={"logs"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.DASHBOARD}
					component={Dashboard}
					activePage={"scheduling"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ACTIVITY_DASHBOARD}
					component={ActivityDashboard}
					activePage={"activities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_ACTIVITY}
					component={ManageActivity}
					activePage={"activities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_ACTIVITY}
					component={ManageActivity}
					activePage={"activities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.PATIENTS_DASHBOARD}
					component={PatientsDashboard}
					activePage={"patients"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_PATIENT}
					component={ManagePatient}
					activePage={"patients"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_PATIENT}
					component={ManagePatient}
					activePage={"patients"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.PROCEDURE_DASHBOARD}
					component={ProcedureDashboard}
					activePage={"procedures"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_PROCEDURE}
					component={ManageProcedure}
					activePage={"procedures"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_PROCEDURE}
					component={ManageProcedure}
					activePage={"procedures"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.SURGEONS_DASHBOARD}
					component={SurgeonDashboard}
					activePage={"surgeons"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_SURGEON}
					component={ManageSurgeons}
					activePage={"surgeons"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_SURGEON}
					component={ManageSurgeons}
					activePage={"surgeons"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.CONTRACTS_DASHBOARD}
					component={ContractDashboard}
					activePage={"contracts"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_CONTRACT}
					component={ManageContract}
					activePage={"contracts"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_CONTRACT}
					component={ManageContract}
					activePage={"contracts"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.FACILITIES_DASHBOARD}
					component={FacilityDashboard}
					activePage={"facilities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_FACILITY}
					component={ManageFacility}
					activePage={"facilities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_FACILITY}
					component={ManageFacility}
					activePage={"facilities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.CLIENTS_DASHBOARD}
					component={ClientsDashboard}
					activePage={"tenant-clients"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_CLIENT}
					component={ManageClients}
					activePage={"tenant-clients"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_CLIENT}
					component={ManageClients}
					activePage={"tenant-clients"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.SCAN_FLOW_DASHBOARD}
					component={ScanFlowDashboard}
					activePage={"scan-flow"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_SCAN_FLOW}
					component={ManageScanFlow}
					activePage={"scan-flow"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.CASELOG_DASHBOARD}
					component={CaseLogDashboard}
					activePage={"pac-data"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_CASELOG}
					component={ManageCaseLogs}
					activePage={"pac-data"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_CASELOG}
					component={ManageCaseLogs}
					activePage={"pac-data"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_INSURANCE}
					component={ManageInsurance}
					activePage={"insurance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_INSURANCE}
					component={ManageInsurance}
					activePage={"insurance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.INSURANCE_DASHBOARD}
					component={InsuranceDashboard}
					activePage={"insurance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.GROUP_DASHBOARD}
					component={GroupDashboard}
					activePage={"groups"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.GROUP_ADD}
					component={ManageGroups}
					activePage={"groups"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.GROUP_EDIT}
					component={ManageGroups}
					activePage={"groups"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.USER_DASHBOARD}
					component={UserDashboard}
					activePage={"users"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_USER}
					component={ManageUsers}
					activePage={"users"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_USER}
					component={ManageUsers}
					activePage={"users"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.KNOWLEDGE_BASE_CATEGORIES}
					component={KnowledgeBaseCategoryDashboard}
					activePage={"knowledge-base-categories"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_KNOWLEDGE_BASE_CATEGORIES}
					component={ManageKnowledgeBaseCategory}
					activePage={"knowledge-base-categories"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_KNOWLEDGE_BASE_CATEGORIES}
					component={ManageKnowledgeBaseCategory}
					activePage={"knowledge-base-categories"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.KNOWLEDGE_BASE_SUB_CATEGORIES}
					component={KnowledgeBaseSubCategoryDashboard}
					activePage={"knowledge-base-sub-categories"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_KNOWLEDGE_BASE_SUB_CATEGORIES}
					component={ManageKnowledgeBaseSubCategory}
					activePage={"knowledge-base-sub-categories"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_KNOWLEDGE_BASE_SUB_CATEGORIES}
					component={ManageKnowledgeBaseSubCategory}
					activePage={"knowledge-base-sub-categories"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.KNOWLEDGE_BASE_ARTICLES}
					component={KnowledgeBaseArticleDashboard}
					activePage={"knowledge-base"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_KNOWLEDGE_BASE_ARTICLES}
					component={ManageKnowledgeBaseArticle}
					activePage={"knowledge-base"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_KNOWLEDGE_BASE_ARTICLES}
					component={ManageKnowledgeBaseArticle}
					activePage={"knowledge-base"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.SURGEON_ASSOCIATION}
					component={SurgeonAssociationDashboard}
					activePage={"surgeon-association"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_SURGEON_ASSOCIATION}
					component={ManageSurgeonAssociation}
					activePage={"surgeon-association"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ARCHIVED_USER_DASHBOARD}
					component={UserDashboard}
					key={"archieved"}
					activePage={"archived-users"}
					archievedUser={true}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_ARCHIVED_USER}
					component={ManageUsers}
					key={"archieved"}
					activePage={"archived-users"}
					archievedUser={true}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_ARCHIVED_USER}
					key={"archieved"}
					component={ManageUsers}
					activePage={"archived-users"}
					archievedUser={true}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.PRACTICE_DASHBOARD}
					component={PracticeDashboard}
					activePage={"practices"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_PRACTICE}
					component={ManagePractice}
					activePage={"practices"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_PRACTICE}
					component={ManagePractice}
					activePage={"practices"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_BILL}
					component={ManageBilling}
					activePage={"activities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_BILL}
					component={ManageBilling}
					activePage={"activities"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.CLAIMS_AND_COLLECTION}
					component={ClaimsAndCollectionDashboard}
					activePage={"claims-and-collections"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_CLAIM_AND_COLLECTION}
					component={ManageClaimDetail}
					activePage={"claims-and-collections"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.INVOICE_CLAIM_AND_COLLECTION}
					component={ClaimInvoice}
					activePage={"claims-and-collections"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.CLAIM_FORM}
					component={ClaimForm}
					activePage={"claims-and-collections"}
				/>

				<PrivateRoute
					exact
					path={APP_ROUTES.REGIONS}
					component={RegionsDashboard}
					activePage={"regions"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_REGION}
					component={ManageRegions}
					activePage={"regions"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.UPDATE_REGION}
					component={ManageRegions}
					activePage={"regions"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.SURGICAL_ASSISTANT}
					component={AssistantDashboard}
					activePage={"surgical_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD}
					component={AssistantDashboard}
					activePage={"surgical_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_ASSISTANT}
					component={ManageAssistant}
					activePage={"surgical_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_ASSISTANT}
					component={ManageAssistant}
					activePage={"surgical_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.PHYSICIAN_ASSISTANT}
					component={PhysicianAssistantDashboard}
					activePage={"physicians_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.PHYSICIAN_ASSISTANT_DASHBOARD}
					component={PhysicianAssistantDashboard}
					activePage={"physicians_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_PHYSICIAN_ASSISTANT}
					component={ManagePhysicianAssistant}
					activePage={"physicians_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_PHYSICIAN_ASSISTANT}
					component={ManagePhysicianAssistant}
					activePage={"physicians_assistance"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.BATCH_DASHBOARD}
					component={BatchDashboard}
					activePage={"Batch"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.BATCH_ADD}
					component={ManageBatches}
					activePage={"Batch"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.BATCH_UPDATE}
					component={ManageBatches}
					activePage={"Batch"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.PAYMENT_POSTING}
					component={ManagePaymentPosting}
					activePage={"Batch"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.UPDATE_PAYMENT_POSTING}
					component={ManagePosting}
					activePage={"Batch"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ACCOUNT_DASHBOARD}
					component={AccountsDashboard}
					activePage={"accounts"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ACCOUNT_INVOICE_DETAIL}
					component={AccountDetailDashboard}
					activePage={"accounts"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ACCOUNT_INVOICE_UPDATE}
					component={ManageInvoice}
					activePage={"accounts"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ORGANIZATION_DASHBOARD}
					component={OrganizationDashboard}
					activePage={"organizations"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.ADD_ORGANIZATION}
					component={ManageOrganization}
					activePage={"organizations"}
				/>
				<PrivateRoute
					exact
					path={APP_ROUTES.EDIT_ORGANIZATION}
					component={ManageOrganization}
					activePage={"organizations"}
				/>
				<PrivateRoute
					exact
					path='/'
					name='Home'
					component={Dashboard}
					activePage={"scheduling"}
				/>
				<Route component={PageNotFound} />
			</Switch>
		</BrowserRouter>
		</UIProvider>
	);
}

export default App;