import {
	convertGMTToLocal,
	formatDateTime,
	formatTime,
	getLogInUserDetails,
	getScanfloeStatusInfo,
	isManager,
} from "../../opl-utilities/helpers";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import {
	ALLOWED_FILE_TYPES,
	API_URL,
	SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import { useUI } from "../../context/ui";
import { useScanflow } from "./scanflow-context";
import ScanflowForm from "./ScanflowForm";
import CustomModal from "../../components/common/CustomModal";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import FileViewer from "../../components/common/ui/FileViewer";
import { withRouter } from "react-router-dom";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import ScanFlowImages from "./ScanFlowImages";
import PopUpModal from "../../components/common/PopUpModal";

const Label = ({ title }) => (
	<p
		className='color-primary f-w-300 l-h-1'
		style={{ fontSize: "12px", paddingBottom: "6px" }}>
		{title}
	</p>
);

const AssigneeDetails = ({ data }) => {
	return (
		<div
			style={{
				padding: "20px",
				borderBottom: "solid 1px #d8d8d8",
			}}>
			<Label title='Assignee' />
			<p
				className='color-primary f-w-300 l-h-1'
				style={{ fontSize: "12px", paddingBottom: "6px" }}>
				{data?.assignee?.name || "-"} {data?.assignee?.lastName}
			</p>
		</div>
	);
};

const AssigneeFormModal = withRouter(({ history, location, data, setShow }) => {
	const queryParams = new URLSearchParams(location.search);
	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const userId = getLogInUserDetails("id");
	const role = getLogInUserDetails("type");
	const { refreshHandler, random } = useScanflow();
	const { addToastMessage } = useUI();

	const callUserApi = async () => {
		setUsers([]);
		try {
			const role = getLogInUserDetails("type");
			const resp = await fetch(
				API_URL + `/api/1.0.0/users?type=${role}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setUsers(response.data);
				}
			}
		} catch (e) {
			console.log("error", e);
		} finally {
		}
	};

	useEffect(() => {
		callUserApi();
	}, [random]);

	const validationSchema = Yup.object({
		assignee: Yup.string().required("Assignee is required"),
	});

	return (
		<section id='opl_coremax'>
			<section
				style={{
					position: "fixed",
					inset: 0,
					zIndex: 9999,
					backgroundColor: "rgba(255, 255, 255, 0.86)",
				}}>
				<section
					className='col-1-1 flex center-center'
					style={{
						height: "100vh",
						width: "100vw",
					}}>
					<div
						style={{
							width: "400px",
							padding: "24px",
							height: "auto",
							maxHeight: "80vh",
							border: "solid 1px #f0f0f0",
							background: "white",
							borderRadius: "8px",
							margin: "0 auto",
							boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
						}}>
						<div
							className='col-1-1 flex center-left'
							style={{
								borderBottom: "solid 1px #f0f0f0",
								paddingBottom: "12px",
								marginBottom: "16px",
							}}>
							<h6
								className='color-primary f-w-300 opl-tooltip'
								data-tooltip='View entire history of the activity below.'>
								Assign Task
							</h6>
							<div
								className='mar-l-a cursor-pointer opl-tooltip'
								data-tooltip='Close to return to the previous section.'
								onClick={() => setShow(false)}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
										fill='#283f54'
									/>
								</svg>
							</div>
						</div>
						<div>
							<section>
								<Formik
									initialValues={{
										assignee: data?.assignee?.id || "",
										type: "",
									}}
									validationSchema={validationSchema}
									enableReinitialize={true}
									onSubmit={async (values) => {
										setLoading(true);
										try {
											const resp = await fetch(
												API_URL +
													`/api/1.0.0/scanflow-v2/assign-task`,
												{
													method: "POST",
													body: JSON.stringify({
														userId: userId,
														role: role,
														assignee:
															values.assignee,
														stepExecutionId:
															data.id,
													}),
													headers: {
														"Content-Type":
															"application/json",
														"X-JWT-Assertion":
														localStorage.getItem("token"),
													},
												}
											);
											if (resp.ok) {
												const response =
													await resp.json();
												if (
													response.result
														.responseCode === 200
												) {
													if (
														response?.data?.taskId
													) {
														updateQueryParam(
															"id",
															response?.data
																?.taskId
														);
													}
													addToastMessage({
														status: true,
														message:
															"Task successfully assigned.",
													});
													refreshHandler();
													setShow();
												} else {
													addToastMessage({
														status: false,
														message:
															"Something went wrong in assigning task. Please try again.",
													});
												}
											} else {
												const response =
													await resp.json();
												addToastMessage({
													status: false,
													message:
														response?.responseDescription ||
														"Something went wrong in assigning task. Please try again.",
												});
											}
										} catch (e) {
											console.log("error", e);
											addToastMessage({
												status: false,
												message:
													"Something went wrong in assigning task. Please try again.",
											});
										} finally {
											setLoading(false);
										}
									}}>
									{({
										handleSubmit,
										values,
										errors,
										touched,
										setFieldValue,
										validateForm,
									}) => (
										<Form onSubmit={handleSubmit}>
											<div
												style={{
													padding: "20px",
													borderBottom:
														"solid 1px #d8d8d8",
												}}>
												<div>
													<ButtonLightTextIcon
														title={
															"Assign to myself"
														}
														disabled={
															loading ||
															userId ===
																data?.assignee
																	?.id
														}
														style={{
															width: "100%",
														}}
														type='button'
														icon={
															loading &&
															values.type ===
																"myself" ? (
																<LoaderIcon />
															) : (
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'>
																	<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
																</svg>
															)
														}
														onClick={async () => {
															await setFieldValue(
																"assignee",
																userId
															);
															await setFieldValue(
																"type",
																"myself"
															);
															const errors =
																await validateForm();
															handleSubmit();
														}}
													/>
												</div>
												<div
													style={{
														position: "relative",
														margin: "20px 0",
													}}>
													<div
														style={{
															position:
																"absolute",
															inset: "0",
															display: "flex",
															alignItems:
																"center",
														}}>
														<span
															style={{
																width: "100%",
																borderTop:
																	"1px solid #ddd",
															}}
														/>
													</div>
													<div
														style={{
															position:
																"relative",
															display: "flex",
															justifyContent:
																"center",
															fontSize: "0.75rem",
															textTransform:
																"uppercase",
														}}>
														<span
															style={{
																backgroundColor:
																	"white",
																padding:
																	"0 0.5rem",
																color: "#6b7280",
															}}>
															or
														</span>
													</div>
												</div>
												<div
													style={{
														marginTop: "10px",
														marginBottom: "16px",
													}}>
													<Label title='Assign to Others' />
													<Field
														as='select'
														name={"assignee"}
														className={
															"primary " +
															(errors.assignee &&
															touched.assignee
																? " error"
																: "")
														}
														disabled={
															!isManager(role) ||
															data.statusId !== 1
														}>
														<option value={""}>
															Select User
														</option>
														{users
															?.filter(
																(d) =>
																	d.id !==
																	userId
															)
															.map((d, i) => (
																<option
																	key={i}
																	value={
																		d.id
																	}>
																	{d.name}{" "}
																	{
																		d.last_name
																	}
																	{d.completed ||
																	d.notStarted ||
																	d.onGoing
																		? "  ("
																		: ""}
																	{d.completed
																		? `Completed: ${
																				d.completed
																		  }${
																				d.notStarted
																					? ","
																					: ""
																		  } `
																		: ""}
																	{d.notStarted
																		? `Not Started: ${
																				d.notStarted
																		  }${
																				d.onGoing
																					? ","
																					: ""
																		  } `
																		: ""}
																	{d.onGoing
																		? `On Going: ${d.onGoing}`
																		: ""}
																	{d.completed ||
																	d.notStarted ||
																	d.onGoing
																		? ")"
																		: ""}
																</option>
															))}
													</Field>
												</div>
												<div className='flex col-1-1 center-right'>
													<ButtonTextIcon
														title={
															data?.assignee?.name
																? "Forward"
																: "Assign"
														}
														type='button'
														onClick={async () => {
															await setFieldValue(
																"type",
																"other"
															);
															const errors =
																await validateForm();
															handleSubmit();
														}}
														style={{
															width: "100%",
														}}
														disabled={loading}
														icon={
															loading &&
															values.type ===
																"other" ? (
																<LoaderIcon />
															) : data?.assignee
																	?.name ? (
																<svg
																	clipRule='evenodd'
																	fillRule='evenodd'
																	strokeLinejoin='round'
																	strokeMiterlimit='2'
																	viewBox='0 0 24 24'>
																	<path
																		d='M12.012 1.995c-5.518 0-9.998 4.48-9.998 9.998s4.48 9.998 9.998 9.998 9.997-4.48 9.997-9.998-4.479-9.998-9.997-9.998zm0 1.5c4.69 0 8.497 3.808 8.497 8.498s-3.807 8.498-8.497 8.498-8.498-3.808-8.498-8.498 3.808-8.498 8.498-8.498zm1.528 4.715s1.502 1.505 3.255 3.259c.146.147.219.339.219.531s-.073.383-.219.53c-1.753 1.754-3.254 3.258-3.254 3.258-.145.145-.336.217-.527.217-.191-.001-.383-.074-.53-.221-.293-.293-.295-.766-.004-1.057l1.978-1.977h-6.694c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6.694l-1.979-1.979c-.289-.289-.286-.762.006-1.054.147-.147.339-.221.531-.222.19 0 .38.071.524.215z'
																		fillRule='nonzero'
																	/>
																</svg>
															) : (
																<svg
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'>
																	<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
																</svg>
															)
														}
													/>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</section>
						</div>
						<div
							className='col-1-1 flex center-left'
							style={{
								marginTop: "16px",
								borderTop: "solid 1px #f0f0f0",
								paddingTop: "16px",
							}}>
							<div style={{ marginLeft: "auto" }}>
								<ButtonLightTextIcon
									title='Cancel'
									onClick={() => setShow(false)}
									icon={
										<svg
											clipRule="evenodd"
											fillRule="evenodd"
											strokeLinejoin="round"
											strokeMiterlimit="2"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											width='19.995'
											height='19.996'
										>
											<path
												d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
												fillRule="nonzero" 
											/>
										</svg>
									}
								/>
							</div>
						</div>
					</div>
				</section>
			</section>
		</section>
	);
});

const Timer = ({ task }) => {
	const [elapsedTime, setElapsedTime] = useState(0);
	const [isTimerRunning, setIsTimerRunning] = useState(false);
	useEffect(() => {
		let timerInterval = null;
		if (isTimerRunning) {
			timerInterval = setInterval(() => {
				setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
			}, 1000);
		}
		return () => clearInterval(timerInterval);
	}, [isTimerRunning]);

	useEffect(() => {
		setElapsedTime(0);
		if (task?.statusId === 2) {
			const intervals = task?.intervals
				? JSON.parse(task?.intervals)
				: "";
			if (intervals && intervals.length > 0) {
				const savedStartTime =
					intervals[intervals.length - 1].startTime;
				if (savedStartTime) {
					const defaultDurationInSeconds = task.timeSpent;
					const startTime = new Date(
						convertGMTToLocal(savedStartTime.replace(" ", "T"))
					);
					const currentTime = new Date();
					const elapsedSeconds = Math.floor(
						(currentTime - startTime) / 1000
					);
					const newRemainingTime =
						defaultDurationInSeconds + elapsedSeconds;
					setElapsedTime(newRemainingTime > 0 ? newRemainingTime : 0);
				}
			}
			setIsTimerRunning(true);
		} else {
			setElapsedTime(task.timeSpent ? task.timeSpent : 0);
			setIsTimerRunning(false);
		}
	}, [task]);

	return (
		<h4 className='l-h-1-2 f-w-900 t-a-r' style={{ color: "#7f8c8d" }}>
			{formatTime(elapsedTime)}
		</h4>
	);
};

const TaskDetails = () => {
	const { task } = useScanflow();
	const userId = getLogInUserDetails("id");
	const role = getLogInUserDetails("type");
	const [viewFileUpload, setViewFileUpload] = useState("");

	return (
		<>
			<div className='doc-container'>
				<div
					className='col-1-1 flex center-left'
					style={{
						padding: "16px 20px",
						borderBottom: "solid 1px #e3e3e3",
					}}>
					<div>
						<h5
							className='l-h-1-2 f-w-700'
							style={{ color: "#2c3e50" }}>
							Document
						</h5>
						<p
							className='l-h-1 f-w-300'
							style={{ color: "#8f8f8f" }}>
							(All Files)
						</p>
					</div>
					<div className='mar-l-a'>
						<Timer task={task} />
						<p
							className='l-h-1 f-w-300 t-a-r '
							style={{ color: "#8f8f8f" }}>
							(
							<span className='t-t-u'>
								{task?.stepsConfig?.stepName || "NA"}
							</span>{" "}
							| {getScanfloeStatusInfo("id", task?.statusId).name}
							)
						</p>
					</div>
				</div>
				<div
					style={{
						backgroundColor: "#ecf0f1",
						width: "auto",
						height: "100%",
						padding: "0px",
					}}>
					{task.patientActivity.files.length > 0 && (
						<ScanFlowImages
						    p={task.patientActivity.patient || null}
							files={task.patientActivity.files}
							showAddbutton={ task?.assignee?.id === userId && task?.statusId !== 3 && task?.statusId !== 5 && ![4, 1].includes(task.statusId) }
							handleAddMore={() => {
								setViewFileUpload("Handle");
							}}
						/>
					)} 
					{/* {task?.patientActivity?.facesheetURL ? (
						<FileViewer
							fileUrl={task?.patientActivity?.facesheetURL}
						/>
					) : (
						<div className='flex center-center'>
							<p>
								Sorry, the Document you're looking for could not
								be found.
							</p>
						</div>
					)} */}
				</div>
			</div>
			<div className='from-container'>
				<div>
					<AssigneeDetails data={task} />
					{isManager(role) && [1].includes(task.statusId) && (
						<div
							style={{
								padding: "20px",
								borderBottom: "solid 1px #d8d8d8",
							}}>
							<CustomModal
								selector={
									<ButtonTextIcon
										style={{ width: "100%" }}
										icon={
											task?.assignee?.name ? (
												<svg
													clipRule='evenodd'
													fillRule='evenodd'
													strokeLinejoin='round'
													strokeMiterlimit='2'
													viewBox='0 0 24 24'>
													<path
														d='M12.012 1.995c-5.518 0-9.998 4.48-9.998 9.998s4.48 9.998 9.998 9.998 9.997-4.48 9.997-9.998-4.479-9.998-9.997-9.998zm0 1.5c4.69 0 8.497 3.808 8.497 8.498s-3.807 8.498-8.497 8.498-8.498-3.808-8.498-8.498 3.808-8.498 8.498-8.498zm1.528 4.715s1.502 1.505 3.255 3.259c.146.147.219.339.219.531s-.073.383-.219.53c-1.753 1.754-3.254 3.258-3.254 3.258-.145.145-.336.217-.527.217-.191-.001-.383-.074-.53-.221-.293-.293-.295-.766-.004-1.057l1.978-1.977h-6.694c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6.694l-1.979-1.979c-.289-.289-.286-.762.006-1.054.147-.147.339-.221.531-.222.19 0 .38.071.524.215z'
														fillRule='nonzero'
													/>
												</svg>
											) : (
												<svg
													width='24'
													height='24'
													viewBox='0 0 24 24'>
													<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
												</svg>
											)
										}
										title={
											task?.assignee?.name
												? "Forward"
												: "Assign"
										}
									/>
								}
								content={<AssigneeFormModal data={task} />}
							/>
						</div>
					)}
				</div>
				<div style={{ padding: "12px 16px" }}>
					<div
						className='col-1-1 flex center-left'
						style={{
							paddingBottom: "12px",
							borderBottom: "solid 1px #f0f0f0",
							marginBottom: "16px",
						}}>
						<h6 className='color-primary f-w-600'>Update</h6>
						<div className='mar-l-a'>
							<CustomModal
								selector={
									<ButtonLightTextIcon
										title='Scanflow history'
										icon={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'>
												<path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-8v-9h2v7h6v2z' />
											</svg>
										}
									/>
								}
								content={
									<ScanFlowHistoryContent
										id={task?.patientActivity?.id}
									/>
								}
							/>
						</div>
					</div>
					<ScanflowForm />
					{/* setIsTimerRunning={ () => { setIsTimerRunning(); }}  */}
				</div>
				{viewFileUpload && (
					<PopUpModal>
						<DocumentFormContent
							setShow={setViewFileUpload}
							id={task?.patientActivity?.patientEventId}
							files={task?.patientActivity?.files}
						/>
					</PopUpModal>
				)}
			</div>
		</>
	);
};

export default TaskDetails;

const DetailRow = ({ label, value }) => (
	<div>
		<p
			className='f-w-300 l-h-1'
			style={{
				fontSize: "12px",
				paddingBottom: "4px",
				color: "#aaaaaa",
			}}>
			{label}:
		</p>
		<p className='f-w-400 color-primary l-h-1' style={{}}>
			{value}
		</p>
	</div>
);

const ScanFlowHistoryContent = ({ id, setShow }) => {
	const [history, setHistory] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { random } = useScanflow();

	const callApi = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				`${API_URL}/api/1.0.0/scanflow-v2/activity-history?patientActivityId=${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (!resp.ok) throw new Error("Failed to fetch history");
			const { data } = await resp.json();
			setHistory(data);
		} catch (e) {
			setError("An error occurred while retrieving scanflow history.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) callApi();
	}, [id, random]);

	const getRandomColor = () => {
		const array = ["#F08D3B", "#E65464", "#56BAEC", "#8e44ad", "#27ae60"];
		const randomIndex = Math.floor(Math.random() * array.length);
		return array[randomIndex];
	};

	if (!id) return null;

	return (
		<section id='opl_coremax'>
			<section
				style={{
					position: "fixed",
					inset: 0,
					zIndex: 9999,
					backgroundColor: "rgba(255, 255, 255, 0.86)",
				}}>
				<section
					className='col-1-1 flex center-center'
					style={{
						height: "100vh",
						width: "100vw",
					}}>
					<div
						style={{
							width: "486px",
							padding: "24px",
							height: "auto",
							maxHeight: "80vh",
							border: "solid 1px #f0f0f0",
							background: "white",
							borderRadius: "8px",
							margin: "0 auto",
							boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
						}}>
						<div
							className='col-1-1 flex center-left'
							style={{
								borderBottom: "solid 1px #f0f0f0",
								paddingBottom: "12px",
								marginBottom: "16px",
							}}>
							<h6
								className='color-primary f-w-300 opl-tooltip'
								data-tooltip='View entire history of the activity below.'>
								Scanflow History
							</h6>
							<div
								className='mar-l-a cursor-pointer opl-tooltip'
								data-tooltip='Close to return to the previous section.'
								onClick={() => setShow(false)}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
										fill='#283f54'
									/>
								</svg>
							</div>
						</div>
						<div
							style={{
								height: "492px",
								overflowY: "auto",
								background: "white",
							}}>
							<section>
								{loading && (
									<div
										className='col-1-1'
										style={{
											height: "40px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<LoaderIcon
											style={{
												width: "20px",
												height: "20px",
											}}
										/>
									</div>
								)}
								{!loading && error && (
									<p
										className='f-w-300 color-primary'
										style={{ color: "#c0392b" }}>
										{error}
									</p>
								)}
								{!loading &&
									!error &&
									history &&
									history?.length &&
									history.map(
										(
											{
												stepsConfig,
												assignee,
												assignedAt,
												createdAt,
												updatedAt,
												statusId,
												timeSpent,
												comment,
											},
											i
										) => (
											<div className='col-1-1' key={i}>
												<div>
													<div className='flex center-left'>
														<div
															className='flex center-center b-r-100'
															style={{
																flex: "0 40px",
																background:
																	"#f0f0f0",
																height: "40px",
																width: "40px",
															}}>
															<p
																className='f-w-300 color-primary l-h-1'
																style={{
																	fontSize:
																		"11px",
																}}>
																#
																<span className='f-w-700'>
																	{history.length -
																		i}
																</span>
															</p>
														</div>
														<div
															style={{
																height: "4px",
																width: "24px",
																borderTop:
																	"dashed 2px #f0f0f0",
															}}></div>
														<div
															className='b-r-20'
															style={{
																padding:
																	"12px 16px",
																backgroundColor:
																	getRandomColor(),
															}}>
															<p
																className='color-white f-w-400 l-h-1 t-t-u'
																style={{
																	fontSize:
																		"11px",
																}}>
																{stepsConfig?.stepName ||
																	"NA"}
															</p>
														</div>
													</div>
													<div
														style={{
															height: "16px",
															width: "4px",
															borderLeft:
																"dashed 2px #f0f0f0",
															marginLeft: "20px",
														}}></div>
													<div
														className='b-r-5'
														style={{
															display: "grid",
															gridTemplateColumns:
																"repeat(2, 1fr)",
															gridTemplateRows:
																"repeat(auto-fill, auto)",
															gridColmnGap:
																"12px",
															gridRowGap: "12px",
															background:
																"#f4fbff",
															padding: "16px",
															border: "solid 1px #f0f0f0",
														}}>
														<div>
															<DetailRow
																label='Assignee'
																value={
																	assignee?.name ||
																	"NA"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Assigned At'
																value={
																	assignedAt
																		? formatDateTime(
																				assignedAt
																		  ).toLocaleString()
																		: "-"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Started At'
																value={
																	createdAt
																		? formatDateTime(
																				createdAt
																		  ).toLocaleString()
																		: "-"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Completed At'
																value={
																	updatedAt &&
																	statusId ===
																		5
																		? formatDateTime(
																				updatedAt
																		  ).toLocaleString()
																		: "-"
																}
															/>
														</div>
														<div>
															<DetailRow
																label='Total Time Spent'
																value={`${formatTime(
																	timeSpent ||
																		0
																)} hrs.`}
															/>
														</div>
														<div>
															<DetailRow
																label='Note'
																value={
																	comment ||
																	"-"
																}
															/>
														</div>
													</div>
													{history.length !==
														i - 1 && (
														<div
															style={{
																height: "48px",
																width: "4px",
																borderLeft:
																	"dashed 2px #f0f0f0",
																marginLeft:
																	"20px",
															}}></div>
													)}
												</div>
											</div>
										)
									)}
								{!loading &&
									!error &&
									history &&
									history.length === 0 && (
										<p className='f-w-300 color-primary'>
											No history available.
										</p>
									)}
							</section>
						</div>
						<div
							className='col-1-1 flex center-left'
							style={{
								marginTop: "16px",
								borderTop: "solid 1px #f0f0f0",
								paddingTop: "16px",
							}}>
							<ButtonLightTextIcon
								title='Cancel'
								onClick={() => setShow(false)}
								icon={
									<svg
										clipRule="evenodd"
										fillRule="evenodd"
										strokeLinejoin="round"
										strokeMiterlimit="2"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
										width='19.995'
										height='19.996'
									>
										<path
											d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
											fillRule="nonzero" 
										/>
									</svg>
								}
							/>
						</div>
					</div>
				</section>
			</section>
		</section>
	);
};

const DocumentFormContent = ({ id, files, setShow }) => {
	const { addToastMessage } = useUI();
	const [loading, setLoading] = useState(false);
	const fileUploadRef = useRef();
	const validationSchema = Yup.object().shape({
		file_type: Yup.string().required("File Type is required"),
		file: Yup.string().required("File is required"),
	});
	const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
	const { refreshHandler } = useScanflow();

	const Label = ({ title }) => (
		<p
			className='color-primary f-w-300 l-h-1'
			style={{ fontSize: "12px", paddingBottom: "6px" }}>
			{title}
		</p>
	);

	return (
		<section id='opl_coremax'>
			<section
				style={{
					position: "fixed",
					inset: 0,
					zIndex: 9999,
					backgroundColor: "rgba(255, 255, 255, 0.86)",
				}}>
				<section
					className='col-1-1 flex center-center'
					style={{
						height: "100vh",
						width: "100vw",
					}}>
					<div
						style={{
							width: "486px",
							padding: "24px",
							height: "auto",
							maxHeight: "80vh",
							border: "solid 1px #f0f0f0",
							background: "white",
							borderRadius: "8px",
							margin: "0 auto",
							boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
						}}>
						<div
							className='col-1-1 flex center-left'
							style={{
								borderBottom: "solid 1px #f0f0f0",
								paddingBottom: "12px",
								marginBottom: "16px",
							}}>
							<h6
								className='color-primary f-w-300 opl-tooltip'
								data-tooltip='View entire history of the activity below.'>
								Add File
							</h6>
							<div
								className='mar-l-a cursor-pointer opl-tooltip'
								data-tooltip='Close to return to the previous section.'
								onClick={() => setShow(false)}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
										fill='#283f54'
									/>
								</svg>
							</div>
						</div>
						<div >
							<Formik
								initialValues={{
									file_type: "facesheet",
									file: "",
								}}
								validationSchema={validationSchema}
								onSubmit={async (values, { resetForm }) => {
									setLoading(true);
									try {
										const loggedInUser = JSON.parse(
											localStorage.getItem(
												"LOGGED_IN_USER"
											)
										); 
										let newFiles = files.map((o) => ({
											type: o.fileType,
											fileUrl: o.fileUrl,
										}));

										const hasFacesheet = newFiles.some(
											(file) => file.type === "facesheet"
										);
										
										if ( values.file_type === 'facesheet' && hasFacesheet) {
											newFiles = newFiles.filter( (file) => file.type !== "facesheet");
										}
  
										const payloadJSON = JSON.stringify({
											files: [
												{
													type: values.file_type,
													fileUrl: values.file,
												},
												...newFiles,
											],
											user_id: loggedInUser.id,
											id: id,
										});

										const resp = await fetch(
											API_URL + `/api/1.0.0/activity`,
											{
												method: "PUT",
												headers: {
													"Content-Type":
														"application/json",
													"X-JWT-Assertion":
													localStorage.getItem("token"),
												},
												body: payloadJSON,
											}
										);
										if (resp.ok) {
											const response = await resp.json();
											if (response) {
												if (
													response.result
														.responseCode === 200
												) {
													addToastMessage({
														status: true,
														message: `File added successfully, thank you.`,
													});
													resetForm();
													setShow(false);
													refreshHandler();
													handleReset();
												} else {
													addToastMessage({
														status: false,
														message: `Something went wrong, please try again later.`,
													});
												}
											}
										} else {
											const response = await resp.json();
											if (response) {
												addToastMessage({
													status: false,
													message: `${
														response.responseDescription ||
														"Something went wrong, please try again later."
													}`,
												});
											}
										}
									} catch (e) {
										addToastMessage({
											status: false,
											message:
												"Something went wrong, please try again later.",
										});
										console.log("Error:", e);
									} finally {
										setLoading(false);
									}
								}}>
								{({
									errors,
									touched,
									setFieldValue,
									values,
									submitForm,
								}) => (
									<Form className='col-1-1'>
										<table className='col-1-1'>
											<tbody>
												<tr>
													<td colSpan='2'>
														<Label title='File Type' />
														<Field
															as='select'
															name='file_type'
															onChange={(e)=>{
																setFieldValue("file_type",e.target.value);
																handleReset();
															}}
															className={`col-1-1 primary ${
																errors.file_type &&
																touched.file_type
																	? "error"
																	: ""
															}`}>
															<option value=''>
																Select
															</option>
															{ALLOWED_FILE_TYPES.map(
																(d, i) => (
																	<option
																		value={
																			d.value
																		}
																		key={i}>
																		{
																			d.label
																		}
																	</option>
																)
															)}
														</Field>
													</td>
												</tr>
												{values.file_type && (
													<tr>
														<td colSpan='2'>
															<div
																className='flex center-left'
																style={{
																	gap: "8px",
																}}>
																<div
																	className='opl-tooltip'
																	data-tooltip='Please only select PDF files for upload.'>
																	<Label title='Upload File' />
																</div>
																<div
																	className='opl-tooltip'
																	data-tooltip='Please only select PDF files for upload.'>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																		style={{
																			height: "12px",
																			width: "12px",
																			marginBottom:
																				"5px",
																		}}>
																		<path
																			d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
																			fill='#5dc6b3'
																		/>
																	</svg>
																</div>
															</div>
															<DragFileUpload
																ref={
																	fileUploadRef
																}
																fileType={
																	values.file_type
																}
																error={
																	errors.file &&
																	touched.file
																}
																afterUpload={(
																	url
																) => {
																	if (url) {
																		setFieldValue(
																			"file",
																			url
																		);
																	}
																}}
															/>
														</td>
													</tr>
												)}
												<tr>
													<br />
												</tr>
												<tr>
													<div
														style={{
															marginLeft: "auto",
														}}>
														<ButtonTextIcon
															title='Add File'
															type='submit'
															style={{
																width: "100%",
															}}
															disabled={loading}
															icon={
																loading ? <LoaderIcon /> : (
																	<svg
																		fill='#000000'
																		version='1.1'
																		width='800px'
																		height='800px'
																		viewBox='0 0 400 400'
																		xmlSpace='preserve'>
																		<g>
																			<g>
																				<path d='M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z' />
																				<path d='M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z' />
																			</g>
																		</g>
																	</svg>)
															}
														/>
													</div>
												</tr>
											</tbody>
										</table>
									</Form>
								)}
							</Formik>
						</div>
						<div
							className='col-1-1 flex center-left'
							style={{
								marginTop: "16px",
								borderTop: "solid 1px #f0f0f0",
								paddingTop: "16px",
							}}>
							<ButtonLightTextIcon
								title='Cancel'
								onClick={() => setShow(false)}
								icon={
									<svg
										clipRule="evenodd"
										fillRule="evenodd"
										strokeLinejoin="round"
										strokeMiterlimit="2"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
										width='19.995'
										height='19.996'
									>
										<path
											d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
											fillRule="nonzero" 
										/>
									</svg>
								}
							/>
						</div>
					</div>
				</section>
			</section>
		</section>
	);
};
