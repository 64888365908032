import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import TableFilter from "./knowledge-base/TableFilter";
import { useUI } from "../context/ui";
import { decodeModuleAccessFromToken } from "../opl-utilities/helpers";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import PopUpModal from "../components/common/PopUpModal";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import FileViewer from "../components/common/ui/FileViewer";
import FullScreenFileViewerModal from "../components/common/ui/FullScreenFileViewerModal";

const jsonToQueryParams = (json) => {
  const queryParams = Object.keys(json)
    .filter((key) => json[key] !== null && json[key] !== "")
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
    )
    .join("&");

  return queryParams;
};

const KnowledgeBasePage = ({ history, location, activePage }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
  const pageSize = new URLSearchParams(location.search).get("pageSize") || 12;
  const { addToastMessage } = useUI();
  const queryParams = new URLSearchParams(location.search);


  const permissions = decodeModuleAccessFromToken("knowledge-base");

  useEffect(() => {
    if (!permissions) {
      history.push("/login");
    }
  }, [permissions, history]);

  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("search", "");
    updateQueryParam("pageNo", 0);
  };

  const getKnowledgeBaseApi = async () => {
    const query = jsonToQueryParams({
      name: queryParams.get("search") || "",
    });
    setLoading(true);
    try {
      const resp = await fetch(`${API_URL}/api/1.0.0/knowledge-base?pageSize=${pageSize}&pageNo=${pageNo}&${query}&sortOrder=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        if (response) {
          setData(response.data);
        }
      }
    } catch (e) {
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  const updateQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };
  const handleDelete = async (id) => {
    if (!id) {
      addToastMessage({
        status: false,
        message: "Something went wrong while deleting . Please try again.",
      });
      return false;
    }
    try {
      const response = await fetch(
        `${API_URL}/api/1.0.0/knowledge-base/change-status/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
          body: JSON.stringify({ "statusId": 2 })
        }
      );

      if (response.ok) {
        getKnowledgeBaseApi();
        addToastMessage({
          status: true,
          message: "Knowledge base deleted successfully.",
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    addToastMessage({
      status: false,
      message: "Something went wrong while deleting Knowledge base. Please try again.",
    });
    return false;
  };

  useEffect(() => {
    getKnowledgeBaseApi();
  }, [location.search]);


  return (
    <section id="opl_coremax">
      <section className="main-section">
        <Header active={activePage} location={location} />
        <section id="knowledgebase">
          <div className="col-1-1 flex center-left  pad-0-10 main-section-top-row">
            <h5 className="page-active color-primary f-w-700">Knowledge Base</h5>
            {permissions?.isCreateAllowed &&
              <ButtonTextIcon
                title='Add New '
                className='mar-l-a'
                onClick={() => history.push("/add-knowledge-base")}
                icon={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='19.995'
                    height='19.995'
                    viewBox='0 0 19.995 19.995'>
                    <path
                      d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
                      transform='translate(-2.005 -2)'
                    />
                  </svg>
                }
              />
            }
            &nbsp;
          </div>
          <TableFilter
            resetFilters={() => {
              resetFilters();
            }} />
          <br />
          <div>
            {data && !loading && (
              <div
                className='flex center-left'
                style={{
                  padding: "10px 15px 10px 15px",
                  gap: "12px",
                }}>
                <p
                  className='f-w-300'
                  style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>

          {loading ? (
            <section
              style={{
                height: "100vh",
                paddingBottom: "20vh",
                backgroundColor: "#fafaff"
              }}
              className='flex center-center'>
              <LoaderIcon
                style={{ height: "32px", width: "32px" }}
              />
            </section>
          ) : data?.knowledgeBaseList.length ? (
            <div className="rs-table-holder">
              <table className="rs-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Category Name</th>
                    <th>Sub Category Name</th>
                    <th>Description</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    loading ?
                      (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center", padding: "20px" }}>
                            <LoaderIcon
                              style={{ height: "32px", width: "32px" }}
                            />
                          </td>
                        </tr>
                      ) : (
                        data && data?.knowledgeBaseList.map((entry, i) => (
                          <tr key={entry.id}>
                            <td >{pageNo * pageSize + i + 1}</td>
                            <td>{entry.name}</td>
                            <td>{entry.knowledgeBaseSubCategory.knowledgeBaseCategory.name}</td>
                            <td>{entry.knowledgeBaseSubCategory.name}</td>
                            <td style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: '200px'  
                            }}>{entry.description}</td>
                            <td>
                              <div className="flex center-center" style={{ gap: '12px' }}>

                                {permissions?.isUpdateAllowed && (
                                  <div className="color-secondary transition hover-color-primary cursor-pointer ">

                                    <CustomModal
                                      selector={
                                        <ButtonTertiary
                                          actionType='view'
                                          title='View'
                                        />
                                      }
                                      content={
                                            <KnowledgeBaseContent
                                              id={entry?.id}
                                              history={history}
                                            />
                                      }
                                    />
                                  </div>

                                )}


                                {permissions?.isUpdateAllowed &&
                                  <div className="flex color-light font-size-10 " >
                                    <NavLink
                                      to={{
                                        pathname: "/edit-knowledge-base",
                                        state: { id: entry?.id },
                                      }}
                                    >
                                      <ButtonTertiary
                                        actionType='edit'
                                        title='Edit'
                                      />


                                    </NavLink>
                                  </div>
                                }

                                {permissions?.isDeleteAllowed &&
                                  <div className="color-secondary transition hover-color-primary cursor-pointer " >
                                    <CustomModal
                                      selector=
                                      {
                                        <ButtonTertiary
                                          actionType='delete'
                                          title='Delete'
                                        />
                                      }
                                      content={
                                        <ConfirmationModal
                                          onConfirm={async () => { await handleDelete(entry?.id); return true; }}
                                          message="Are you sure you want to delete this knowledge base?"
                                        />


                                      }
                                    />

                                  </div>
                                }
                              </div>
                            </td>
                          </tr>
                        ))
                      )
                  }

                </tbody>
              </table>
            </div>
          ) : (
            <section
              style={{
                height: "80vh",
                paddingBottom: "20vh",
                backgroundColor: "#fff",
              }}
              className='flex center-center'>
              <p>No records found.</p>
            </section>
          )}

          <div>
            {data && !loading && (
              <div
                className='flex center-center'
                style={{
                  padding: "10px 15px 10px 15px",
                  gap: "12px",
                }}>
                <p
                  className='f-w-300'
                  style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>

        </section>
      </section>
    </section>
  );
};

export default withRouter(KnowledgeBasePage);

const KnowledgeBaseContent = ({ setShow, id, history }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Decode permissions for the "facilities" module
  const permissions = decodeModuleAccessFromToken("knowledge-base");

  // Redirect to login if no permissions
  useEffect(() => {
    if (!permissions) {
      history.push("/login");
    }
  }, [permissions, history]);

  const InfoItem = ({ icon, label, name, logText=false , ...rest }) => {
    return (
      <div {...rest}>
        <div className='flex top-left' style={{ gap: "10px" }}>
          <div>{icon}</div>
          <div style={{ overflow: "hidden" }}>
            <p
              className='f-w-300'
              style={{
                color: "#aaaaaa",
                fontSize: "10px",
                lineHeight: 1,
                paddingBottom: "2px",
              }}>
              {label}
            </p>
            <p
              style={{ 
                width: !logText ?  "200px" : "auto",
                lineHeight: !logText ? 1 : 1.3,
                overflow: "hidden",
                whiteSpace: !logText ? "nowrap" : null,
                textOverflow: "ellipsis",
              }}>
              {name}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const fetchFacilityData = async (id) => {
    try {
      const resp = await fetch(
        API_URL +
        `/api/1.0.0/knowledge-base/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token")
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        if (response) {
          setData(response.data);
          setLoading(false);
        }
      }
    } catch (e) {
      setError("Failed to fetch facility data.");
      setLoading(false);
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchFacilityData(id);
  }, [id]);

  return (
    <section id="opl_coremax">
      <section id="custom-modal">
        <div className="inner">
          <div className="box" id="activity-detail-box">
            <div className="flex center-left">
              <div>
                <h6 className="color-primary f-w-300">
                  Knowledge Base Details
                </h6>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <ButtonLightTextIcon
                  title="Cancel"
                  onClick={() => { 
                    setShow(false) 
                  }}
                  icon={
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.995"
                      height="19.996"
                    >
                      <path
                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                        fillRule="nonzero"
                      />
                    </svg>
                  }
                />
              </div>
            </div>
            <div
              style={{
                height: "60vh",
                margin: "10px",
                overflowY: "scroll",
                padding: "20px 5px",
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              {loading && (
                <div
                  className="col-1-1"
                  style={{
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoaderIcon
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
              )}
              {!loading && error && (
                <p
                  className="f-w-300 color-primary"
                  style={{ color: "#c0392b" }}
                >
                  {error}
                </p>
              )}
              {data && !loading && !error && (
                <div className="activity-card pos-rel">
                  <div
                    style={{
                      position: "absolute",
                      content: "",
                      height: "4px",
                      left: "-1px",
                      right: "-1px",
                      bottom: "-2px",
                      borderBottomLeftRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  ></div>
                  <div
                    className="col-1-1 flex center-left"
                    style={{
                      gap: "10px",
                      paddingBottom: "5px",
                    }}
                  >
                    <h5 className="f-w-700">
                      <span className="f-w-300">#</span>
                      &nbsp;
                      {data?.id}
                    </h5>
                    <hr
                      style={{
                        outline: "none",
                        border: "none",
                        height: "16px",
                        width: "2px",
                        background: "#EBEBEB",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      padding: "12px 0 12px 0 ",
                    }}
                  >
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                            <circle cx="12" cy="10" r="4"></circle>
                            <circle cx="12" cy="12" r="10"></circle>
                          </svg>
                        }
                        label={"Title"}
                        name={data?.name}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <rect
                              x="3"
                              y="3"
                              width="7"
                              height="7"
                              rx="1"
                            ></rect>
                            <rect
                              x="14"
                              y="3"
                              width="7"
                              height="7"
                              rx="1"
                            ></rect>
                            <rect
                              x="3"
                              y="14"
                              width="7"
                              height="7"
                              rx="1"
                            ></rect>
                            <rect
                              x="14"
                              y="14"
                              width="7"
                              height="7"
                              rx="1"
                            ></rect>
                          </svg>
                        }
                        label={"Category"}
                        name={
                          data?.knowledgeBaseSubCategory?.knowledgeBaseCategory
                            ?.name || "NA"
                        }
                      />
                    </div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <rect
                              x="3"
                              y="3"
                              width="18"
                              height="18"
                              rx="2"
                            ></rect>

                            <rect
                              x="6"
                              y="6"
                              width="5"
                              height="5"
                              rx="1"
                            ></rect>
                            <rect
                              x="13"
                              y="6"
                              width="5"
                              height="5"
                              rx="1"
                            ></rect>
                            <rect
                              x="6"
                              y="13"
                              width="5"
                              height="5"
                              rx="1"
                            ></rect>
                            <rect
                              x="13"
                              y="13"
                              width="5"
                              height="5"
                              rx="1"
                            ></rect>
                          </svg>
                        }
                        label={"Sub Category"}
                        name={data?.knowledgeBaseSubCategory?.name || "NA"}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <line x1="10" y1="3" x2="8" y2="21"></line>
                            <line x1="16" y1="3" x2="14" y2="21"></line>

                            <line x1="3" y1="8" x2="21" y2="6"></line>
                            <line x1="3" y1="16" x2="21" y2="14"></line>
                          </svg>
                        }
                        label={"Code"}
                        name={
                          data?.knowledgeBaseSubCategory?.knowledgeBaseCategory
                            ?.code || "NA"
                        }
                      />
                    </div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <rect
                              x="3"
                              y="2"
                              width="18"
                              height="20"
                              rx="2"
                            ></rect>

                            <line x1="7" y1="6" x2="17" y2="6"></line>

                            <line x1="7" y1="10" x2="17" y2="10"></line>
                            <line x1="7" y1="14" x2="13" y2="14"></line>
                          </svg>
                        }
                        label={"Description"}
                        logText={true}
                        name={data?.description || "NA"}
                      />
                    </div>

                    <hr
                      style={{
                        marginTop: "10px",
                        paddingBottom: "10px",
                      }}
                    />
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        style={{
                          fontWeight: "700",
                          paddingBottom: "8px",
                        }}
                      >
                        Files
                      </p>
                      <div
                        style={{
                          display: "grid",
                          gap: "16px",
                        }}
                      >
                        {data?.fileLinkS3Url ? (
                          <CustomModal 
                            selector={
                              <div
								                className="cursor-pointer"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: "5px 12px",
                                  backgroundColor: "rgb(245, 245, 245)",
                                  borderRadius: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  >
                                    <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>
                                    <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
                                    <path d="M10 9H8"></path>
                                    <path d="M16 13H8"></path>
                                    <path d="M16 17H8"></path>
                                  </svg>
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {data?.fileLink?.split("/").pop()}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "10px",
                                        color: "#7D7D7D",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {data?.type}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "8px",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    title="View file"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                        color: "#000",
                                      }}
                                    >
                                      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                      <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            }
                            content={
                              <FullScreenFileViewerModal
                                title={`Document - (${data.fileLink
                                  .split("/")
                                  .pop()})`}
                                fileUrl={data?.fileLinkS3Url || null}
                              />
                            }
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "5px 12px",
                              backgroundColor: "rgb(245, 245, 245)",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                >
                                  No file found.
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {permissions?.isUpdateAllowed && (
                <NavLink
                  to={
                    data?.id
                      ? {
                          pathname: "/edit-knowledge-base",
                          state: {
                            id: data?.id,
                          },
                        }
                      : "#"
                  }
                >
                  <ButtonLightTextIcon
                    title="Edit Details"
                    icon={
                      <svg
                        width="15"
                        height="15"
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z"
                          fill="#283f54"
                        />
                      </svg>
                    }
                  />
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
const getRandomColor = () => {
  const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};