import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import ProcedureFilter from "./procedure/ProcedureFilter";
import { decodeModuleAccessFromToken } from "../opl-utilities/helpers";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import ProcedureDetails from "./procedure/ProcedureDetails";

const jsonToQueryParams = (json) => {
  const queryParams = Object.keys(json)
    .filter((key) => json[key] !== null && json[key] !== "")
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
    .join("&");

  return queryParams;
};
const ProcedurePage = ({ history, location, activePage }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const { addToastMessage } = useUI();
  const queryParams = new URLSearchParams(location.search);
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;

  const permissions = decodeModuleAccessFromToken("procedures");
  const [selectedIds, setSelectedIds] = useState([]);
  const isChecked = (id) => selectedIds.includes(id);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Redirect to login if no permissions
  useEffect(() => {
    if (!permissions) {
      history.push("/login");
    }
  }, [permissions, history]);

  const resetFilters = () => {
    updateQueryParam("name", "");
    updateQueryParam("pageNo", 0);
  };

  const handleDelete = async (id) => {
    try {
      if (!id) {
        return false;
      }
      const response = await fetch(
        API_URL + `/api/1.0.0/procedure-list/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        getProcedureApi();
        addToastMessage({
          status: true,
          message: `procedure deleted successfully.`,
        });
        return true;
      }

      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedure. Please try again.`,
      });

      return false;
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedure. Please try again.`,
      });
      return false;
    }
  };

  const getProcedureApi = async () => {
    // const query = jsonToQueryParams({
    //     name: queryParams.get("search") || "",
    //     pageNo: queryParams.get("pageNo") || 1,
    // });
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        name: queryParams.get("name") || "",
      });
      const resp = await fetch(
        API_URL +
        `/api/1.0.0/procedure-list?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.ok) {
        const response = await resp.json();
        if (response) {
          setData(response.data);
        }
      }
    } catch (e) {
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  const updateQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };

  useEffect(() => {
    getProcedureApi();
  }, [location.search]);

  const handleMultipleDelete = async () => {
    if (selectedIds.length === 0) {
      addToastMessage({
        status: false,
        message: `No procedure selected for deletion.`,
      });
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/1.0.0/procedure-list`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
        body: JSON.stringify({ ids: selectedIds }), // Send IDs as an array in the payload
      });

      if (response.status === 200) {
        addToastMessage({
          status: true,
          message: `Selected procedures deleted successfully.`,
        });
        getProcedureApi(); // Refresh the list
        setSelectedIds([]); // Clear selection
      } else {
        addToastMessage({
          status: false,
          message: `Something went wrong while deleting procedures. Please try again.`,
        });
      }
    } catch (error) {
      addToastMessage({
        status: false,
        message: `Something went wrong while deleting procedures. Please try again.`,
      });
    }
  };

  return (
    <section id="opl_coremax">
      <section className="main-section">
        <Header active={activePage} location={location} />
        <section id="category">
          <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
            <h5 className="page-active color-primary f-w-700">Procedures</h5>
            {permissions?.isCreateAllowed && (
              <ButtonTextIcon
                title="Add New"
                className="mar-l-a"
                onClick={() => history.push("/add-procedure")}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.995"
                    height="19.995"
                    viewBox="0 0 19.995 19.995"
                  >
                    <path
                      d="M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z"
                      transform="translate(-2.005 -2)"
                    />
                  </svg>
                }
              />
            )}
            &nbsp;
          </div>
          <ProcedureFilter
            resetFilters={resetFilters}
            callBack={async () => {
              await handleMultipleDelete(data.id);
              return true;
            }}
            isDeleteDisabled={selectedIds.length === 0} // Pass button disable state
          />
          <br />
          <div>
            {data && !loading && (
              <div
                className="flex center-left"
                style={{
                  padding: "10px 15px 10px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
          {loading ? (
            <section
              style={{
                height: "100vh",
                paddingBottom: "20vh",
                backgroundColor: "#fafaff",
              }}
              className="flex center-center"
            >
              <LoaderIcon style={{ height: "32px", width: "32px" }} />
            </section>
          ) : data?.procedureList.length ? (
            <div className="rs-table-holder">
              <table className="rs-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>CPT code</th>
                    <th>CPT Description</th>
                    <th>Name</th>
                    <th>Speciality</th>
                    <th>Related Diagnosis</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.procedureList?.map((data, index) => (
                    <tr key={data.id}>
                      <td width="12%">
                        <span
                          className="color-primary"
                          style={{
                            // width: "20px",
                            height: "23px",
                            backgroundColor: false ? "#4CAF50" : "#ccc",
                            borderRadius: "4px",
                            display: "inline-block",
                            position: "relative",
                            cursor: "pointer",
                            marginRight: "8px",
                            color: "white",
                            textAlign: "center",
                            lineHeight: "20px",
                            fontSize: "14px",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="primary"
                            checked={isChecked(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                          />
                        </span>
                      </td>
                      <td>{data.cptCode}</td>
                      <td>{data.cptDescription}</td>
                      <td>{data.procedureName}</td>
                      <td>{data.specialty?.name}</td>
                      <td>{data.relatedDxCode}</td>
                      <td>
                        <div
                          className="flex center-center"
                          style={{ gap: "12px" }}
                        >
                          {permissions?.isReadAllowed && (
                            <div className="color-secondary transition hover-color-primary cursor-pointer ">

                              <CustomModal
                                selector={
                                  <ButtonTertiary
                                    actionType='view'
                                    title='View'
                                  />
                                }
                                content={
                                      <ProcedureDetails
                                        id={data?.id}
                                        history={history}
                                      />
                                }
                              />
                            </div>

                          )}
                          {permissions?.isUpdateAllowed && (
                            <div>
                              <NavLink
                                to={{
                                  pathname: "/edit-procedure",
                                  state: { id: data.id },
                                }}
                              >
                                <ButtonTertiary
                                  actionType='edit'
                                  title='Edit'
                                />
                              </NavLink>
                            </div>
                          )}
                          {permissions?.isDeleteAllowed && (
                            <div className="color-secondary transition hover-color-primary cursor-pointer ">
                              <CustomModal
                                selector={
                                  <ButtonTertiary
                                    actionType="delete"
                                    title="Delete"
                                  />
                                }
                                content={
                                  <ConfirmationModal
                                    onConfirm={async () => {
                                      await handleDelete(data.id);
                                      return true;
                                    }}
                                    message="Are you sure you want to delete this procedure ?"
                                  />
                                }
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <section
              style={{
                height: "80vh",
                paddingBottom: "20vh",
                backgroundColor: "#fff",
              }}
              className="flex center-center"
            >
              <p>No records found.</p>
            </section>
          )}

          <div>
            {data && !loading && (
              <div
                className="flex center-center"
                style={{
                  padding: "10px 15px 10px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
        </section>
      </section>
    </section>
  );
};

export default withRouter(ProcedurePage);
