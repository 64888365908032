import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PaginatedDropdown from '../../components/common/ui/PaginatedDropdown';
import ButtonTextIcon from '../../components/common/ui/ButtonTextIcon';
import LoaderIcon from '../../components/common/ui/LoaderIcon';
import { useUI } from '../../context/ui';
import { API_URL } from '../../opl-utilities/constants';


const UpdateProcedureForm=({ history,procedureData, match }) => {
    const { addToastMessage } = useUI();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    
    const [initialValues, setInitialValues] = useState({
        procedureName: '',
        specialityId: null,
        cptCode: '',
        cptDescription: '',
        relatedDxCode: '',
    });

    const validationSchema = Yup.object({
        procedureName: Yup.string().required('Name is required'), 
        specialityId:  Yup.mixed() 
        .nullable()
        .test("speciality-required", "Speciality is Required",
            function (value) { return value !== null && value.value; }),
        cptCode: Yup.string().required('CPT Code is required'),
        cptDescription: Yup.string().required('CPT Description is required'),
        relatedDxCode: Yup.string().required('Related DxCode is required'),
    });

    useEffect(() => {
        setInitialValues({ 
            id: procedureData?.id,
            procedureName: procedureData?.procedureName || null,
            specialityId: procedureData?.specialtyId || null,
            specialityId: procedureData?.specialty ? {
                value: procedureData?.specialty?.id,
                label: procedureData?.specialty?.name
            }
                : null,
            cptDescription: procedureData?.cptDescription || null,
            cptCode: procedureData?.cptCode || null,
            relatedDxCode: procedureData?.relatedDxCode || null,
        });
     }, [procedureData]);

    const updateProcedure = async (values) => {
        setLoading(true);
        setStatus("");
        console.log("vvv",values);
        
        try {   
            const payload = JSON.stringify({
				procedureName: values.procedureName,
				specialityId: values?.specialityId?.value,
				cptCode: values.cptCode,
				cptDescription: values.cptDescription,
				relatedDxCode: values.relatedDxCode,
			});  
            console.log("...",payload);
                   
            const resp = await fetch(API_URL + `/api/1.0.0/procedure-list/${values?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
                body: payload,
            });
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    if (response.result.responseCode === 200) {
                        return true;
                    } else {
                        addToastMessage({
                            status: false,
                            message: `Something went wrong, please try again later.`,
                        });
                        return false;
                    }
                }
            }
            else {
                const response = await resp.json();
                if (response) {
                    addToastMessage({
                        status: false,
                        message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
            }
        } catch (e) {
            addToastMessage({
                status: false,
                message: "Something went wrong, please try again later.",
            });
            console.log("Error:", e);
            return false;
        } finally {
            setLoading(false);
        }

    };

    const Label = ({ title, required }) => (
        <p className="color-primary f-w-600" style={{ paddingBottom: '5px' }}>
            {title}
            {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        </p>
    );

    
    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelSpecilityFunc = (o) => `${o.name}`;



    return (
        <Formik
            initialValues={initialValues}   
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await updateProcedure(values);
				if (api) {
					resetForm();
					setStatus(
						"You have successfully updated a procedure, thank you."
					);
                    addToastMessage({
						status: true,
						message: `You have successfully updated a procedure, thank you.`,
					})
					history.push("/procedures");
				} else {
					setStatus("Something went wrong, please try again later.");
				}
			}}
        >
            {({ values, errors, touched, setFieldValue }) => (
                <Form>
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Label title="Name" required={true} />
                                        <Field
                                            name="procedureName"
                                            type="text"
                                            placeholder="Enter Name"
                                            className={`primary ${errors.procedureName && touched.procedureName ? 'error' : ''
                                                }`}
                                        />

                                    </td>
                                    <td>
                                        <Label title="Speciality" required={true} />
                                        <PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/specialities?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "id",
                                                    labelSpecilityFunc
                                                )
                                            }
                                            selectedValue={values.specialityId}
                                            placeHolder='Search'
                                            error={errors.specialityId && touched.specialityId} 
                                            onChange={(v) => { 
                                                    setFieldValue(
                                                        "specialityId",
                                                        v
                                                    );  
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="CPT code" required={true} />
                                        <Field
                                            name="cptCode"
                                            type="text"
                                            placeholder="CPT code"
                                            className={`primary ${errors.cptCode && touched.cptCode ? 'error' : ''
                                                }`}
                                        />
                                    </td>
                                    <td>
                                        <Label title="CPT Description" required={true} />
                                        <Field
                                            name="cptDescription"
                                            type="text"
                                            placeholder="CPT Description"
                                            className={`primary ${errors.cptDescription && touched.cptDescription ? 'error' : ''
                                                }`}
                                        />

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <Label title="Related DX code" required={true} />
                                        <Field
                                            name="relatedDxCode"
                                            type="text"
                                            placeholder="Related Dx code"
                                            className={`primary ${errors.relatedDxCode && touched.relatedDxCode ? 'error' : ''
                                                }`}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push('/procedures')}
                            icon={
                                <svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
                            }
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Update Now'
                            className='mar-l-a'
                            icon={
                                loading ? (
                                    <LoaderIcon />
                                ) : (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.996'
                                        viewBox='0 0 19.995 19.996'>
                                        <path
                                            d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                            transform='translate(-2.014 -1.995)'
                                        />
                                    </svg>
                                )
                            }
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default UpdateProcedureForm;